import cloneDeep from "lodash/cloneDeep";
import config from "../configs/reba.json";
import { getSchemaFromComponentValues } from "./RulaReba";
import { IRiskScoreInfo } from "./interfaces";

export class Reba {
  initialized: boolean;

  assessmentResult: any;

  riskComponents: any;

  additionalInputs: any;

  highestRiskFrame: any;

  warningsExist: boolean;

  static rebaTableA: any;

  static rebaTableB: any;

  static rebaTableFinal: any;

  static Schema: any;

  static getRiskScoresFromLevel: (level: any) => number[];

  constructor(oldObject: Reba) {
    this.initialized = true;
    if (!oldObject) {
      this.assessmentResult = {};
      this.riskComponents = {};
      this.additionalInputs = {};
      this.warningsExist = false;
      return;
    }
    this.assessmentResult = cloneDeep(oldObject.assessmentResult);
    this.additionalInputs = cloneDeep(oldObject.additionalInputs);
    this.riskComponents = cloneDeep(oldObject.riskComponents);
    this.highestRiskFrame = cloneDeep(oldObject.highestRiskFrame);
    this.warningsExist = cloneDeep(
      this.doWarningsExist(oldObject.riskComponents),
    );
    this.computeReba();
  }

  static getRiskScoreInfo = (
    bodyPart: string,
    score: number,
  ): IRiskScoreInfo => {
    const ranges = (config as any).BodyPartScores
      ? (config as any).BodyPartScores[bodyPart]
      : undefined;
    if (!ranges || !ranges[score]) {
      throw new Error(
        `Reba.BodyPartScores[${bodyPart}][${score}] doesn't exist in config`,
      );
    }
    return {
      Score: score,
      Color: ranges[score].Color,
      ShortText: ranges[score].ShortText,
      TranslateText: ranges[score].TranslateText,
    };
  };

  updateAdditionalInfo = (
    typeOfInput: string | number,
    bodyGroup: string | number,
    newValue: any,
  ): void => {
    this.additionalInputs[typeOfInput][bodyGroup] = newValue;
    this.computeReba();
  };

  updateRiskComponents = (
    bodyGroup: string | number,
    type: string | number,
    newValue: any,
  ): void => {
    this.riskComponents[bodyGroup][type] = newValue;
    this.computeReba();
  };

  computeReba = (): void => {
    const neckScore = Math.min(
      this.getRiskComponentValue("Neck", "Score") +
        this.getRiskComponentValue("Neck", "Twist") +
        this.getRiskComponentValue("Neck", "SideBend"),
      3,
    );
    const trunkScore = Math.min(
      this.getRiskComponentValue("Trunk", "Score") +
        this.getRiskComponentValue("Trunk", "Twist") +
        this.getRiskComponentValue("Trunk", "SideBend"),
      5,
    );

    const legScore = Math.min(
      this.getRiskComponentValue("Leg", "Score") +
        this.getRiskComponentValue("Leg", "UnstableFooting"),
      4,
    );

    const tableAScore = Reba.rebaTableA[trunkScore][neckScore][legScore - 1];

    const wrist =
      this.getRiskComponentValue("Wrist", "Score") +
      this.getRiskComponentValue("Wrist", "Twist");
    const upperArm =
      this.getRiskComponentValue("Upper Arm", "Score") +
      this.getRiskComponentValue("Upper Arm", "Abducted") +
      this.getRiskComponentValue("Upper Arm", "ShoulderRaised");
    const lowerArm = this.getRiskComponentValue("Lower Arm", "Score");

    const tableBScore = Reba.rebaTableB[upperArm][lowerArm][wrist - 1];

    const finalTableA = Math.min(
      tableAScore +
        this.additionalInputs.Force.Load +
        this.additionalInputs.Force.ForceBuildup,
      12,
    );

    const finalTableB = Math.min(
      tableBScore + this.additionalInputs.Misc.CouplingScore,
      12,
    );

    this.assessmentResult = {
      ...this.assessmentResult,
      ...Reba.getRiskScoreInfo(
        "Overall",

        Reba.rebaTableFinal[finalTableA][finalTableB - 1] +
          this.additionalInputs.Misc.BodyPartHeldStatic +
          this.additionalInputs.Misc.RepeatedSmallActions +
          this.additionalInputs.Misc.RapidPostureChange,
      ),
    };

    this.assessmentResult.Components = {};
    this.assessmentResult.Components.Trunk = Reba.getRiskScoreInfo(
      "Trunk",
      trunkScore,
    );

    this.assessmentResult.Components["Upper Arm"] = Reba.getRiskScoreInfo(
      "Upper Arm",
      upperArm,
    );
    this.assessmentResult.Components["Lower Arm"] = Reba.getRiskScoreInfo(
      "Lower Arm",
      lowerArm,
    );
    this.assessmentResult.Components.Neck = Reba.getRiskScoreInfo(
      "Neck",
      neckScore,
    );
    this.assessmentResult.Components.Leg = Reba.getRiskScoreInfo(
      "Leg",
      legScore,
    );
    this.assessmentResult.Components.Wrist = Reba.getRiskScoreInfo(
      "Wrist",
      wrist,
    );
  };

  getRiskComponentValue = (bodyPart: string, componentId: string): number => {
    if (
      !this.riskComponents ||
      !Object.prototype.hasOwnProperty.call(this.riskComponents, bodyPart) ||
      !Object.prototype.hasOwnProperty.call(
        this.riskComponents[bodyPart],
        componentId,
      )
    ) {
      return 0;
    }
    if (this.riskComponents[bodyPart][componentId] === -1) {
      return Reba.Schema[bodyPart][componentId];
    }
    return this.riskComponents[bodyPart][componentId];
  };

  doWarningsExist = (riskComponentsOldObject: any): boolean => {
    let warnings = false;
    Object.values(riskComponentsOldObject).forEach((value) => {
      if (typeof value === "object") {
        warnings = this.doWarningsExist(value);
      } else if (value === -1) {
        warnings = true;
      }
    });
    return warnings;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
function returnDefaultValueIfNan(val: number, def: any): any {
  if (val === -1) {
    return def;
  }
  return val;
}

Reba.getRiskScoresFromLevel = (level): number[] => {
  const l: number[] = [];

  Object.entries(config.BodyPartScores.Overall).forEach(
    ([scoreKey, scoreValue]: any) => {
      if (scoreValue.ShortText.toLowerCase() === level.toLowerCase()) {
        l.push(parseInt(scoreKey, 10));
      }
    },
  );
  return l;
};

Reba.Schema = getSchemaFromComponentValues(config.ComponentValues);

Reba.rebaTableFinal = {};
Reba.rebaTableFinal[1] = [1, 1, 1, 2, 3, 3, 4, 5, 6, 7, 7, 7];
Reba.rebaTableFinal[2] = [1, 2, 2, 3, 4, 4, 5, 6, 6, 7, 7, 8];
Reba.rebaTableFinal[3] = [2, 3, 3, 3, 4, 5, 6, 7, 7, 8, 8, 8];
Reba.rebaTableFinal[4] = [3, 4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9];
Reba.rebaTableFinal[5] = [4, 4, 4, 5, 6, 7, 8, 8, 9, 9, 9, 9];
Reba.rebaTableFinal[6] = [6, 6, 6, 7, 8, 8, 9, 9, 10, 10, 10, 10];
Reba.rebaTableFinal[7] = [7, 7, 7, 8, 9, 9, 9, 10, 10, 11, 11, 11];
Reba.rebaTableFinal[8] = [8, 8, 8, 9, 10, 10, 10, 10, 10, 11, 11, 11];
Reba.rebaTableFinal[9] = [9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 12];
Reba.rebaTableFinal[10] = [10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12];
Reba.rebaTableFinal[11] = [11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12];
Reba.rebaTableFinal[12] = [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12];

Reba.rebaTableA = {};
Reba.rebaTableA[1] = {};
Reba.rebaTableA[1][1] = [1, 2, 3, 4];
Reba.rebaTableA[1][2] = [1, 2, 3, 4];
Reba.rebaTableA[1][3] = [1, 2, 3, 4];

Reba.rebaTableA[2] = {};
Reba.rebaTableA[2][1] = [2, 3, 4, 5];
Reba.rebaTableA[2][2] = [3, 4, 5, 6];
Reba.rebaTableA[2][3] = [4, 5, 6, 7];

Reba.rebaTableA[3] = {};
Reba.rebaTableA[3][1] = [2, 4, 5, 6];
Reba.rebaTableA[3][2] = [4, 5, 6, 7];
Reba.rebaTableA[3][3] = [5, 6, 7, 8];

Reba.rebaTableA[4] = {};
Reba.rebaTableA[4][1] = [3, 5, 6, 7];
Reba.rebaTableA[4][2] = [5, 6, 7, 8];
Reba.rebaTableA[4][3] = [6, 7, 8, 9];

Reba.rebaTableA[5] = {};
Reba.rebaTableA[5][1] = [4, 6, 7, 8];
Reba.rebaTableA[5][2] = [6, 7, 8, 9];
Reba.rebaTableA[5][3] = [7, 8, 9, 9];

Reba.rebaTableB = {};
Reba.rebaTableB[1] = {};
Reba.rebaTableB[2] = {};
Reba.rebaTableB[3] = {};
Reba.rebaTableB[4] = {};
Reba.rebaTableB[5] = {};
Reba.rebaTableB[6] = {};

Reba.rebaTableB[1][1] = [1, 2, 2];
Reba.rebaTableB[1][2] = [1, 2, 3];

Reba.rebaTableB[2][1] = [1, 2, 3];
Reba.rebaTableB[2][2] = [2, 3, 4];

Reba.rebaTableB[3][1] = [3, 4, 5];
Reba.rebaTableB[3][2] = [4, 5, 5];

Reba.rebaTableB[4][1] = [4, 5, 5];
Reba.rebaTableB[4][2] = [5, 6, 7];

Reba.rebaTableB[5][1] = [6, 7, 8];
Reba.rebaTableB[5][2] = [7, 8, 8];

Reba.rebaTableB[6][1] = [7, 8, 8];
Reba.rebaTableB[6][2] = [8, 9, 9];
