import { EThree } from "@virgilsecurity/e3kit-browser";
import { InitializeVirgilLibrary } from "@tumeke/tumekejs/lib/auth";

InitializeVirgilLibrary(EThree);

/*
export {
  initializeVirgil,
  isVirgilInitialized,
  logoutVirgil,
  createNewUserVirgil,
  createVirgilGroupWithSelf,
  encryptMessage,
  decryptMessage,
  // addUserToGroup,
  canResetPassword,
  resetPassword,
  acceptUserIntoCompanyVirgilHelper,
  joinGroup,
  decryptAESKeys,
  changePassword,
  restorePrivateKey,
  initiateResetPasswordFlow,
  confirmPasswordReset,
  initiateResetPasswordCognito,
} from "@tumeke/tumekejs/lib/auth";
*/
