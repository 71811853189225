// import "react-app-polyfill/ie9";
// import "react-app-polyfill/ie11";
import "./styles";

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from "@tumeke/kerneljs/lib/constants/defaultValues";

import * as virgil from '@tumeke/kerneljs/lib/helpers/VirgilHelpers';

window.Buffer = window.Buffer || require("buffer").Buffer;

const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

// function noop() {}

// if (process.env.NODE_ENV !== 'development') {
//   console.log = noop;
//   console.warn = noop;
//   console.error = noop;
// }

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
  // TODO: build these CSS files separatly and deploy them in CDN for best performance and caching.
  import("./assets/css/sass/themes/gogo." + color + ".scss").then((x) => {
    require("./AppRenderer");
  });
};

render();
