import cloneDeep from "lodash/cloneDeep";
import config from "../configs/handstrain.json";
import { computeRangesHelper } from "./NioshUtils";
import { IRiskScoreInfo } from "./interfaces";

export interface IAdditionalInput {
  borgScale: number;
  taskDuration: number;
  [x: string]: any;
}

export class HandStrain {
  initialized: boolean;

  assessmentResult: any;

  riskComponents: any;

  additionalInputs: IAdditionalInput;

  constructor(oldObject: HandStrain | null) {
    this.initialized = true;
    if (!oldObject) {
      this.assessmentResult = {};
      this.riskComponents = {};
      this.additionalInputs = HandStrain.getAdditionalInputs();
      return;
    }
    this.assessmentResult = cloneDeep(oldObject.assessmentResult);
    this.additionalInputs = cloneDeep(oldObject.additionalInputs);
    this.riskComponents = cloneDeep(oldObject.riskComponents);

    this.computeAssessment();
  }

  static getAdditionalInputs = (): IAdditionalInput => {
    const inputs = {
      borgScale: 2,
      taskDuration: 1,
    };
    return inputs;
  };

  static getIntensityMultiplier = (borg: number): number => {
    const borgPerc = borg / 10.0;
    if (borgPerc <= 0.4) {
      return 30 * borgPerc ** 3 - 15.6 * borgPerc ** 2 + 13 * borgPerc + 0.4;
    }
    return 36 * borgPerc ** 3 - 33.3 * borgPerc ** 2 + 24.77 * borgPerc - 1.86;
  };

  static getEffortMultiplier = (efforts: number): number => {
    if (efforts <= 90) {
      return 0.1 + 0.25 * efforts;
    }
    return 0.00334 * efforts ** 1.96;
  };

  static getDurationMultiplier = (duration: number): number => {
    if (duration <= 60) {
      return 0.45 + 0.31 * duration;
    }
    return 19.17 * Math.log(duration) - 59.44;
  };

  static getFlexionMultiplier = (angle: number): number =>
    1.2 * Math.exp(0.009 * angle) - 0.2;

  static getExtensionMultiplier = (angle: number): number => {
    if (angle <= 30) {
      return 1.0;
    }
    return 1.0 + 0.00028 * (angle - 30) ** 2;
  };

  static getWorkdayMultiplier = (workday: number): number => {
    if (workday < 0.05) {
      return 0.2;
    }
    return 0.042 * workday + 0.09 * Math.log(workday) + 0.477;
  };

  static getRiskScoreInfo = (
    component: string,
    score: number,
  ): IRiskScoreInfo => {
    const range = HandStrain.computeRange(
      config.ResultValues,
      component,
      score,
    );

    const ranges = (config as any).ResultValues
      ? (config as any).ResultValues[component]
      : undefined;
    if (!ranges || !ranges[range]) {
      throw new Error(
        `HandStrain.ResultValues[${component}][${range}] doesn't exist in config`,
      );
    }

    return {
      Score: score,
      Color: ranges[range].Color,
      Text: ranges[range].Text,
      ShortText: ranges[range].ShortText,
    };
  };

  getRiskComponent = (info: string | number): number =>
    parseFloat(Number(this.riskComponents[info]).toFixed(2));

  getAdditionalInput = (info: string | number): any =>
    this.additionalInputs[info];

  getAssessmentResult = (info: string | number): any =>
    this.assessmentResult[info];

  updateAdditionalInfo = (
    typeOfInput: string | number,
    bodyGroup: any,
    newValue: any,
  ): void => {
    this.additionalInputs[typeOfInput] = newValue;
    this.computeAssessment();
  };

  static computeRange = (
    map: any,
    component: string | number,
    score: any,
  ): string => {
    const ret = computeRangesHelper(map[component], score);
    if (!ret) {
      throw new Error(`No range found ${component}`);
    }
    return ret;
  };

  updateRiskComponents = (
    typeOfInput: string,
    bodyGroup: string,
    newValue: any,
  ): void => {
    this.riskComponents[typeOfInput] = newValue;
    this.computeAssessment();
  };

  computeAssessment = (): void => {
    let postureMultiplierLeft = 1.0;
    if (
      this.riskComponents.flexionAngleLeft >
      this.riskComponents.extensionAngleLeft
    ) {
      postureMultiplierLeft = HandStrain.getFlexionMultiplier(
        this.riskComponents.flexionAngleLeft,
      );
    } else {
      postureMultiplierLeft = HandStrain.getExtensionMultiplier(
        this.riskComponents.extensionAngleLeft,
      );
    }
    let postureMultiplierRight = 1.0;
    if (
      this.riskComponents.flexionAngleRight >
      this.riskComponents.extensionAngleRight
    ) {
      postureMultiplierRight = HandStrain.getFlexionMultiplier(
        this.riskComponents.flexionAngleRight,
      );
    } else {
      postureMultiplierRight = HandStrain.getExtensionMultiplier(
        this.riskComponents.extensionAngleRight,
      );
    }

    this.assessmentResult.resultLeft = HandStrain.getRiskScoreInfo(
      "resultLeft",
      HandStrain.getIntensityMultiplier(this.additionalInputs.borgScale) *
        HandStrain.getEffortMultiplier(this.riskComponents.effortsLeft) *
        HandStrain.getDurationMultiplier(
          this.riskComponents.durationPerExertionLeft,
        ) *
        postureMultiplierLeft *
        HandStrain.getWorkdayMultiplier(this.additionalInputs.taskDuration),
    );

    this.assessmentResult.resultRight = HandStrain.getRiskScoreInfo(
      "resultRight",
      HandStrain.getIntensityMultiplier(this.additionalInputs.borgScale) *
        HandStrain.getEffortMultiplier(this.riskComponents.effortsRight) *
        HandStrain.getDurationMultiplier(
          this.riskComponents.durationPerExertionRight,
        ) *
        postureMultiplierRight *
        HandStrain.getWorkdayMultiplier(this.additionalInputs.taskDuration),
    );

    this.assessmentResult.OverallScore = HandStrain.getRiskScoreInfo(
      "OverallScore",
      (this.assessmentResult.resultRight.Score +
        this.assessmentResult.resultLeft.Score) /
        2.0,
    );

    this.assessmentResult.im = HandStrain.getRiskScoreInfo(
      "im",
      HandStrain.getIntensityMultiplier(this.additionalInputs.borgScale),
    );
    this.assessmentResult.eml = HandStrain.getRiskScoreInfo(
      "eml",
      HandStrain.getEffortMultiplier(this.riskComponents.effortsLeft),
    );
    this.assessmentResult.dml = HandStrain.getRiskScoreInfo(
      "dml",
      HandStrain.getDurationMultiplier(
        this.riskComponents.durationPerExertionLeft,
      ),
    );
    this.assessmentResult.pml = HandStrain.getRiskScoreInfo(
      "pml",
      postureMultiplierLeft,
    );
    this.assessmentResult.wm = HandStrain.getRiskScoreInfo(
      "wm",
      HandStrain.getWorkdayMultiplier(this.additionalInputs.taskDuration),
    );

    this.assessmentResult.emr = HandStrain.getRiskScoreInfo(
      "emr",
      HandStrain.getEffortMultiplier(this.riskComponents.effortsRight),
    );
    this.assessmentResult.dmr = HandStrain.getRiskScoreInfo(
      "dmr",
      HandStrain.getDurationMultiplier(
        this.riskComponents.durationPerExertionRight,
      ),
    );
    this.assessmentResult.pmr = HandStrain.getRiskScoreInfo(
      "pmr",
      postureMultiplierRight,
    );
  };
}
