function getSchemaHelper(
  values: any,
  key: string | undefined,
  schema: any,
): void {
  Object.entries(values).forEach(([k, value]: any) => {
    if (typeof value !== "object") {
      return;
    }
    if ("Base" in value) {
      // eslint-disable-next-line no-param-reassign
      schema[k] = value.Base;
    } else {
      // eslint-disable-next-line no-param-reassign
      schema[k] = {};
      // eslint-disable-next-line no-param-reassign
      schema[k].Warnings = {};
      getSchemaHelper(values[k], k, schema[k]);
    }
  });
}

export function getSchemaFromComponentValues(ComponentValues: any): any {
  const schema = {};
  getSchemaHelper(ComponentValues, undefined, schema);
  return schema;
}
