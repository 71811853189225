import { IStorageAdapter } from "@kernel-adapters/storage";
import { SettingsReduxState } from "./slice";
import { defaultLocale, localeOptions } from "../../constants/defaultValues";

export const persistStore = async (
  state: SettingsReduxState,
  storageAdapter: IStorageAdapter,
): Promise<SettingsReduxState> => {
  const currentLanguage: string | null = await storageAdapter.getItem(
    "currentLanguage",
  );
  const locale: string =
    currentLanguage != null ? JSON.parse(currentLanguage) : defaultLocale;

  return {
    ...state,
    locale:
      locale && localeOptions.filter((x) => x.value === locale).length > 0
        ? locale
        : defaultLocale,
  };
};
