import cloneDeep from "lodash/cloneDeep";
import config from "../configs/rula.json";
import { getSchemaFromComponentValues } from "./RulaReba";
import { IRiskScoreInfo } from "./interfaces";

export class Rula {
  static rulaTableA: any;

  static rulaTableB: any;

  static Schema: any;

  static rulaTable: any;

  additionalInputs: any;

  assessmentResult: any;

  riskComponents: any;

  initialized: boolean;

  highestRiskTime: number;

  highestRiskFrame: any;

  warningsExist: boolean;

  static getRiskScoresFromLevel: (level: any) => number[];

  constructor(oldObject: Rula) {
    this.initialized = true;
    if (!oldObject) {
      this.assessmentResult = {};
      this.riskComponents = {};
      this.additionalInputs = {};
      this.highestRiskTime = 0;
      this.warningsExist = false;
      return;
    }
    this.assessmentResult = cloneDeep(oldObject.assessmentResult);
    this.additionalInputs = cloneDeep(oldObject.additionalInputs);
    this.riskComponents = cloneDeep(oldObject.riskComponents);
    this.highestRiskFrame = cloneDeep(oldObject.highestRiskFrame);
    this.highestRiskTime = oldObject.highestRiskTime;
    this.warningsExist = cloneDeep(
      this.doWarningsExist(oldObject.riskComponents),
    );
    this.computeRula();
  }

  getArmForceScore = (): number => {
    let forceScore = this.additionalInputs.Force.Arm;
    if (forceScore === 2) {
      forceScore = 3;
    }
    if (!(forceScore === 0)) {
      forceScore += Number(this.additionalInputs.Force.ArmSuddenExertion);
    }
    return Math.min(forceScore, 3);
  };

  getLegForceScore = (): number => {
    let forceScore = this.additionalInputs.Force.Leg;
    if (forceScore === 2) {
      forceScore = 3;
    }
    if (!(forceScore === 0)) {
      forceScore += Number(this.additionalInputs.Force.LegSuddenExertion);
    }
    return Math.min(forceScore, 3);
  };

  getLegSupportedScore = (): number => {
    let legScore = 1;
    if (
      "Misc" in this.additionalInputs &&
      "LegsSupported" in this.additionalInputs.Misc
    ) {
      legScore = this.additionalInputs.Misc.LegsSupported ? 1 : 2;
    }
    return legScore;
  };

  computeRula = (): void => {
    const wrist = Math.min(
      this.getRiskComponentValue("Wrist", "Score") +
        this.getRiskComponentValue("Wrist", "Midline"),
    );
    const twist = this.getRiskComponentValue("Wrist", "Twist");
    const upperArm =
      this.getRiskComponentValue("Upper Arm", "Score") +
      this.getRiskComponentValue("Upper Arm", "Abducted") +
      this.getRiskComponentValue("Upper Arm", "ShoulderRaised");
    const lowerArm =
      this.getRiskComponentValue("Lower Arm", "Score") +
      this.getRiskComponentValue("Lower Arm", "Midline");

    // Don't need to subtract from twist bc it's already 0 or 1
    const tableAScore =
      Rula.rulaTableA[wrist][twist][upperArm - 1][lowerArm - 1];

    const trunkScore =
      this.getRiskComponentValue("Trunk", "Score") +
      this.getRiskComponentValue("Trunk", "Twist") +
      this.getRiskComponentValue("Trunk", "SideBend");
    const legScore = this.getLegSupportedScore();
    const neckScore =
      this.getRiskComponentValue("Neck", "Score") +
      this.getRiskComponentValue("Neck", "Twist") +
      this.getRiskComponentValue("Neck", "SideBend");
    const tableBScore = Rula.rulaTableB[trunkScore][legScore][neckScore - 1];
    const armForceScore = this.getArmForceScore();
    const legForceScore = this.getLegForceScore();
    const finalArm = Math.min(
      this.additionalInputs["Muscle Use"].Arm + armForceScore + tableAScore,
      8,
    );
    const finalLeg = Math.min(
      this.additionalInputs["Muscle Use"].Leg + legForceScore + tableBScore,
      7,
    );

    this.assessmentResult = {
      ...this.assessmentResult,
      ...Rula.getRiskScoreInfo(
        "Overall",
        Rula.rulaTable[finalLeg][finalArm - 1],
      ),
    };

    this.assessmentResult.Components = {};
    this.assessmentResult.Components.Trunk = Rula.getRiskScoreInfo(
      "Trunk",
      trunkScore,
    );
    this.assessmentResult.Components["Upper Arm"] = Rula.getRiskScoreInfo(
      "Upper Arm",
      upperArm,
    );
    this.assessmentResult.Components["Lower Arm"] = Rula.getRiskScoreInfo(
      "Lower Arm",
      lowerArm,
    );
    this.assessmentResult.Components.Neck = Rula.getRiskScoreInfo(
      "Neck",
      neckScore,
    );
    this.assessmentResult.Components.Wrist = Rula.getRiskScoreInfo(
      "Wrist",
      wrist,
    );
  };

  static getRiskScoreInfo = (
    bodyPart: string,
    score: number,
  ): IRiskScoreInfo => {
    const ranges = (config as any).BodyPartScores
      ? (config as any).BodyPartScores[bodyPart]
      : undefined;
    if (!ranges || !ranges[score]) {
      throw new Error(
        `Reba.BodyPartScores[${bodyPart}][${score}] doesn't exist in config`,
      );
    }
    return {
      Score: score,
      Color: ranges[score].Color,
      ShortText: ranges[score].ShortText,
      TranslateText: ranges[score].TranslateText,
    };
  };

  updateAdditionalInfo = (
    bodyGroup: string | number,
    info: string | number,
    newValue: any,
  ): void => {
    this.additionalInputs[bodyGroup][info] = newValue;
    this.computeRula();
  };

  updateRiskComponents = (
    bodyGroup: string | number,
    type: string | number,
    newValue: any,
  ): void => {
    this.riskComponents[bodyGroup][type] = newValue;
    this.computeRula();
  };

  doWarningsExist = (riskComponentsOldObject: any): boolean => {
    let warnings = false;
    Object.values(riskComponentsOldObject).forEach((value) => {
      if (typeof value === "object") {
        warnings = this.doWarningsExist(value);
      } else if (value === -1) {
        warnings = true;
      }
    });
    return warnings;
  };

  getRiskComponentValue = (bodyPart: string, componentId: string): number => {
    if (
      !this.riskComponents ||
      !Object.prototype.hasOwnProperty.call(this.riskComponents, bodyPart) ||
      !Object.prototype.hasOwnProperty.call(
        this.riskComponents[bodyPart],
        componentId,
      )
    ) {
      return 0;
    }
    if (this.riskComponents[bodyPart][componentId] === -1) {
      return Rula.Schema[bodyPart][componentId];
    }
    return this.riskComponents[bodyPart][componentId];
  };
}

Rula.getRiskScoresFromLevel = (level): number[] => {
  const l: number[] = [];

  Object.entries(config.BodyPartScores.Overall).forEach(
    ([scoreKey, scoreValue]: any) => {
      if (scoreValue.ShortText.toLowerCase() === level.toLowerCase()) {
        l.push(parseInt(scoreKey, 10));
      }
    },
  );
  return l;
};

Rula.Schema = getSchemaFromComponentValues(config.ComponentValues);

// wrist, wrist_twist - 1, upperArm - 1, lowerArm - 1
Rula.rulaTableA = {};
Rula.rulaTableA[1] = [
  [
    [1, 2, 2],
    [2, 3, 3],
    [3, 3, 4],
    [4, 4, 4],
    [5, 5, 6],
    [7, 8, 9],
  ],
  [
    [2, 2, 3],
    [3, 3, 4],
    [3, 4, 4],
    [4, 4, 4],
    [5, 6, 6],
    [7, 8, 9],
  ],
];
Rula.rulaTableA[2] = [
  [
    [2, 2, 3],
    [3, 3, 4],
    [4, 4, 4],
    [4, 4, 5],
    [5, 6, 6],
    [7, 8, 9],
  ],
  [
    [2, 2, 3],
    [3, 3, 4],
    [4, 4, 4],
    [4, 4, 5],
    [5, 6, 7],
    [7, 8, 9],
  ],
];
Rula.rulaTableA[3] = [
  [
    [2, 3, 3],
    [3, 3, 4],
    [4, 4, 4],
    [4, 4, 5],
    [5, 6, 7],
    [7, 8, 9],
  ],
  [
    [3, 3, 3],
    [4, 4, 4],
    [4, 4, 5],
    [5, 5, 6],
    [6, 7, 7],
    [8, 9, 9],
  ],
];
Rula.rulaTableA[4] = [
  [
    [3, 3, 4],
    [4, 4, 5],
    [5, 5, 5],
    [5, 5, 6],
    [6, 7, 7],
    [8, 9, 9],
  ],
  [
    [3, 3, 4],
    [4, 4, 5],
    [5, 5, 5],
    [5, 5, 6],
    [7, 7, 8],
    [9, 9, 9],
  ],
];

// [Trunk, leg, neck - 1]
Rula.rulaTableB = {};
Rula.rulaTableB[1] = {};
Rula.rulaTableB[2] = {};
Rula.rulaTableB[3] = {};
Rula.rulaTableB[4] = {};
Rula.rulaTableB[5] = {};
Rula.rulaTableB[6] = {};

Rula.rulaTableB[1][1] = [1, 2, 3, 5, 7, 8];
Rula.rulaTableB[1][2] = [3, 3, 3, 5, 7, 8];
Rula.rulaTableB[2][1] = [2, 2, 3, 5, 7, 8];
Rula.rulaTableB[2][2] = [3, 3, 4, 6, 7, 8];
Rula.rulaTableB[3][1] = [3, 4, 4, 6, 7, 8];
Rula.rulaTableB[3][2] = [4, 5, 5, 7, 8, 8];
Rula.rulaTableB[4][1] = [5, 5, 5, 7, 8, 8];
Rula.rulaTableB[4][2] = [5, 5, 6, 7, 8, 9];
Rula.rulaTableB[5][1] = [6, 6, 6, 7, 8, 9];
Rula.rulaTableB[5][2] = [6, 7, 7, 7, 8, 9];
Rula.rulaTableB[6][1] = [7, 7, 7, 8, 8, 9];
Rula.rulaTableB[6][2] = [7, 7, 7, 8, 8, 9];

Rula.rulaTable = {};
Rula.rulaTable[1] = [1, 2, 3, 3, 4, 4, 5, 5];
Rula.rulaTable[2] = [2, 2, 3, 3, 4, 4, 5, 5];
Rula.rulaTable[3] = [3, 3, 3, 3, 4, 5, 6, 6];
Rula.rulaTable[4] = [3, 4, 4, 4, 5, 6, 6, 7];
Rula.rulaTable[5] = [4, 4, 4, 5, 6, 6, 7, 7];
Rula.rulaTable[6] = [5, 5, 5, 6, 7, 7, 7, 7];
Rula.rulaTable[7] = [5, 5, 6, 6, 7, 7, 7, 7];
