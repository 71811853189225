import { IStorageAdapter } from "@kernel-adapters/storage";
import { persistStore as adminProfilePersistStore } from "./adminProfile/persist";
import { adminProfileInitialState } from "./adminProfile/slice";
import { persistStore as authPersistStore } from "./auth/persist";
import { authInitialState } from "./auth/slice";
import { persistStore as dashboardPersistStore } from "./dashboard/persist";
import { dashboardInitialState } from "./dashboard/slice";
import { persistStore as menuPersistStore } from "./menu/persist";
import { menuInitialState } from "./menu/slice";
import { persistStore as notificationsPersistStore } from "./notifications/persist";
import { notificationsInitialState } from "./notifications/slice";
import { persistStore as roiPersistStore } from "./roi/persist";
import { roiInitialState } from "./roi/slice";
import { persistStore as settingsPersistStore } from "./settings/persist";
import { settingsInitialState } from "./settings/slice";
import { persistStore as treePersistStore } from "./tree/persist";
import { treeInitialState } from "./tree/slice";
import { persistStore as videosPersistStore } from "./videos/persist";
import { videosInitialState } from "./videos/slice";
import { persistStore as usersPersistStore } from "./users/persist";
import { usersInitialState } from "./users/slice";
import { persistStore as companiesPersistStore } from "./companies/persist";
import { companiesInitialState } from "./companies/slice";
import { persistStore as tempPasswordsPersistStore } from "./temp_passwords/persist";
import { tempPasswordsInitialState } from "./temp_passwords/slice";
import { persistStore as improvementsPersistStore } from "./improvements/persist";
import { improvementsInitialState } from "./improvements/slice";
import { ReduxState } from "./reducers";
import { ExtendedState, PersistStateItems, StateItems } from "../types";

export const updateFromPersist = async <
  S extends StateItems<S>,
  T extends PersistStateItems<S>,
>(
  storageAdapter: IStorageAdapter,
  extentedPersistStore: T,
): Promise<ExtendedState<S>> => {
  const persistedStore: ReduxState = {
    adminProfile: await adminProfilePersistStore(adminProfileInitialState),
    authUser: await authPersistStore(authInitialState, storageAdapter),
    dashboard: await dashboardPersistStore(
      dashboardInitialState,
      storageAdapter,
    ),
    menu: await menuPersistStore(menuInitialState),
    notifications: await notificationsPersistStore(notificationsInitialState),
    roi: await roiPersistStore(roiInitialState, storageAdapter),
    settings: await settingsPersistStore(settingsInitialState, storageAdapter),
    tree: await treePersistStore(treeInitialState),
    videos: await videosPersistStore(videosInitialState, storageAdapter),
    users: await usersPersistStore(usersInitialState),
    companies: await companiesPersistStore(companiesInitialState),
    tempPasswords: await tempPasswordsPersistStore(tempPasswordsInitialState),
    improvements: await improvementsPersistStore(improvementsInitialState),
  };
  const extendedState: S = extentedPersistStore
    ? extentedPersistStore.store
    : ({} as S);
  if (extentedPersistStore?.persistStore) {
    const promises = Object.entries(extentedPersistStore.persistStore).map(
      ([key, persistStore]) =>
        persistStore(extentedPersistStore.store[key] as any),
    );
    const results = await Promise.all(promises);
    Object.keys(extentedPersistStore.persistStore).forEach((key, index) => {
      extendedState[key as keyof S] = results[index];
    });
  }

  return {
    ...persistedStore,
    ...extendedState,
  };
};
