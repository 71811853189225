import { createAsyncThunk } from "@reduxjs/toolkit";

import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  updateCompanyPolicies as updateCompanyPoliciesApi,
  getCompaniesData as getCompaniesDataApi,
} from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";
import { setCompanyData } from "@kernel-store/auth/thunk";

export enum AssessmentTypes {
  RULA = 1,
  REBA = 2,
  NIOSH = 3,
  HandStrain = 4,
  LibertyMutual = 5,
  Snook = 5,
  Training = 6,
}

export interface ICompanyMandatoryMetadataInputs {
  [key: string]: boolean;
}

export const savePolicesRequest = createAsyncThunk<
  { companyId: number; companyData: any },
  {
    companyId: number;
    policiesData: {
      companyName: string;
      metadata: {
        [key: string]: any;
        edit_role: string;
      };
      faceBlur: boolean;
      backgroundBlur: boolean;
      devices: string[];
      notes: {
        [key: string]: any;
        taskDescription: boolean;
        suggestionsForImprovement: boolean;
      };
      reportFormat: {
        [key: string]: any;
        chubbFormat: boolean;
      };
      sendAcceptConfirmEmail: boolean;
      defaultAssessmentType: AssessmentTypes;
      mandatoryMetadataInputs: ICompanyMandatoryMetadataInputs[];
      scrambleFilesPreupload: boolean;
      blockRSUpload: boolean;
      nonAdminBlockVideoDownload: boolean;
      hasGPT: boolean;
      hasTrainingFeature: boolean;
      is_lcp: boolean;
      assessmentLimit: number;
      limitType: number;
      meterStartDate: string | null;
      userLimit: number;
      maintainers: string[];
      allowBulkExport: boolean;
      deleteVideoTime: boolean;
      deleteVideoTimePolicyDays: number;
      uploadDownloadAlert: boolean;
      has2fAuthorization: boolean;
    };
  },
  { state: ReduxState }
>(
  "companies/savePolicesRequest",
  async ({ companyId, policiesData }, { getState, dispatch }) => {
    const state = getState();
    let companyData = null;
    try {
      companyData = await updateCompanyPoliciesApi(companyId, policiesData);
      if (companyData.id === state.authUser.company.id) {
        dispatch(setCompanyData({ ...companyData }));
      }
    } catch (e: any) {
      NotificationManager.warning(
        e.response?.data?.message ||
          "Error getting data. Please take a screenshot and contact support.",
        "Error Getting Data",
        5000,
        null,
        null,
        "",
      );
      throw e;
    }
    return { companyId, companyData };
  },
);

export const getCompaniesDataRequest = createAsyncThunk<
  { companiesData: any },
  { page: number; page_size: number; search: string },
  { state: ReduxState }
>("companies/getCompaniesDataRequest", async ({ page, page_size, search }) => {
  let companiesData = null;
  try {
    companiesData = await getCompaniesDataApi({ page, page_size, search });
  } catch (e) {
    console.log("Error get companies", e);
    throw e;
  }
  return { companiesData };
});
