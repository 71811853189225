import React from "react";
import classnames from "classnames";

export enum ENotificationType {
  CHANGE = "change",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

type Props = {
  id?: string;
  type?: ENotificationType;
  title?: React.ReactNode | string | null;
  message: React.ReactNode | string | null;
  timeOut?: number;
  onClick?: { (): void } | null;
  onRequestHide?: { (): void } | null;
  customClassName?: string;
};

export type TNotification = Props & {
  id?: string | number | null;
  priority?: string | null;
};

class Notification extends React.Component<Props> {
  static defaultProps = {
    type: ENotificationType.INFO,
    title: null,
    timeOut: 5000,
    onClick: () => null,
    onRequestHide: () => null,
    customClassName: "",
  };

  timer: ReturnType<typeof setTimeout> | undefined;

  componentDidMount() {
    const { timeOut } = this.props;
    if (timeOut !== 0) {
      this.timer = setTimeout(this.requestHide, timeOut);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
    this.requestHide();
  };

  requestHide = () => {
    const { onRequestHide } = this.props;
    if (onRequestHide) {
      onRequestHide();
    }
  };

  render() {
    const { type, title, message, customClassName } = this.props;
    const className = classnames([
      "notification",
      `notification-${type}`,
      customClassName,
    ]);
    const { id } = this.props;
    return (
      <div
        className={className}
        onClick={this.handleClick}
        onKeyDown={this.handleClick}
        role="presentation"
      >
        <div
          className="notification-message"
          role="alert"
          data-testid={`${id}NotificationMessageContainer`}
        >
          {!!title && (
            <h4 className="title" data-testid={`${id}NotificationMessageTitle`}>
              {title}
            </h4>
          )}
          <div className="message" data-testid={`${id}NotificationMessageText`}>
            {message}
          </div>
        </div>
      </div>
    );
  }
}

export default Notification;
