export enum TConfigFormat {
  Number = "Number",
  String = "String",
  Float = "Float",
  Int = "Int",
  IntPositive = "IntPositive",
  Boolean = "Boolean",
  Base64 = "Base64",
}

export interface IConfigAdapter {
  env: (
    name: string,
    format: TConfigFormat,
    isRequired: boolean,
  ) => number | string | boolean | null;
}
