export const generatePassword = (length: number): string => {
  const crypto: Crypto | undefined = window?.crypto;
  const charset: string =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  let returnValue: string = "";
  if (typeof crypto === "undefined") {
    const charsetLength: number = charset.length;
    for (let i = 0; i < length; ++i) {
      returnValue += charset.charAt(Math.floor(Math.random() * charsetLength));
    }
  } else {
    const indexes: Uint32Array = crypto.getRandomValues(
      new Uint32Array(length),
    );
    indexes.forEach((index) => {
      returnValue += charset[index % charset.length];
    });
  }
  if (!returnValue.match(/[0-9]/) || !returnValue.match(/[a-zA-Z]/)) {
    return generatePassword(length);
  }

  return returnValue;
};

export const getRandomString = (length: number): string => {
  const s = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return Array(length)
    .join()
    .split(",")
    .map(() => s.charAt(Math.floor(Math.random() * s.length)))
    .join("");
};
