import * as Sentry from "@sentry/browser";

import { Config } from "@tumeke/kerneljs";

Sentry.init({
  dsn: "https://7af29fec0afa48b39cc8b1b55ad7b024@o400209.ingest.sentry.io/5283402",
  environment: Config.SENTRY_ENV,
  beforeSend(event, hint) {
    // Modify or drop the event here
    const error = hint.originalException;
    if (error && error.message) {
      if (error.message instanceof String && 
          error.message.includes("currentTime")) {
        // Drop event because these get fired too many times.
        return null;
      }
      
    }
    return event;
  },
});
