import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { deleteNotificationRecord } from "@kernel-store/notifications/thunk";

export type NotificationsReduxState = {
  notificationList: any[];
};

export const notificationsInitialState: NotificationsReduxState = {
  notificationList: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: notificationsInitialState,
  reducers: {
    addNotificationRecord: (
      state,
      action: PayloadAction<{ notification: any; key: number }>,
    ) => {
      if (
        state.notificationList.some(
          (notification) => notification.key === action.payload.key,
        )
      ) {
        return;
      }
      state.notificationList.push({
        ...action.payload.notification,
        key: action.payload.key,
      });
      state.notificationList.sort((a, b) => b.date - a.date);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteNotificationRecord.fulfilled, (state, action) => {
      const notificationIndex = state.notificationList.findIndex(
        (notification) => notification.key === action.payload.notificationId,
      );
      if (notificationIndex > -1) {
        state.notificationList.splice(notificationIndex, 1);
      }
    });
  },
});

export const { addNotificationRecord } = notificationsSlice.actions;

export default notificationsSlice.reducer;
