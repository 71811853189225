import { IStorageAdapter } from "@kernel-adapters/storage";
import { RoiReduxState, EMPTY_STATE } from "./slice";

export const persistStore = async (
  state: RoiReduxState,
  storageAdapter: IStorageAdapter,
): Promise<RoiReduxState> => {
  const solutions: string | null = await storageAdapter.getItem("solutions");
  const injuries: string | null = await storageAdapter.getItem("injuries");
  // const results: string | null = await storageAdapter.getItem("results");  // Not used
  const intro: string | null = await storageAdapter.getItem("intro");

  if (solutions === null || injuries === null || intro === null) {
    return EMPTY_STATE;
  }

  return {
    ...state,
    solutions: JSON.parse(solutions).solutions,
    injuries: JSON.parse(injuries).injuries,
    intro: JSON.parse(intro),
  };
};
