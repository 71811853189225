import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from "@kernel-constants/defaultValues";

export type MenuReduxState = {
  containerClassnames: string;
  subHiddenBreakpoint: number;
  menuHiddenBreakpoint: number;
  menuClickCount: number;
  selectedMenuHasSubItems: boolean;
};

export const menuInitialState: MenuReduxState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === "menu-default", // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

const authSlice = createSlice({
  name: "auth",
  initialState: menuInitialState,
  reducers: {
    changeSelectedMenuHasSubItems: (
      state,
      action: PayloadAction<{ hasSubItems: boolean }>,
    ) => {
      state.selectedMenuHasSubItems = action.payload.hasSubItems;
    },
    changeDefaultClassnames: (
      state,
      action: PayloadAction<{ strCurrentClasses: string }>,
    ) => {
      state.containerClassnames = action.payload.strCurrentClasses;
    },
    addContainerClassname: (
      state,
      action: PayloadAction<{ classname: string; strCurrentClasses: string }>,
    ) => {
      const { classname, strCurrentClasses } = action.payload;
      const newClasses: string =
        strCurrentClasses.indexOf(classname) > -1
          ? `${strCurrentClasses} ${classname}`
          : strCurrentClasses;
      state.containerClassnames = newClasses;
    },
    clickOnMobileMenu: (
      state,
      action: PayloadAction<{ strCurrentClasses: string }>,
    ) => {
      const { strCurrentClasses } = action.payload;
      const currentClasses = strCurrentClasses
        ? strCurrentClasses
            .split(" ")
            .filter((x) => x !== "" && x !== "sub-show-temporary")
        : [];
      let nextClasses = "";
      if (currentClasses.includes("main-show-temporary")) {
        nextClasses = currentClasses
          .filter((x) => x !== "main-show-temporary")
          .join(" ");
      } else {
        nextClasses = `${currentClasses.join(" ")} main-show-temporary`;
      }
      state.containerClassnames = nextClasses;
      state.menuClickCount = 0;
    },
    setContainerClassnames: (
      state,
      action: PayloadAction<{
        clickIndex: number;
        strCurrentClasses: string;
        selectedMenuHasSubItems: boolean;
      }>,
    ) => {
      const { clickIndex, strCurrentClasses, selectedMenuHasSubItems } =
        action.payload;
      let newClickIndex: number = clickIndex;
      const currentClasses = strCurrentClasses
        ? strCurrentClasses.split(" ").filter((x) => x !== "")
        : "";
      let nextClasses = "";
      if (!selectedMenuHasSubItems) {
        if (
          currentClasses.includes("menu-default") &&
          (newClickIndex % 4 === 0 || newClickIndex % 4 === 3)
        ) {
          newClickIndex = 1;
        }
        if (
          currentClasses.includes("menu-sub-hidden") &&
          newClickIndex % 4 === 2
        ) {
          newClickIndex = 0;
        }
        if (
          currentClasses.includes("menu-hidden") &&
          (newClickIndex % 4 === 2 || newClickIndex % 4 === 3)
        ) {
          newClickIndex = 0;
        }
      }

      if (newClickIndex % 4 === 0) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden";
        }
        newClickIndex = 0;
      } else if (newClickIndex % 4 === 1) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden main-hidden sub-hidden";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default sub-hidden";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden main-hidden sub-hidden";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden main-show-temporary";
        }
      } else if (newClickIndex % 4 === 2) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden sub-hidden";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default main-hidden sub-hidden";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden sub-hidden";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden main-show-temporary sub-show-temporary";
        }
      } else if (newClickIndex % 4 === 3) {
        if (
          currentClasses.includes("menu-default") &&
          currentClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = "menu-default menu-sub-hidden sub-show-temporary";
        } else if (currentClasses.includes("menu-default")) {
          nextClasses = "menu-default sub-hidden";
        } else if (currentClasses.includes("menu-sub-hidden")) {
          nextClasses = "menu-sub-hidden sub-show-temporary";
        } else if (currentClasses.includes("menu-hidden")) {
          nextClasses = "menu-hidden main-show-temporary";
        }
      }
      if (currentClasses.includes("menu-mobile")) {
        nextClasses += " menu-mobile";
      }
      state.containerClassnames = nextClasses;
      state.menuClickCount = newClickIndex;
    },
  },
});

export const {
  changeSelectedMenuHasSubItems,
  changeDefaultClassnames,
  addContainerClassname,
  clickOnMobileMenu,
  setContainerClassnames,
} = authSlice.actions;

export default authSlice.reducer;
