export const StorageAdapter = {
  getItem: async (key) => {
    const jsonValue = localStorage.getItem(key)
    return jsonValue;
  },
  setItem: async (key, value) => {
    localStorage.setItem(key, value);
  },
  removeItem: async (key) => {
    localStorage.removeItem(key);
  },
  clear: async () => {
    localStorage.clear();
  },
  getAllKeys: async () => {
    const keysLength = localStorage.length;
    const keys = [];
    for (let i = 0; i < keysLength; i += 1) {
      keys.push(localStorage.key(i));
    }
    return keys;
  },
  multiGet: async (keys) => {
    const values = [];
    keys.forEach((key) => {
      const jsonValue = localStorage.getItem(key);
      values.push([key, jsonValue]);
    })
    return values;
  },
  multiSet: (keyValuePairs) => {
    keyValuePairs.forEach((keyPair) => {
      localStorage.setItem(keyPair[0], keyPair[1]);
    })
  },
  multiRemove: (keys) => {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};