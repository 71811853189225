import { createAsyncThunk } from "@reduxjs/toolkit";

import { getTempPasswordsData as getTempPasswordsDataApi } from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";

export const getTempPasswordsDataRequest = createAsyncThunk<
  { tempPasswordsData: any },
  { page: number; page_size: number; search: string },
  { state: ReduxState }
>(
  "tempPasswords/getTempPasswordsDataRequest",
  async ({ page, page_size, search }) => {
    let tempPasswordsData = null;
    try {
      tempPasswordsData = await getTempPasswordsDataApi({
        page,
        page_size,
        search,
      });
    } catch (e) {
      console.log("Error get temp passwords", e);
      throw e;
    }
    return { tempPasswordsData };
  },
);
