import React, { Suspense, useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";

import { TumekeJSModule } from "@tumeke/kerneljs";
import { StorageAdapter } from "./adapters/strorage";
import { ConfigAdapter } from "./adapters/config";

import "./sentry";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

// get the Version of Browser, older than '11'
const getIEVersion = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }
  const trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }
  const edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }
  // other browser
  return false;
};

// Method for Rendering of Unsupported Version
const UnsupportedBrowserPage = () => (
  <div>
    <p>
      Hello there! TuMeke uses advanced encryption to protect user data that is
      not available on Internet Explorer
    </p>
    <p>
      We support all other major browsers, including Chrome, Firefox, Safari,
      and Opera
    </p>
  </div>
);

const router = createBrowserRouter([{ path: "*", Component: App, errorElement: <span /> }]);

const iEVersion = getIEVersion();
function AppWrapper() {
  const [store, setStore] = useState(TumekeJSModule.getStore());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    TumekeJSModule.init({
      StorageAdapter,
      ConfigAdapter,
    }, {}).then(() => {
      setStore(TumekeJSModule.getStore());
      setLoading(false);
    });
  }, []);

  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
        {!loading && <RouterProvider router={router} />}
      </Suspense>
    </Provider>
  );
}
if (iEVersion && iEVersion <= 11) {
  createRoot(document.getElementById("root")).render(<UnsupportedBrowserPage />);
} else {
  createRoot(document.getElementById("root")).render(<AppWrapper />);
  /*
   * If you want your app to work offline and load faster, you can change
   * unregister() to register() below. Note this comes with some pitfalls.
   * Learn more about service workers: https://bit.ly/CRA-PWA
   */
  serviceWorker.unregister();
}
