import cloneDeep from "lodash/cloneDeep";
import config from "../configs/training.json";
import { computeRangesHelper } from "./NioshUtils";
import { IRiskScoreInfo } from "./interfaces";

export interface IAdditionalInput {
  [x: string]: any;
}

export class Training {
  initialized: boolean;

  assessmentResult: any;

  riskComponents: any;

  additionalInputs: IAdditionalInput;

  constructor(oldObject: Training | null) {
    this.initialized = true;
    if (!oldObject) {
      this.assessmentResult = {};
      this.riskComponents = {};
      this.additionalInputs = Training.getAdditionalInputs();
      return;
    }
    this.assessmentResult = cloneDeep(oldObject.assessmentResult);
    this.additionalInputs = cloneDeep(oldObject.additionalInputs);
    this.riskComponents = cloneDeep(oldObject.riskComponents);

    this.computeAssessment();
  }

  static getAdditionalInputs = (): IAdditionalInput => {
    const inputs = {};
    return inputs;
  };

  static getRiskScoreInfo = (
    component: string,
    score: number,
  ): IRiskScoreInfo => {
    const range = Training.computeRange(
      config.ComponentValues,
      component,
      score,
    );

    const ranges = (config as any).ComponentValues
      ? (config as any).ComponentValues[component]
      : undefined;
    if (!ranges || !ranges[range]) {
      throw new Error(
        `Training.ComponentValues[${component}][${range}] doesn't exist in config`,
      );
    }

    return {
      Score: score,
      Color: ranges[range].Color,
      Text: ranges[range].Text,
      ShortText: ranges[range].ShortText,
    };
  };

  getRiskComponent = (info: string | number): number =>
    parseFloat(Number(this.riskComponents[info]).toFixed(2));

  getAdditionalInput = (info: string | number): any =>
    this.additionalInputs[info];

  getAssessmentResult = (info: string | number): any =>
    this.assessmentResult[info];

  updateAdditionalInfo = (
    typeOfInput: string | number,
    bodyGroup: any,
    newValue: any,
  ): void => {
    this.additionalInputs[typeOfInput] = newValue;
    this.computeAssessment();
  };

  static computeRange = (
    map: any,
    component: string | number,
    score: any,
  ): string => {
    const ret = computeRangesHelper(map[component], score);
    if (!ret) {
      throw new Error(`No range found ${component}`);
    }
    return ret;
  };

  updateRiskComponents = (
    typeOfInput: string,
    bodyGroup: string,
    newValue: any,
  ): void => {
    this.riskComponents[typeOfInput] = newValue;
    this.computeAssessment();
  };

  computeAssessment = (): void => {
    const score = this.assessmentResult.Score;
    this.assessmentResult = Training.getRiskScoreInfo("Score", score);
  };
}
