import React from "react";
import { EventEmitter } from "events";
import { TNotification, ENotificationType } from "./Notification";

const createUUID = () => {
  const pattern = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  return pattern.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

class NotificationManager extends EventEmitter {
  listNotify: any[];

  constructor() {
    super();
    this.listNotify = [];
  }

  create(notify: TNotification) {
    const defaultNotify: TNotification = {
      id: createUUID(),
      type: ENotificationType.INFO,
      title: null,
      message: null,
      timeOut: 5000,
      customClassName: "",
    };
    if (notify.priority) {
      this.listNotify.unshift(Object.assign(defaultNotify, notify));
    } else {
      this.listNotify.push(Object.assign(defaultNotify, notify));
    }
    this.emitChange();
  }

  primary(
    message: React.ReactNode | string | null,
    title: React.ReactNode | string | null,
    timeOut: number,
    onClick: { (): void } | null,
    priority: string | null,
    customClassName: string,
  ) {
    this.create({
      type: ENotificationType.PRIMARY,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  }

  secondary(
    message: React.ReactNode | string | null,
    title: React.ReactNode | string | null,
    timeOut: number,
    onClick: { (): void } | null,
    priority: string | null,
    customClassName: string,
  ) {
    this.create({
      type: ENotificationType.SECONDARY,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  }

  info(
    message: React.ReactNode | string | null,
    title: React.ReactNode | string | null,
    timeOut: number,
    onClick: { (): void } | null,
    priority: string | null,
    customClassName: string,
  ) {
    this.create({
      type: ENotificationType.INFO,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  }

  success(
    message: React.ReactNode | string | null,
    title: React.ReactNode | string | null,
    timeOut: number,
    onClick: { (): void } | null,
    priority: string | null,
    customClassName: string,
  ) {
    this.create({
      type: ENotificationType.SUCCESS,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  }

  warning(
    message: React.ReactNode | string | null,
    title: React.ReactNode | string | null,
    timeOut: number,
    onClick: { (): void } | null,
    priority: string | null,
    customClassName: string,
  ) {
    this.create({
      type: ENotificationType.WARNING,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  }

  error(
    message: React.ReactNode | string | null,
    title: React.ReactNode | string | null,
    timeOut: number,
    onClick: { (): void } | null,
    priority: string | null,
    customClassName: string,
  ) {
    this.create({
      type: ENotificationType.ERROR,
      message,
      title,
      timeOut,
      onClick,
      priority,
      customClassName,
    });
  }

  remove(notification: any) {
    this.listNotify = this.listNotify.filter((n) => notification.id !== n.id);
    this.emitChange();
  }

  emitChange() {
    this.emit(ENotificationType.CHANGE, this.listNotify);
  }

  addChangeListener(callback: any) {
    this.addListener(ENotificationType.CHANGE, callback);
  }

  removeChangeListener(callback: any) {
    this.removeListener(ENotificationType.CHANGE, callback);
  }
}

export default new NotificationManager();
