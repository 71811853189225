import "cross-fetch/polyfill";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import moment from 'moment';
import subMinutes from "date-fns/subMinutes";
import addMinutes from "date-fns/addMinutes";

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const VIRGIL_URL_LENGTH = 28;
const BUFFER_TIME = 2;
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const AWS_REGION = "us-east-1";
let EXPIRE_TIME_MINS = 5;

// dev
const COGNITO_CONFIG = {
  UserPoolId: "",
  ClientId: "",
};

// AmazonCognitoIdentity.config.region = awsRegion;
let userPool: AmazonCognitoIdentity.CognitoUserPool | null = null;
let currentUser: AmazonCognitoIdentity.CognitoUser | null = null;

const initializeUserPools = (
  poolId: string,
  clientId: string,
  expireTime: number | undefined,
): void => {
  COGNITO_CONFIG.UserPoolId = poolId;
  COGNITO_CONFIG.ClientId = clientId;
  if (expireTime !== undefined) {
    EXPIRE_TIME_MINS = expireTime;
  }
  console.log("[initializeUserPool]", clientId);
  userPool = new AmazonCognitoIdentity.CognitoUserPool(COGNITO_CONFIG);
};

const authDetails = (
  email: string,
  password: string,
  mfaWithEmail?: boolean,
): AmazonCognitoIdentity.AuthenticationDetails =>
  new AmazonCognitoIdentity.AuthenticationDetails({
    Username: email,
    Password: password,
    ClientMetadata: mfaWithEmail ? { type: "email" } : undefined,
  });

const cognitoUser = (email: any): AmazonCognitoIdentity.CognitoUser =>
  new AmazonCognitoIdentity.CognitoUser({ Username: email, Pool: userPool! });

// callback to promise prob a better way
const cognitoAuthUser = (
  email: string,
  password: string,
  onlyCheck = false,
  mfaWithEmail = false,
): Promise<any> =>
  new Promise((resolve, reject) => {
    const callbkfn: AmazonCognitoIdentity.IAuthenticationCallback = {
      onSuccess(result: any) {
        resolve(result);
      },
      onFailure(err: any) {
        reject(err);
      },
      mfaRequired(_codeDeliveryDetails: any, codeDeliveryDestination: any) {
        let destination = "unknown";
        if ("CODE_DELIVERY_DESTINATION" in codeDeliveryDestination) {
          destination = codeDeliveryDestination.CODE_DELIVERY_DESTINATION;
        }
        resolve({
          message: "mfa",
          destination,
        });
      },
    };
    const authenticateDetails = authDetails(email, password, mfaWithEmail);

    if (onlyCheck) {
      const tempUserPool = new AmazonCognitoIdentity.CognitoUserPool(
        COGNITO_CONFIG,
      );
      const tempUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: tempUserPool!,
      });
      const currentCliendId = userPool?.getClientId();
      const currentUsername = userPool?.getCurrentUser()?.getUsername();

      callbkfn.onSuccess = (result: any) => {
        tempUser.signOut();
        if (currentCliendId && currentUsername) {
          localStorage?.setItem(
            `CognitoIdentityServiceProvider.${currentCliendId}.LastAuthUser`,
            currentUsername,
          );
        }
        resolve(result);
      };

      tempUser.authenticateUser(authenticateDetails, callbkfn);
    } else {
      currentUser = cognitoUser(email);

      currentUser.authenticateUser(authenticateDetails, callbkfn);
    }
  });

const hasCurrentUser = (): boolean => currentUser !== null;

const cognitoSendVerificationcode = (
  _email: string,
  verificationCode: string,
): Promise<any> =>
  new Promise((resolve, reject) => {
    const callbkfn = {
      onSuccess(result: any) {
        resolve(result);
      },
      onFailure(err: any) {
        reject(err);
      },
    };

    currentUser!.sendMFACode(verificationCode, callbkfn);
  });

const cognitoInitiateForgotPassword = (email: string): Promise<any> =>
  new Promise((resolve, reject) => {
    cognitoUser(email).forgotPassword({
      onSuccess(data) {
        resolve(data);
        console.log("Successfully initiated password reset");
      },
      onFailure(err) {
        reject(err);
      },
    });
  });

const cognitoConfirmPasswordReset = (
  email: string,
  verificationCode: string,
  newPassword: string,
): Promise<any> =>
  new Promise((resolve, reject) => {
    cognitoUser(email).confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve("Done");
        console.log("Password confirmed!");
      },
      onFailure(err) {
        reject(err);
        console.log("Password not confirmed!");
      },
    });
  });

// callback to promise prob a better way
const cognitoChangePasswordWeb = (
  _email: string,
  oldPassword: string,
  newPassword: string,
): Promise<any> =>
  new Promise((resolve, reject) => {
    const poolCurrentUser = userPool!.getCurrentUser();
    if (!poolCurrentUser || poolCurrentUser === null) {
      reject();
    }
    // Continue with steps in Use case 16
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    poolCurrentUser!.getSession((err: any, _session: any) => {
      if (err) {
        console.log("Get session error");
        reject(err);
        return;
      }

      poolCurrentUser!.changePassword(
        oldPassword,
        newPassword,
        (error: any, result: any) => {
          if (error) {
            reject(error);
          } else {
            resolve(result.user);
          }
        },
      );
    });
  });

// TODO: Check cognitoChangePasswordRN
// userPool!.storage doesn't exist
const cognitoChangePasswordRN = (
  _email: string,
  oldPassword: string,
  newPassword: string,
) =>
  new Promise((resolve, reject) => {
    (userPool! as any).storage.sync((err: any, result: string) => {
      if (err) {
        console.log("Sync error");
        // Something wrong with getting current session
        reject(err);
      } else if (result === "SUCCESS") {
        const cognitoUserPool = userPool!.getCurrentUser();

        if (!cognitoUserPool || cognitoUserPool === null) {
          reject(err);
          return;
        }
        // Continue with steps in Use case 16
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        cognitoUserPool.getSession((error: any, _session: any) => {
          if (error) {
            console.log("Get session error");
            reject(err);
            return;
          }

          cognitoUserPool.changePassword(
            oldPassword,
            newPassword,
            (e: any, resultData: any) => {
              if (err) {
                reject(err);
              } else {
                resolve(resultData.user);
              }
            },
          );
        });
      }
    });
  });

// TODO: Check cognitoRefreshTokenRN
// userPool!.storage doesn't exist
const cognitoRefreshTokenRN = () =>
  new Promise((resolve, reject) => {
    (userPool! as any).storage.sync((err: any, result: any) => {
      if (err) {
        console.log("Sync error");
        // Something wrong with getting current session
        reject(err);
      } else if (result === "SUCCESS") {
        const cognitoUserPool = userPool!.getCurrentUser();

        if (!cognitoUserPool || cognitoUserPool === null) {
          reject(err);
          return;
        }
        // Continue with steps in Use case 16
        cognitoUserPool.getSession((e: any, session: any) => {
          if (e) {
            console.log("Get session error");
            reject(e);
            return;
          }
          const refreshToken = session.getRefreshToken().token;
          const token = new AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: refreshToken,
          });

          if (session.isValid()) {
            resolve(session);
            return;
          }

          cognitoUserPool.refreshSession(
            token,
            (error: any, sessionData: any) => {
              if (error) {
                console.log("Refresh error");
                reject(error);
                return;
              }
              // const idToken = sessionData.getIdToken().getJwtToken();
              resolve(sessionData);
            },
          );
        });
      }
    });
  });

// TODO: Check cognitoForceRefreshTokenRN
// userPool!.storage doesn't exist
const cognitoForceRefreshTokenRN = () =>
  new Promise((resolve, reject) => {
    (userPool! as any).storage.sync((err: any, result: any) => {
      if (err) {
        console.log("Sync error");
        // Something wrong with getting current session
        reject(err);
      } else if (result === "SUCCESS") {
        const cognitoUserPool = userPool!.getCurrentUser();

        if (!cognitoUserPool || cognitoUserPool === null) {
          reject(err);
          return;
        }
        // Continue with steps in Use case 16
        cognitoUserPool.getSession((error: any, session: any) => {
          if (error) {
            console.log("Get session error");
            reject(error);
            return;
          }
          const refreshToken = session.getRefreshToken().token;
          const token = new AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: refreshToken,
          });
          cognitoUserPool.refreshSession(token, (e: any, sessionData: any) => {
            if (e) {
              console.log("Refresh error");
              reject(e);
              return;
            }
            resolve(sessionData);
          });
        });
      }
    });
  });

const cognitoForceRefreshTokenWeb = (): Promise<any> =>
  new Promise((resolve, reject) => {
    const cognitoPoolUser = userPool!.getCurrentUser();
    if (!cognitoPoolUser || cognitoPoolUser === null) {
      reject(new Error("No user found"));
    }
    // Continue with steps in Use case 16
    cognitoPoolUser!.getSession((err: any, session: any) => {
      if (err) {
        console.log("Get session error");
        reject(err);
        return;
      }
      const refreshToken = session.getRefreshToken().token;
      const token = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: refreshToken,
      });

      cognitoPoolUser!.refreshSession(
        token,
        (error: any, sessionRefresh: any) => {
          if (error) {
            console.log("Refresh error");
            reject(err);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const idToken = sessionRefresh.getIdToken().getJwtToken();
          resolve(sessionRefresh);
        },
      );
    });
  });

const cognitoRefreshTokenWeb = (): Promise<any> =>
  new Promise((resolve, reject) => {
    const cognitoUserPool = userPool!.getCurrentUser();
    if (!cognitoUserPool || cognitoUserPool === null) {
      reject();
    }
    // Continue with steps in Use case 16
    cognitoUserPool!.getSession((err: any, session: any) => {
      if (err) {
        console.log("Get session error");
        reject(err);
        return;
      }
      const refreshToken = session.getRefreshToken().token;
      const token = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: refreshToken,
      });

      if (session.isValid()) {
        resolve(session);
      }

      cognitoUserPool!.refreshSession(
        token,
        (error: any, sessionRefresh: any) => {
          if (error) {
            console.log("Refresh error");
            reject(error);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const idToken = sessionRefresh.getIdToken().getJwtToken();
          resolve(session);
        },
      );
    });
  });

// callback to promise prob a better way
const cognitoRegisterUser = (
  email: string,
  password: string,
  virgil_id: string,
): Promise<any> =>
  new Promise((resolve, reject) => {
    if (virgil_id === undefined) {
      throw new Error("Undefined virgilId");
    }
    const attributeList = [];
    const virgilDict = {
      Name: "custom:virgil_id",
      Value: virgil_id,
    };
    const virgilIdCognito = new AmazonCognitoIdentity.CognitoUserAttribute(
      virgilDict,
    );

    attributeList.push(virgilIdCognito);
    userPool!.signUp(
      email,
      password,
      attributeList,
      [],
      (err: any, result: any) => {
        console.log(err, result);
        if (err) {
          reject(err);
        } else {
          resolve(result.user);
        }
      },
    );
  });

// Cognito utils
const asyncStore = async (key: string, val: string): Promise<void> =>
  AsyncStorage.setItem(key, val);

const asyncStoreIdToken = async (id_token: any): Promise<void> => {
  const token = {
    token: id_token,
    expirationDate: addMinutes(new Date(), EXPIRE_TIME_MINS).toString(),
    // expirationDate: moment().add(EXPIRE_TIME_MINS, 'minutes').toString(),
  };
  await AsyncStorage.setItem("id_token", JSON.stringify(token));
};

const logOutHelper = (): void => {
  localStorage?.removeItem("userObj");
  localStorage?.removeItem("companyObj");
  localStorage?.removeItem("id_token");
  window.location.href = "/user/login";
};

const refreshTokenHelper = async (
  platform: string,
  callback?: () => void,
): Promise<boolean> => {
  try {
    let cognitoUserRefreshed;
    if (platform === "web") {
      cognitoUserRefreshed = await cognitoForceRefreshTokenWeb();
    } else {
      cognitoUserRefreshed = await cognitoForceRefreshTokenRN();
    }
    const idToken = cognitoUserRefreshed.getIdToken().getJwtToken();
    await asyncStoreIdToken(idToken);
    return true;
  } catch (e) {
    console.log(e);
    if (callback) {
      callback();
    } else {
      logOutHelper();
    }
    return false;
  }
};

// Platform either "web" or "rn"
const asyncGetIdToken = async (
  platform: string,
  callback?: () => void,
): Promise<any> => {
  let token;
  try {
    const idToken = await AsyncStorage.getItem("id_token");
    if (!idToken) throw new Error("idToken doesn't exist");
    token = JSON.parse(idToken);
  } catch (e) {
    if (callback) {
      callback();
    } else {
      logOutHelper();
    }
    return "";
  }

  const expr = token.expirationDate;
  if (
    new Date().getTime() >= subMinutes(new Date(expr), BUFFER_TIME).getTime()
  ) {
    // if (moment() >= moment(expr).subtract(BUFFER_TIME, 'minutes')) {
    await refreshTokenHelper(platform, callback);
  }
  const idToken = await AsyncStorage.getItem("id_token");
  if (!idToken) {
    return null;
  }
  return JSON.parse(idToken!).token;
};

const setUserSession = (serverObj: {
  id_token: string;
  access_token: string;
  refresh_token: string;
}): AmazonCognitoIdentity.CognitoUserSession => {
  const cognitoIdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: serverObj.id_token,
  });
  const cognitoAccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: serverObj.access_token,
  });
  const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: serverObj.refresh_token,
  });
  const username = cognitoIdToken.payload.email; // or what you use as username, e.g. email
  const user = cognitoUser(username);
  const cognitoUserRet = new AmazonCognitoIdentity.CognitoUserSession({
    AccessToken: cognitoAccessToken,
    IdToken: cognitoIdToken,
    RefreshToken: cognitoRefreshToken,
  });
  user.setSignInUserSession(cognitoUserRet);
  return cognitoUserRet;
};

const cognitoSignoutUser = (): void => {
  const cognitoUserPool = userPool!.getCurrentUser();
  if (cognitoUserPool) {
    cognitoUserPool.signOut();
  }
};

const cognitoInitSession = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const cognitoPoolUser = userPool?.getCurrentUser();
    if (!cognitoPoolUser) {
      reject();
      return;
    }
    cognitoPoolUser?.getSession((err: any) => {
      if (err) {
        reject(err);
        return;
      }
      currentUser = cognitoPoolUser as AmazonCognitoIdentity.CognitoUser;
      resolve(true);
    });
  });

const cognitoGetPhone = (): Promise<{
  phoneNumberVerified: boolean;
  phoneNumber: string;
  mfaEnabled: boolean;
}> =>
  new Promise((resolve, reject) => {
    const cognitoPoolUser = userPool?.getCurrentUser();
    cognitoPoolUser!.getSession((err: any) => {
      if (err) {
        return;
      }
      currentUser = cognitoPoolUser as AmazonCognitoIdentity.CognitoUser;
      cognitoPoolUser?.getUserData((error, result) => {
        if (error) {
          reject(error);
          return;
        }
        const phoneNumberObj = (result?.UserAttributes || []).find(
          (item) => item.Name === "phone_number",
        );
        const phoneNumberVerifiedObj = (result?.UserAttributes || []).find(
          (item) => item.Name === "phone_number_verified",
        );

        resolve({
          phoneNumber: phoneNumberObj?.Value || "",
          phoneNumberVerified: phoneNumberVerifiedObj?.Value === "true",
          mfaEnabled:
            (result?.UserMFASettingList?.length || 0) > 0 &&
            !!result?.PreferredMfaSetting,
        });
      });
    });
  });

const cognitoEnableMultifactor = (): Promise<any> =>
  new Promise((resolve, reject) => {
    currentUser?.enableMFA((error, result) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(result);
    });
  });

const cognitoDisableMultifactor = (): Promise<any> =>
  new Promise((resolve, reject) => {
    currentUser?.disableMFA((error, result) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(result);
    });
  });

const cognitoSendPhoneNumberVerificationSMS = (): Promise<any> =>
  new Promise((resolve, reject) => {
    currentUser?.getAttributeVerificationCode("phone_number", {
      onSuccess: (success) => {
        resolve(success);
      },
      onFailure(err) {
        reject(err);
      },
    });
  });

const cognitoVerifyPhoneNumber = (code: string): Promise<any> =>
  new Promise((resolve, reject) => {
    currentUser?.verifyAttribute("phone_number", code, {
      onSuccess: (success) => {
        resolve(success);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });

const cognitoSetMultifactorByDefault = (isDefault: boolean): Promise<any> =>
  new Promise((resolve, reject) => {
    currentUser?.setUserMfaPreference(
      {
        PreferredMfa: isDefault,
        Enabled: isDefault,
      },
      {
        PreferredMfa: false,
        Enabled: false,
      },
      (error, result) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(result);
      },
    );
  });

const cognitoUpdatePhoneNumber = (
  phoneNumber: string,
): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    currentUser?.updateAttributes(
      [
        {
          Name: "phone_number",
          Value: phoneNumber,
        },
      ],
      (err, details) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(details);
      },
    );
  });

export {
  cognitoAuthUser,
  cognitoRegisterUser,
  cognitoChangePasswordWeb,
  cognitoChangePasswordRN,
  cognitoRefreshTokenWeb,
  cognitoRefreshTokenRN,
  COGNITO_CONFIG,
  initializeUserPools,
  asyncGetIdToken,
  refreshTokenHelper,
  asyncStoreIdToken,
  asyncStore,
  cognitoInitiateForgotPassword,
  cognitoConfirmPasswordReset,
  cognitoSendVerificationcode,
  hasCurrentUser,
  setUserSession,
  cognitoSignoutUser,
  cognitoGetPhone,
  cognitoEnableMultifactor,
  cognitoDisableMultifactor,
  cognitoSendPhoneNumberVerificationSMS,
  cognitoSetMultifactorByDefault,
  cognitoVerifyPhoneNumber,
  cognitoUpdatePhoneNumber,
  cognitoInitSession,
};
