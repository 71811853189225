import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EMPTY_FILTER, TDashboardFilter } from "@kernel-store/dashboard/slice";
import { getUsersDataRequest } from "@kernel-store/users/thunk";

export type UsersReduxState = {
  usersData: any;
  usersDataLoading: boolean;
  usersCount: number;
  usersFilters: any;
  usersDataDirty: boolean;
  filterObject: TDashboardFilter;
  refresh: boolean;
};

export const usersInitialState: UsersReduxState = {
  usersData: [],
  usersDataLoading: false,
  usersCount: 0,
  usersFilters: {},
  usersDataDirty: false,
  filterObject: EMPTY_FILTER,
  refresh: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {
    deleteUsers: (
      state,
      action: PayloadAction<{ userIds: number[]; isFull: boolean }>,
    ) => {
      action.payload.userIds.forEach((userId) => {
        const userIndex = state.usersData.findIndex(
          (user: any) => user.id === userId,
        );
        if (userIndex > -1) {
          if (action.payload.isFull) {
            state.usersData.splice(userIndex, 1);
            state.usersCount -= 1;
          } else {
            state.usersData[userIndex].role = "none";
          }
        }
      });
    },
    getUsersDataSuccess: (
      state,
      action: PayloadAction<{ usersData: { items: any; count: number } }>,
    ) => {
      state.usersData = action.payload.usersData.items;
      state.usersCount = action.payload.usersData.count;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersDataRequest.pending, (state) => {
      state.usersDataLoading = true;
    });
    builder.addCase(getUsersDataRequest.fulfilled, (state, action) => {
      state.usersData = action.payload.usersData.items;
      state.usersCount = action.payload.usersData.count;
      state.usersDataLoading = false;
    });
    builder.addCase(getUsersDataRequest.rejected, (state) => {
      state.usersDataLoading = false;
    });
  },
});

export const { deleteUsers, getUsersDataSuccess } = usersSlice.actions;

export default usersSlice.reducer;
