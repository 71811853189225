export const INJURY_TYPES: string[] = [
  "",
  "Back strain",
  "Sciatica",
  "Neck strain",
  "Shoulder strain",
  "Rotator cuff injury",
  "Elbow strain",
  "Epicondylitis",
  "Hand/wrist strain",
  "Tendonitis",
  "Carpal tunnel syndrome",
  "Bursitis",
  "Knee strain",
  "All other MSDs",
];

export const INJURY_COSTS: { [key: string]: string | number } = {
  "": 0,
  "Back strain": "24135.67",
  Sciatica: "56929.50",
  "Neck strain": "47189.00",
  "Shoulder strain": "26008.00",
  "Rotator cuff injury": "47666.68",
  "Elbow strain": "24946.33",
  Epicondylitis: "20223.00",
  "Hand/wrist strain": "24497.00",
  Tendonitis: "19896.00",
  "Carpal tunnel syndrome": "30827.00",
  Bursitis: "31376.00",
  "Knee strain": "27957.67",
  "All other MSDs": "17380.00",
};

export const SOLUTION_TYPES: {
  key: string | number;
  label: string;
  icon: string;
}[] = [
  {
    key: "",
    label: "Solution type",
    icon: "toolbox",
  },
  {
    key: "equipment",
    label: "Equipment",
    icon: "truck",
  },
  {
    key: "education",
    label: "Education",
    icon: "graduation-cap",
  },
  {
    key: "other",
    label: "Other",
    icon: "toolbox",
  },
];
