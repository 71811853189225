import RulaConfig from "../configs/rula.json";
import RebaConfig from "../configs/reba.json";

export default interface IFrequencyTable {
  [x: string]: number;
}

export const getChartDataHelper = (frequencyTable: IFrequencyTable): any => {
  let s = 0;
  const percentages: any = {};
  Object.values(frequencyTable).forEach((value: number) => {
    s += value;
  });
  if (s === 0) s = 1;
  Object.entries(frequencyTable).forEach(([scoreKey, value]: any) => {
    percentages[scoreKey] = Math.floor((value / s) * 1000) / 10.0;
  });
  if ("-1" in percentages) {
    percentages["Not Identified"] = percentages["-1"];
    delete percentages["-1"];
  }
  return percentages;
};

export interface IChartDataForAssessment {
  videoId: any;
  data: any;
  colors: {};
}

export const getChartDataForAssessment = (
  assessmentData: any,
  subjectId: string | number,
  type: string,
  detailedView: boolean = true,
): IChartDataForAssessment => {
  const { videoId } = assessmentData;
  const { assessmentType } = assessmentData.data;
  let frequencyTable: any;
  if (type === "Overall") {
    frequencyTable = assessmentData.data.summaryStats[subjectId].riskFrequency;
  } else {
    frequencyTable =
      assessmentData.data.summaryStats[subjectId].componentFrequency[type]
        .Combined;
  }
  let processedFreqTable: any = {};
  const colors: any = {};
  const config =
    assessmentType === 1
      ? RulaConfig.BodyPartScores
      : RebaConfig.BodyPartScores;
  if (!detailedView) {
    Object.entries(frequencyTable).forEach(([scoreKey, value]: any) => {
      if (scoreKey === "-1") {
        processedFreqTable["-1"] = frequencyTable["-1"];
        colors["Not Identified"] = "#6c7b8a";
        return;
      }
      const key = (config as any)[type][parseInt(scoreKey, 10)].ShortText;
      if (key in processedFreqTable) {
        processedFreqTable[key] += value;
      } else {
        processedFreqTable[key] = value;
        colors[key] = (config as any)[type][parseInt(scoreKey, 10)].Color;
      }
    });
  } else {
    processedFreqTable = frequencyTable;

    Object.keys(frequencyTable).forEach((scoreKey) => {
      const s = parseInt(scoreKey, 10);
      if (s in (config as any)[type]) {
        colors[scoreKey] = (config as any)[type][s].Color;
      } else {
        colors["Not Identified"] = "#6c7b8a";
      }
    });
  }

  const data = getChartDataHelper(processedFreqTable);

  return {
    videoId,
    data,
    colors,
  };
};

export const equalizeFreqHelper = (chartTables: any[]): any[] => {
  for (let i = 0; i < chartTables.length; i++) {
    const entries1 = Object.keys(chartTables[i].data);
    for (let j = i; j < chartTables.length; j++) {
      if (i === j) {
        continue;
      }
      const entries2 = Object.keys(chartTables[j].data);

      let present = entries1.filter((e) => !entries2.includes(e));

      for (let k = 0; k < present.length; k++) {
        // eslint-disable-next-line no-param-reassign
        chartTables[j].data[present[k]] = 0.0;
      }

      present = entries2.filter((e) => !entries1.includes(e));

      for (let k = 0; k < present.length; k++) {
        // eslint-disable-next-line no-param-reassign
        chartTables[i].data[present[k]] = 0.0;
      }
    }
  }
  return chartTables;
};

const isSummaryStatsInvalid = (
  assessmentData: any,
  subjectId: string | number,
): boolean =>
  !("summaryStats" in assessmentData.data) ||
  !assessmentData.data.summaryStats ||
  Object.keys(assessmentData.data.summaryStats).length === 0 ||
  !assessmentData.data.summaryStats[subjectId] ||
  Object.keys(assessmentData.data.summaryStats[subjectId]).length === 0;

const getComponentChartData = (
  assessmentDatas: any[],
  subjectId: string,
  type: string,
  detailedView: boolean | undefined,
): any[] => {
  for (let i = 0; i < assessmentDatas.length; i++) {
    if (
      isSummaryStatsInvalid(assessmentDatas[i], subjectId) ||
      !(
        "componentFrequency" in assessmentDatas[i].data.summaryStats[subjectId]
      ) ||
      !(
        type in
        assessmentDatas[i].data.summaryStats[subjectId].componentFrequency
      )
    ) {
      return [];
    }
  }

  return equalizeFreqHelper(
    assessmentDatas.map((assessmentData: any) =>
      getChartDataForAssessment(assessmentData, subjectId, type, detailedView),
    ),
  );
};

export const getChartData = (
  assessmentDatas: any[],
  subjectId: string,
  type: string,
  detailedView: boolean | undefined,
): any[] => {
  if (type !== "Overall") {
    return getComponentChartData(
      assessmentDatas,
      subjectId,
      type,
      detailedView,
    );
  }

  for (let i = 0; i < assessmentDatas.length; i++) {
    if (isSummaryStatsInvalid(assessmentDatas[i], subjectId)) {
      return [];
    }
  }

  return equalizeFreqHelper(
    assessmentDatas.map((assessmentData: any) =>
      getChartDataForAssessment(assessmentData, subjectId, type, detailedView),
    ),
  );
};

// assessmentDatas is an array of data objs from our server
export const getCompareObject = (
  assessmentDatas: any,
  detailedView = true,
  subjectId = "0",
): any => {
  const compareObject: any = {};
  compareObject.Overall = getChartData(
    assessmentDatas,
    subjectId,
    "Overall",
    detailedView,
  );
  compareObject.Leg = getChartData(
    assessmentDatas,
    subjectId,
    "Leg",
    detailedView,
  );
  compareObject["Lower Arm"] = getChartData(
    assessmentDatas,
    subjectId,
    "Lower Arm",
    detailedView,
  );
  compareObject["Upper Arm"] = getChartData(
    assessmentDatas,
    subjectId,
    "Upper Arm",
    detailedView,
  );
  compareObject.Neck = getChartData(
    assessmentDatas,
    subjectId,
    "Neck",
    detailedView,
  );
  compareObject.Trunk = getChartData(
    assessmentDatas,
    subjectId,
    "Trunk",
    detailedView,
  );
  compareObject.Wrist = getChartData(
    assessmentDatas,
    subjectId,
    "Wrist",
    detailedView,
  );
  return compareObject;
};
