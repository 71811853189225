import React from "react";
import NotificationManager from "./NotificationManager";
import { TNotification } from "./Notification";
import Notifications from "./Notifications";

type Props = {
  enterTimeout?: number;
  leaveTimeout?: number;
};

type State = {
  notifications: TNotification[];
};

class NotificationContainer extends React.Component<Props, State> {
  static defaultProps: Props = {
    enterTimeout: 400,
    leaveTimeout: 400,
  };

  state = {
    notifications: [],
  };

  constructor(props: Props) {
    super(props);
    NotificationManager.addChangeListener(this.handleStoreChange);
  }

  componentWillUnmount() {
    NotificationManager.removeChangeListener(this.handleStoreChange);
  }

  handleStoreChange = (notifications: TNotification[]) => {
    this.setState({
      notifications,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  handleRequestHide = (notification: TNotification) => {
    NotificationManager.remove(notification);
  };

  render() {
    const { notifications } = this.state;
    const { enterTimeout, leaveTimeout } = this.props;
    return (
      <Notifications
        enterTimeout={enterTimeout}
        leaveTimeout={leaveTimeout}
        notifications={notifications}
        onRequestHide={this.handleRequestHide}
      />
    );
  }
}

export default NotificationContainer;
