import { IStorageAdapter } from "@kernel-adapters/storage";
import { AuthReduxState } from "./slice";

export const persistStore = async (
  state: AuthReduxState,
  storageAdapter: IStorageAdapter,
): Promise<AuthReduxState> => {
  const logOutHelper = (): void => {
    storageAdapter.removeItem("userObj");
    storageAdapter.removeItem("companyObj");
    storageAdapter.removeItem("id_token");
    if (window && window.location && window.location.href.startsWith("/app")) {
      window.location.href = "/user/login";
    }
  };

  const getCompanyCookieHelper = async (): Promise<any> => {
    const companyObj: string | null = await storageAdapter.getItem(
      "companyObj",
    );
    const company = companyObj !== null ? JSON.parse(companyObj) : null;
    if (company && company !== null && !("consultancy" in company)) {
      logOutHelper();
      return {};
    }
    return company;
  };

  const userObj: string | null = await storageAdapter.getItem("userObj");
  const companyObj: string | null = await getCompanyCookieHelper();

  return {
    ...state,
    user: userObj != null ? JSON.parse(userObj) : null,
    company: companyObj,
  };
};
