/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
const getBrowserLocale = (): string => {
  const locales: string[] = []
    .concat(
      window?.navigator?.languages as any,
      window?.navigator?.language as any,
      (window?.navigator as any)?.userLanguage as any,
      (window?.navigator as any)?.browserLanguage as any,
      (window?.navigator as any)?.systemLanguage as any,
    )
    .filter((locale) => locale)
    .map((locale) => String(locale as string).replace(/-.*/g, ""));

  const locale = locales.length > 0 ? locales[0] : "en";
  if (
    ![
      "en",
      "es",
      "de",
      "ja",
      "fr",
      "it",
      "pt",
      "ro",
      "id",
      "ar",
      "zh",
    ].includes(locale)
  ) {
    return "en";
  }

  return locale;
};

export const defaultMenuType: string = "menu-default";

export const subHiddenBreakpoint: number = 1440;
export const menuHiddenBreakpoint: number = 768;
export const defaultLocale: string = getBrowserLocale();

export type TLocaceOptions = {
  id: string;
  label: string;
  value: string;
  locale: string;
  direction: "ltr" | "rtl";
};
export const localeOptions: TLocaceOptions[] = [
  {
    id: "en",
    label: "English",
    value: "en",
    locale: "en-US",
    direction: "ltr",
  },
  {
    id: "es",
    label: "Español",
    value: "es",
    locale: "es-ES",
    direction: "ltr",
  },
  {
    id: "de",
    label: "Deutsch",
    value: "de",
    locale: "de-DE",
    direction: "ltr",
  },
  {
    id: "fr",
    label: "Français",
    value: "fr",
    locale: "fr-FR",
    direction: "ltr",
  },
  {
    id: "it",
    label: "Italiano",
    value: "it",
    locale: "it-IT",
    direction: "ltr",
  },
  {
    id: "pt",
    label: "Português",
    value: "pt",
    locale: "pt-BR",
    direction: "ltr",
  },
  { id: "ro", label: "Română", value: "ro", locale: "ro-RO", direction: "ltr" },
  {
    id: "id",
    label: "Bahasa Indonesia",
    value: "id",
    locale: "id-ID",
    direction: "ltr",
  },
  { id: "ja", label: "日本語", value: "ja", locale: "ja-JP", direction: "ltr" },
  { id: "zh", label: "中国人", value: "zh", locale: "zh-CH", direction: "ltr" },
  {
    id: "ar",
    label: "عربي",
    value: "ar",
    locale: "ar-AE",
    direction: "rtl",
  },
];

export const searchPath: string = "/app/pages/search";
export const servicePath: string = "https://api.coloredstrategies.com";

export const awsRegion: string = "us-east-1";

// dev
export type TCognitoConfig = {
  UserPoolId: string;
  ClientId: string;
};
export const cognitoConfig: TCognitoConfig = {
  UserPoolId: "us-east-1_tHpDUmzBu",
  ClientId: "6bl5v103snuaql4jiutp3qv5vi",
};

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive: boolean = false;
export const defaultColor: string = "tumeke.light";
export const defaultDirection: string = "ltr";
export const isDarkSwitchActive: boolean = true;
export const themeColorStorageKey: string = "__theme_color";
export const themeRadiusStorageKey: string = "__theme_radius";
export const isDemo: boolean = false;
