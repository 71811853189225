/* eslint-disable no-bitwise */
import { ChartConfig } from "./ChartConfig";
// import { Colors } from "./constants";

// TODO(znoland): move server side and do in Python?
// TODO(znoland): make rolling window a variable that can be changed by the user.
function cleanJointData(angleArray: any): any {
  // const rollingWindow = 3; // 20
  // const rollingWindowDenominator = rollingWindow * 2 + 1;
  /*
   * Fill gaps in data and set -1 to 0
   *
   * Note:
   *   - if degree is >-1, then get a previous value in the last 10 frames. If no previous values in last 10 frames
   *     are >-1, set as 0
   */
  // const cleanedData =  angleArray.map(
  //    (degree, index) =>
  //      (degree >= 0) ?
  //        degree :
  //        angleArray.slice(0, index).reverse().find((value) => value >= 0)

  /*
   * Get rolling 10 frame window, offset by 1, reverse order so findIndex gets the latest degree >0.
   * (findIndex always gets first match in array)
   * Then offset index by the number of frames/indexes away the new value is
   * (+2 since zero indexed and the slice is already 1 frame behind)
   */

  // If -1 set to 0
  //  )
  // let lastNonnegative = 0;
  // for (let i = 0; i < angleArray.length; i++) {
  //   if (angleArray[i] == -1) {
  //     angleArray[i] = null;
  //   }
  //   if (angleArray[i] == undefined) {
  //     angleArray[i] = null;
  //   }
  // }
  // console.log (angleArray)
  // Get rolling average across X prior and X leading frames
  const averagedData = angleArray;
  for (let i = 0; i < averagedData.length; i++) {
    if (averagedData[i] === -1.0) {
      averagedData[i] = null;
    }
  }

  /*
    const averagedData = angleArray.map((degree, index) =>
      index >= rollingWindow
        ? angleArray
            .slice(index - rollingWindow, index + rollingWindow)
            .reduce((a, b) => a + b) / rollingWindowDenominator
        : degree,
    );
    */
  return averagedData;
}

export interface IFormatData {
  labels: number[];
  datasets: any[];
}

export function formatData(
  allData: any[],
  chunk: number,
  jointMetadata: any,
  activeTab: string,
): IFormatData {
  const frames = allData.length;
  const length = frames / 30;

  // eslint-disable-next-line no-param-reassign
  allData = cleanJointData(allData);
  let start = 0;
  const end = length;
  const label = [0];
  while (start < end) {
    start += length / frames;
    label.push(start);
  }

  const arrays = [];

  Object.values(ChartConfig).forEach((value) => {
    const data = allData.map((degree: any, index: any) =>
      value.belongs(degree, jointMetadata, chunk, index, activeTab)
        ? degree
        : null,
    );
    arrays.push({
      ...JSON.parse(JSON.stringify(value)),
      data,
    });
  });

  arrays.push({
    label: "",
    data: Array(600).fill(null),
    borderColor: "red",
    pointBackgroundColor: "red",
    pointHoverBackgroundColor: "red",
    pointHoverBorderColor: "red",
    pointRadius: 5,
    pointBorderWidth: 0,
  });
  return {
    labels: label,
    datasets: arrays,
  };
}

/**
 * A lookup table for atob(), which converts an ASCII character to the
 * corresponding six-bit number.
 */
const atobLookup = (chr: string): number | undefined => {
  if (/[A-Z]/.test(chr)) {
    return chr.charCodeAt(0) - "A".charCodeAt(0);
  }
  if (/[a-z]/.test(chr)) {
    return chr.charCodeAt(0) - "a".charCodeAt(0) + 26;
  }
  if (/[0-9]/.test(chr)) {
    return chr.charCodeAt(0) - "0".charCodeAt(0) + 52;
  }
  if (chr === "+") {
    return 62;
  }
  if (chr === "/") {
    return 63;
  }
  // Throw exception; should not be hit in tests
  return undefined;
};

const UInt8toInt8 = (value: number): number =>
  value > 0x7f ? value - 0x100 : value;

export const base64ToFloat = (
  base64String: string,
  headerLength = 0,
): [number[], number[]] | null => {
  // Web IDL requires DOMStrings to just be converted using ECMAScript
  // ToString, which in our case amounts to using a template literal.
  let data = `${base64String}`;
  // "Remove all ASCII whitespace from data."
  data = data.replace(/[ \t\n\f\r]/g, "");
  // "If data's length divides by 4 leaving no remainder, then: if data ends
  // with one or two U+003D (=) code points, then remove them from data."
  if (data.length % 4 === 0) {
    data = data.replace(/==?$/, "");
  }
  // "If data's length divides by 4 leaving a remainder of 1, then return
  // failure."
  //
  // "If data contains a code point that is not one of
  //
  // U+002B (+)
  // U+002F (/)
  // ASCII alphanumeric
  //
  // then return failure."
  if (data.length % 4 === 1 || /[^+/0-9A-Za-z]/.test(data)) {
    return null;
  }

  // "Let output be an empty byte sequence."
  // let output = "";
  // "Let buffer be an empty buffer that can have bits appended to it."
  //
  // We append bits via left-shift and or.  accumulatedBits is used to track
  // when we've gotten to 24 bits.
  let buffer = 0;
  let accumulatedBits = 0;
  // "Let position be a position variable for data, initially pointing at the
  // start of data."
  //
  // "While position does not point past the end of data:"
  const dataBuf = new Uint8Array(4);
  const f32 = new Float32Array(dataBuf.buffer);
  const floatArray = [];
  let count = 0;
  const header: number[] = [];
  let headerCount = 0;
  for (let i = 0; i < data.length; i++) {
    // "Find the code point pointed to by position in the second column of
    // Table 1: The Base 64 Alphabet of RFC 4648. Let n be the number given in
    // the first cell of the same row.
    //
    // "Append to buffer the six bits corresponding to n, most significant bit
    // first."
    //
    // atobLookup() implements the table from RFC 4648.
    // eslint-disable-next-line no-bitwise
    buffer <<= 6;
    buffer |= atobLookup(data[i]) as number;
    accumulatedBits += 6;

    // "If buffer has accumulated 24 bits, interpret them as three 8-bit
    // big-endian numbers. Append three bytes with values equal to those
    // numbers to output, in the same order, and then empty buffer."
    if (accumulatedBits === 24) {
      if (headerCount < headerLength) {
        header[headerCount] = UInt8toInt8((buffer & 0xff0000) >> 16);
        headerCount += 1;
      } else {
        // eslint-disable-next-line no-bitwise
        dataBuf[count] = (buffer & 0xff0000) >> 16;
        if (count === 3) {
          floatArray.push(f32[0]);
          count = 0;
        } else {
          count += 1;
        }
      }

      if (headerCount < headerLength) {
        header[headerCount] = UInt8toInt8((buffer & 0xff00) >> 8);
        headerCount += 1;
      } else {
        dataBuf[count] = (buffer & 0xff00) >> 8;
        if (count === 3) {
          floatArray.push(f32[0]);
          count = 0;
        } else {
          count += 1;
        }
      }

      if (headerCount < headerLength) {
        header[headerCount] = UInt8toInt8(buffer & 0xff);
        headerCount += 1;
      } else {
        dataBuf[count] = buffer & 0xff;
        if (count === 3) {
          floatArray.push(f32[0]);
          count = 0;
        } else {
          count += 1;
        }
      }
      buffer = 0;
      accumulatedBits = 0;
    }
    // "Advance position by 1."
  }
  // "If buffer is not empty, it contains either 12 or 18 bits. If it contains
  // 12 bits, then discard the last four and interpret the remaining eight as
  // an 8-bit big-endian number. If it contains 18 bits, then discard the last
  // two and interpret the remaining 16 as two 8-bit big-endian numbers. Append
  // the one or two bytes with values equal to those one or two numbers to
  // output, in the same order."
  if (accumulatedBits === 12) {
    if (headerCount < headerLength) {
      header[headerCount] = UInt8toInt8(buffer & 0xff);
      headerCount += 1;
    } else {
      buffer >>= 4;
      dataBuf[count] = buffer & 0xff;
      if (count === 3) {
        floatArray.push(f32[0]);
        count = 0;
      } else {
        count += 1;
      }
    }
  } else if (accumulatedBits === 18) {
    buffer >>= 2;

    if (headerCount < headerLength) {
      header[headerCount] = UInt8toInt8((buffer & 0xff00) >> 8);
      headerCount += 1;
    } else {
      dataBuf[count] = (buffer & 0xff00) >> 8;
      if (count === 3) {
        floatArray.push(f32[0]);
        count = 0;
      } else {
        count += 1;
      }
    }

    if (headerCount < headerLength) {
      header[headerCount] = UInt8toInt8(buffer & 0xff);
      headerCount += 1;
    } else {
      dataBuf[count] = buffer & 0xff;
      if (count === 3) {
        floatArray.push(f32[0]);
        count = 0;
      } else {
        count += 1;
      }
    }
  }
  return [header, floatArray];
};

export const readHeaderAsInt = async (
  jointPath: string,
  headerLength: number,
  isForce = false,
): Promise<number[]> => {
  const headers: any = { Range: `bytes=0-${headerLength}}` };
  let blobData: Blob;
  try {
    const res = await fetch(jointPath, { headers });
    blobData = await res.blob();
    if (
      blobData.type !== "application/octet-stream" &&
      blobData.type !== "application/macbinary" &&
      blobData.type !== "binary/octet-stream"
    ) {
      return [];
    }
  } catch {
    return [];
  }

  const fileDataPromise: Promise<string> = new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    reader.onloadend = () => {
      const base64Data = String(reader.result)
        .replace("data:application/octet-stream;base64,", "")
        .replace("data:application/macbinary;base64,", "")
        .replace("data:binary/octet-stream;base64,", "");
      resolve(base64Data);
    };
  });
  const fileData = await fileDataPromise;
  const floats = base64ToFloat(fileData, headerLength);
  const header = floats ? floats[0] : [];
  if (
    header.length > 0 &&
    ((header[18] === -98 && header[19] === -99) || isForce)
  ) {
    return header;
  }

  return [];
};

export const readFileAsFloat = async (
  jointPath: string,
  headerLength = 0,
  range?: [number, number],
): Promise<[number, number[] | null, number[] | null]> => {
  const headers: any = {};
  if (range) {
    headers.Range = `bytes=${range[0] + headerLength}-${
      range[1] + headerLength
    }`;
  }
  let blobData: Blob;
  try {
    const res = await fetch(jointPath, { headers });
    blobData = await res.blob();
    if (
      blobData.type !== "application/octet-stream" &&
      blobData.type !== "application/macbinary" &&
      blobData.type !== "binary/octet-stream"
    ) {
      return [0, [], []];
    }
  } catch {
    return [0, [], []];
  }
  const fileDataPromise: Promise<string> = new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(blobData);
    reader.onloadend = () => {
      const base64Data = String(reader.result)
        .replace("data:application/octet-stream;base64,", "")
        .replace("data:application/macbinary;base64,", "")
        .replace("data:binary/octet-stream;base64,", "");
      resolve(base64Data);
    };
  });
  const fileData = await fileDataPromise;
  const floats = base64ToFloat(fileData, headerLength);

  return [blobData.size, floats ? floats[1] : [], floats ? floats[0] : []];
};
