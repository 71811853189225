import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getClientDeptCardsData as getClientDeptCardsDataApi,
  getClientDeptObjData as getClientDeptObjDataApi,
} from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";

export const getClientDeptCardsRequest = createAsyncThunk<
  { clientDeptCardsData: any },
  undefined,
  { state: ReduxState }
>("tree/getClientDeptCardsRequest", async (_, { getState }) => {
  const state = getState();
  const companyId = state.authUser.company.id;

  // const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // TODO(znoland): consider passing in timezone like the dashboard in the future

  let clientDeptCardsData = null;
  const clientDeptCardsDataCleaned: any[] = [];
  try {
    clientDeptCardsData = await getClientDeptCardsDataApi(
      companyId,
      state.tree.seeOnlyYourMetadata,
    );

    clientDeptCardsData.clientDepartmentCards.forEach((card: any) => {
      let cardName = card.name;
      if (!card.name) {
        cardName = state.authUser.company.consultancy
          ? "Client Unassigned"
          : "Department Unassigned";
      }

      clientDeptCardsDataCleaned.push({
        date: card.date,
        id: card.id,
        name: cardName,
        videoCount: card.videoCount,
      });
    });
  } catch (e: any) {
    console.log("Get client dept cards error", e);
    throw e;
  }

  return { clientDeptCardsData: clientDeptCardsDataCleaned };
});

export const getClientDeptObjRequest = createAsyncThunk<
  { clientDeptObjData: any; clientDeptObjJobs: any },
  { clientDeptOptionId: number },
  { state: ReduxState }
>("tree/getClientDeptObjRequest", async ({ clientDeptOptionId }) => {
  let clientDeptObjData = null;
  const clientDeptObjJobs: any[] = [];
  try {
    clientDeptObjData = await getClientDeptObjDataApi(clientDeptOptionId); // TODO(znoland): add toggle to filter on just user's vids

    clientDeptObjData.ClientDeptObject[0][1].forEach((job: any) => {
      clientDeptObjJobs.push({
        jobName: !job.job_name ? "Job Type Unassigned" : job.job_name,
        jobOptionId: !job.job_option_id ? 1 : job.job_option_id,
        videoCount: job.video_count,
        videos: job.videos,
      });
    });
  } catch (e: any) {
    console.log("Get client dept obj error", e);
    throw e;
  }
  return { clientDeptObjData, clientDeptObjJobs };
});

export const setMetadataViewPreference = createAsyncThunk<
  void,
  { value: boolean },
  { state: ReduxState }
>("tree/setMetadataViewPreference", async (_, { dispatch }) => {
  dispatch(getClientDeptCardsRequest());
});
