import { createAsyncThunk, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";

import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { updateAssessmentROI as updateAssessmentROIApi } from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";
import { computeROI } from "@kernel-store/roi/slice";
import { updateAssessmentROIResults } from "@kernel-store/videos/slice";

const updateSolutions = async ({
  getState,
  dispatch,
}: {
  getState: () => ReduxState;
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
}) => {
  dispatch(computeROI());
  const state = getState();
  console.log("setting item");
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  await stogareAdapter.setItem(
    "solutions",
    JSON.stringify({
      solutions: state.roi.solutions,
    }),
  );
};

const updateInjuries = async ({
  getState,
  dispatch,
}: {
  getState: () => ReduxState;
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
}) => {
  dispatch(computeROI());
  const state = getState();
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  await stogareAdapter.setItem(
    "injuries",
    JSON.stringify({
      injuries: state.roi.injuries,
    }),
  );
};

const updateIntro = async ({
  getState,
  dispatch,
}: {
  getState: () => ReduxState;
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
}) => {
  dispatch(computeROI());
  const state = getState();
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  await stogareAdapter.setItem("intro", JSON.stringify(state.roi.intro));
};

export const addEmptySolution = createAsyncThunk<
  void,
  undefined,
  { state: ReduxState }
>("roi/addEmptySolution", async (_, { getState, dispatch }) => {
  await updateSolutions({ getState, dispatch });
});

export const deleteSolution = createAsyncThunk<
  void,
  { id: string },
  { state: ReduxState }
>("roi/deleteSolution", async (_, { getState, dispatch }) => {
  await updateSolutions({ getState, dispatch });
});

export const addEmptyInjury = createAsyncThunk<
  void,
  undefined,
  { state: ReduxState }
>("roi/addEmptyInjury", async (_, { getState, dispatch }) => {
  await updateInjuries({ getState, dispatch });
});

export const deleteInjury = createAsyncThunk<
  void,
  { id: string },
  { state: ReduxState }
>("roi/deleteInjury", async (_, { getState, dispatch }) => {
  await updateInjuries({ getState, dispatch });
});

export const saveIntro = createAsyncThunk<
  void,
  { key: string; value: string | boolean | number | null },
  { state: ReduxState }
>("roi/saveIntro", async (_, { getState, dispatch }) => {
  await updateIntro({ getState, dispatch });
});

export const saveInjury = createAsyncThunk<
  void,
  {
    id: number | string | null;
    key: string | number;
    value: string | boolean | number | null;
  },
  { state: ReduxState }
>("roi/saveInjury", async (_, { getState, dispatch }) => {
  await updateInjuries({ getState, dispatch });
});

export const saveSolution = createAsyncThunk<
  void,
  {
    id: number | string | null;
    key: string | number;
    value: string | boolean | number | null;
  },
  { state: ReduxState }
>("roi/saveSolution", async (_, { getState, dispatch }) => {
  await updateSolutions({ getState, dispatch });
});

export const saveROIResults = createAsyncThunk<
  void,
  { videoId: string; assessmentId: number },
  { state: ReduxState }
>(
  "roi/saveROIResults",
  async ({ videoId, assessmentId }, { getState, dispatch }) => {
    const state = getState();
    try {
      const newRoiResuls: any = {
        solutions: [...state.roi.solutions],
        injuries: [...state.roi.injuries],
        results: [...state.roi.results],
        intro: { ...state.roi.intro },
      };
      await updateAssessmentROIApi(assessmentId, newRoiResuls);
      dispatch(
        updateAssessmentROIResults({
          videoId,
          assessmentId,
          results: newRoiResuls,
        }),
      );
    } catch (e: any) {
      NotificationManager.warning(
        "Error",
        e.response?.data?.message || "Unknown server error. Please try again",
        3000,
        null,
        null,
        "",
      );
    }
  },
);
