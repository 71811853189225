import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";

import { defaultLocale } from "@kernel-constants/defaultValues";

export type SettingsReduxState = {
  locale?: string | null;
  logo?: string | null;
};

export const settingsInitialState: SettingsReduxState = {
  locale: defaultLocale,
  logo: "",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsInitialState,
  reducers: {
    changeLocale: (state, action: PayloadAction<{ locale: string }>) => {
      const storageAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      storageAdapter.setItem(
        "currentLanguage",
        JSON.stringify(action.payload.locale),
      );
      state.locale = action.payload.locale;
    },
    setWebAppLogo: (state, action: PayloadAction<{ logo: string }>) => {
      state.logo = action.payload.logo;
    },
  },
});

export const { changeLocale, setWebAppLogo } = settingsSlice.actions;

export default settingsSlice.reducer;
