/* eslint-disable @typescript-eslint/no-throw-literal */
import * as db from "@tumeke/tumekejs/lib/utils/Database";
import { IImprovementData } from "@tumeke/tumekejs/lib/utils/interfaces";
// import { eThree } from "./VirgilHelpers";
import {
  createVirgilGroupWithSelf,
  encryptMessage,
} from "@tumeke/tumekejs/lib/auth";
import {
  POINTS3D_IN_SINGLE_PERSON,
  Joints3D,
  Joints3DNew,
} from "@kernel-helpers/skeletons/constants";
import { Config } from "@kernel-config";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  readHeaderAsInt,
  readFileAsFloat,
  formatData,
} from "@kernel-helpers/charts/ProcessJoints";
import { initialCompanyMetaData } from "./Constants.js";

export type TAccessGroup = {
  id?: number;
  user_id?: number;
  metadata_field_id?: number;
  metadata_option_id?: number;
};

// Keeping in DRY
/*
async function getCurrentUser(): Promise<any> {
  const storageAdapter = TumekeJSModule.get(EAdapterNames.Storage) as IStorageAdapter;
  const result = await storageAdapter.getItem("userObj");
  return result ? JSON.parse(result) : null;
}
*/

export async function checkLoginStatus(): Promise<any> {
  const ret = await db.checkLoginStatus();
  return ret;
}

// Used to generate arbitrary document IDs
const generatePushID: () => string = (() => {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  const PUSH_CHARS =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  // const lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  // const lastRandChars = [];

  return (): string => {
    let id = "";
    for (let i = 0; i < 20; i += 1) {
      id += PUSH_CHARS[Math.floor(Math.random() * PUSH_CHARS.length)];
    }
    return id;
  };
})();

export async function loginUser(): Promise<any> {
  const status = await db.loginUser();
  return status;
}

export async function addMetadataOptionHelper(
  field_id: number,
  option_name: string,
  parent_option_id: number,
): Promise<any> {
  const result = await db.addMetadataOption(
    field_id,
    option_name,
    parent_option_id,
  );
  return result;
}

export async function editMetadataOptionHelper(
  option_id: number,
  option_name: string,
): Promise<any> {
  const result = await db.editMetadataOption(option_id, option_name);
  return result;
}

export async function deleteMetadataOptionHelper(
  option_id: number,
): Promise<any> {
  const result = await db.deleteMetadataOption(option_id);
  return result;
}

export async function addCompanyMetadataFieldHelper(
  company_id: number,
  field_name: string,
  field_type: string,
  parent_field_id: number | null,
): Promise<any> {
  const result = await db.addCompanyMetadataField(
    company_id,
    field_name,
    field_type,
    parent_field_id,
  );

  return result;
}

export async function editCompanyMetadataFieldHelper(
  company_id: number,
  field_id: number,
  field_name: string,
  field_type: string,
  parent_field_id: number | null,
): Promise<any> {
  const result = await db.editCompanyMetadataField(
    company_id,
    field_id,
    field_name,
    field_type,
    parent_field_id,
  );

  return result;
}

export async function deleteCompanyMetadataFieldHelper(
  company_id: number,
  field_id: number,
): Promise<any> {
  const result = await db.deleteCompanyMetadataField(company_id, field_id);

  return result;
}

export async function addCompanyMetadataOptionHelper(
  company_id: number,
  field_id: number,
  option_name: string,
  parent_option_id: number | null,
): Promise<any> {
  const result = await db.addCompanyMetadataOption(
    company_id,
    field_id,
    option_name,
    parent_option_id,
  );

  return result;
}

export async function editCompanyMetadataOptionHelper(
  company_id: number,
  option_id: number,
  option_name: string,
  parent_option_id: number | null,
): Promise<any> {
  const result = await db.editCompanyMetadataOption(
    company_id,
    option_id,
    option_name,
    parent_option_id,
  );

  return result;
}

export async function deleteCompanyMetadataOptionHelper(
  company_id: number,
  option_id: number,
): Promise<any> {
  const result = await db.deleteCompanyMetadataOption(company_id, option_id);
  return result;
}

export async function getCompanyById(
  id: number,
  isAdmin?: boolean,
): Promise<any> {
  const result = await db.getCompanyById(id, isAdmin);
  return result;
}

export async function checkCompanyUserLimit(
  companyId?: number,
  companyName?: string,
): Promise<any> {
  const result = await db.checkCompanyUserLimit(
    companyId,
    companyName ? encodeURIComponent(companyName) : "",
  );
  return result;
}

export async function generateReport(
  videoId: string,
  assessmentId: number,
  subjectId: number,
  postureId?: number,
  reportType?: string,
  reportPages?: string[],
  lang?: string,
  requestId?: string | number | null,
): Promise<any> {
  const result = await db.generateReport(
    videoId,
    assessmentId,
    subjectId,
    postureId,
    reportType,
    reportPages,
    lang,
    requestId,
  );
  return result;
}

export async function generateGPTRecommendations(
  videoId: string,
  assessmentId: number,
  subjectId: number,
  jobDescription: string,
  lang?: string,
): Promise<any> {
  const result = await db.generateGPTRecommendations(
    videoId,
    assessmentId,
    subjectId,
    jobDescription,
    lang,
  );
  return result;
}

export async function externalGenerateGPTRecommendations(
  shortKey: string,
): Promise<any> {
  const result = await db.externalGenerateGPTRecommendations(shortKey);
  return result;
}

export async function getAssessmentOverTime(
  videoId: string,
  subjectId: number,
): Promise<any> {
  const result = await db.getAssessmentOverTime(videoId, subjectId);
  return result;
}

export async function downloadVideoRequest(
  videoId: string,
  aesKeys: any,
  email?: string,
): Promise<any> {
  let result: any;
  if (videoId.startsWith("view_")) {
    result = await db.externalDownloadVideoRequest(videoId, aesKeys, email);
  } else {
    result = await db.downloadVideoRequest(videoId, aesKeys);
  }
  return result;
}

export async function getVideosCSVRequest(
  filter_object: any,
  assessments_by_field_param: string,
  dateGranularityParam: string,
  clientTimezone: string,
  pageSize: number,
  pageOffset: number,
  search: string,
): Promise<any> {
  let newPageSize = pageSize;
  if (pageSize === undefined) {
    newPageSize = 8;
  }
  if (pageOffset === undefined) {
    newPageSize = 0;
  }
  let newSearch = search;
  if (search === undefined) {
    newSearch = "";
  }
  const result: any = await db.getVideosCSVRequest(
    filter_object,
    assessments_by_field_param,
    dateGranularityParam,
    clientTimezone,
    newPageSize,
    pageOffset,
    newSearch,
  );
  return result;
}

export async function downloadRiskJointsCsvRequest(
  videoId: string,
  assessmentId: number,
): Promise<any> {
  const result = await db.downloadRiskJointsCsvRequest(videoId, assessmentId);
  return result;
}

export async function generateViewOnlyLink(
  videoId: string,
  aesKeys: any,
  assessmentId: number,
  ttl: number,
): Promise<any> {
  const result = await db.generateViewOnlyLink(
    videoId,
    aesKeys,
    assessmentId,
    ttl,
  );
  return result;
}

export async function generateCompareViewOnlyLink(
  videoIds: string[],
  aesKeys: any,
  assessmentIds: number[],
  ttl: number,
): Promise<any> {
  const result = await db.generateCompareViewOnlyLink(
    videoIds,
    aesKeys,
    assessmentIds,
    ttl,
  );
  return result;
}

export async function generateDashboardViewOnlyLink(
  ttl: number,
  dashboard: any,
): Promise<any> {
  const result = await db.generateDashboardViewOnlyLink(ttl, dashboard);
  return result;
}

export async function migrateFirebaseIdtoCognito(email: string): Promise<any> {
  await db.migrateFirebaseIdtoCognito(email);
}

export async function setVideoMetadataFirebaseHelper(
  video_id: string,
  field_id: number,
  option_id: number,
): Promise<any> {
  const result = await db.setVideoMetadata(video_id, field_id, option_id);
  return result;
}

// export async function  getAllMetadataFieldsFirebaseHelper(company_metadata, video_id) {
//   const current_user = getCurrentUser()
//   /* Get fields and options */

//   Object.keys(company_metadata).forEach((key) => {
//     console.log('metadata company 2: ' + key)
//     optionsMetadataArray.push({...metadataFieldData[key], key});
//   });

//   console.log("options array: " + JSON.stringify(optionsMetadataArray))
//   /* Get currently selected values */
//   const video = await db.getVideoDoc(video_id);
//   const videoMetadataData = video['meta_data'];
//   let selectedMetadataObj = {};
//   if (videoMetadataData !== undefined) {
//     videoMetadataData.forEach((metadataField) => {
//       const data = metadataField.data();
//       const id = metadataField.id;
//       selectedMetadataObj[id] = data;
//       // selectedMetadataObj.push({...data, "key" : id});
//     });
//   }
//   return {
//     options: optionsMetadataArray,
//     selected: selectedMetadataObj
//   };

// }

export async function getSingleVideoDoc(video_id: string): Promise<any> {
  const videoDoc = await db.getVideoDoc(video_id);
  // TODO why do we need key? we already have id...
  const { data } = videoDoc;
  delete videoDoc.data;
  const processedVideoDoc = {
    ...data,
    ...videoDoc,
    key: videoDoc.id,
  };
  return processedVideoDoc;
}

export async function getAllUserVideos(
  filter_object: any,
  assessments_by_field_param: string,
  dateGranularityParam: string,
  clientTimezone: string,
  pageSize: number,
  pageOffset: number,
  search: string,
): Promise<any> {
  let newPageSize = pageSize;
  if (pageSize === undefined) {
    newPageSize = 8;
  }
  if (pageOffset === undefined) {
    newPageSize = 0;
  }
  let newSearch = search;
  if (search === undefined) {
    newSearch = "";
  }

  return db.getAllUserVideos(
    filter_object,
    assessments_by_field_param,
    dateGranularityParam,
    clientTimezone,
    newPageSize,
    pageOffset,
    newSearch,
  );
}

export async function getCompanyVideosByIds(videoIds: number[]): Promise<any> {
  const result = await db.getCompanyVideosByIds(videoIds);
  return result;
}

export async function getVideosForSingleUser(user_id: string): Promise<any> {
  const result = await db.getVideosByUserId(user_id);
  return result;
}

export async function deleteNotification(notif_id: number): Promise<any> {
  const result = await db.deleteNotification(notif_id);
  return result;
}

// Just get their user doc, nothing else
export async function getUserByCognitoId(cognito_id: string): Promise<any> {
  const user = await db.getUserByCognitoId(cognito_id);
  if (user === undefined) {
    throw "Not a risk suite user";
  }
  return user;
}

// Just get their user doc, nothing else
export async function getUserById(id: string): Promise<any> {
  const user = await db.getUserById(id);
  if (user === undefined) {
    throw "Not a risk suite user";
  }
  user.uid = user.id;
  return user;
}

// this function should be deprecated, the role is now an
// attribute of the user object
export async function determineUserRole(id: string): Promise<any> {
  const user = await db.getUserById(id);
  return user.role;
}

// TODO: use bcrypt
function generateAESKey(): string {
  let key = "";
  const hex = "0123456789abcdef";

  for (let i = 0; i < 32; i += 1) {
    key += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return key;
}

// Creates a new company doc
export async function createCompany(
  self_virgil_id: string,
  name: string,
  consultancy: any,
  user_id: number = 0,
  isAdmin: boolean = false,
  userFormObject?: any,
): Promise<any> {
  const companyVirgilId = generatePushID();
  await createVirgilGroupWithSelf(self_virgil_id, companyVirgilId);
  console.log("Created group");
  // Generate AES key & use Virgil to obfuscate
  const aesKeySDID = await generateAESKey();
  const aesKeyHDID = await generateAESKey();
  const aesKeySD = await encryptMessage(generateAESKey());
  const aesKeyHD = await encryptMessage(generateAESKey());

  // if(consultancy) {
  //   initialCompanyMetaData.unshift(consultancy_field)
  // } else {
  //   initialCompanyMetaData.unshift(non_consultancy_field)
  // }
  const companyRequest: db.ICreateCompanyReuqest = {
    name,
    aes_key: { [aesKeySDID]: aesKeySD, [aesKeyHDID]: aesKeyHD },
    meta_data: initialCompanyMetaData,
    virgil_id: companyVirgilId,
    consultancy,
    user_id,
    isAdmin,
  };
  if (typeof userFormObject?.limitType !== "undefined") {
    companyRequest.limitType = userFormObject.limitType;
  }
  if (typeof userFormObject?.assessmentLimit !== "undefined") {
    companyRequest.assessmentLimit = userFormObject.assessmentLimit;
  }
  if (typeof userFormObject?.meterStartDate !== "undefined") {
    companyRequest.meterStartDate = userFormObject.meterStartDate;
  }

  const company = await db.createCompany(companyRequest);
  console.log(`Company: ${JSON.stringify(company)}`);
  return company;
}

// Merge companies
export async function mergeCompanies(
  company1ID: number,
  company2ID: number,
  newAesKeys: { [key in string]: string },
): Promise<any> {
  const result = await db.mergeCompanies({
    company1ID,
    company2ID,
    newAesKeys,
  });
  console.log(`Companies merge result: ${JSON.stringify(result)}`);
  return result;
}

// Reassign admin
export async function reassignAdmin(
  companyID: number,
  user1ID: number,
  user2ID: number,
): Promise<any> {
  const result = await db.reassignAdmin({
    companyID,
    user1ID,
    user2ID,
  });
  console.log(`Reassign admin result: ${JSON.stringify(result)}`);
  return result;
}

export async function getGroup(group_id: string): Promise<any> {
  const result = await db.getGroup(group_id);
  return result;
}

// Returns an array with the users groups
export async function getUsersGroups(): Promise<any> {
  const result = (await db.getUsersGroups()).groups;
  return result;
}

// Writes to the user's document indicating that
// they are requesting to join the admin's company.
export async function joinCompanyRequest(
  company_name: string,
  company_id?: number,
  user_id?: number,
  isAdmin?: boolean,
): Promise<any> {
  let result: any;
  if (company_id) {
    result = await db.requestUserCompanyJoin(company_id, user_id, isAdmin);
  } else {
    const company = await db.getCompanyByName(encodeURIComponent(company_name));
    result = await db.requestUserCompanyJoin(company.id, user_id, isAdmin);
  }
  return result;
}

export async function getCompanyByName(company_name: string): Promise<any> {
  const result = await db.getCompanyByName(encodeURIComponent(company_name));
  return result;
}

export async function doesEmailExist(email: string): Promise<any> {
  const result = await db.doesEmailExist(encodeURIComponent(email));
  return result;
}

// eslint-disable no-param-reassign
export async function doesEmailExistHelper(
  email: string | undefined,
  emailChecked: any,
) {
  if (!email) {
    return null;
  }
  if (emailChecked[email]) {
    if (emailChecked[email].promise) {
      const result = await emailChecked[email].promise;
      return result;
    }
    return emailChecked[email].result;
  }
  // eslint-disable-next-line no-param-reassign
  emailChecked[email] = {
    promise: null,
    result: null,
  };
  // eslint-disable-next-line no-param-reassign
  emailChecked[email].promise = new Promise((resolve) => {
    (async () => {
      const emailExists = await doesEmailExist(email);
      // eslint-disable-next-line no-param-reassign
      emailChecked[email].promise = null;
      if (emailExists.message === "Yes") {
        // eslint-disable-next-line no-param-reassign
        emailChecked[email].result = true;
        resolve(true);
      } else if (emailExists.message === "No") {
        // eslint-disable-next-line no-param-reassign
        emailChecked[email].result = false;
        resolve(false);
      } else {
        resolve(null);
      }
    })();
  });
  const result = await emailChecked[email].promise;
  return result;
}

export async function checkSSO(email: string): Promise<any> {
  const result = await db.checkSSO(
    encodeURIComponent(email),
    `${Config.DEPLOY_URL}/user/redirect`,
  );
  return result;
}

export async function getCognitoFromCode(
  code: string,
  email: string,
): Promise<any> {
  const result = await db.getCognitoFromCode(
    code,
    `${Config.DEPLOY_URL}/user/redirect`,
    encodeURIComponent(email),
  );
  return result;
}

export async function updateVideoName(
  videoId: string,
  name: string,
): Promise<any> {
  const result = await db.updateVideoName(videoId, name);
  return result;
}

export async function updateAssessmentName(
  video_id: string,
  assessment_id: number,
  name: string,
): Promise<any> {
  const result = await db.updateAssessmentName(video_id, assessment_id, name);
  return result;
}

export async function updateVideoNotes(
  videoId: string,
  assessmentId: number,
  notes: any,
  notesKey: any,
): Promise<any> {
  const result = await db.updateVideoNotes(
    videoId,
    assessmentId,
    notes,
    notesKey,
  );
  return result;
}

export async function doesCompanyNameExist(company_name: string): Promise<any> {
  const result = await db.doesCompanyNameExist(
    encodeURIComponent(company_name),
  );
  return result;
}

export async function doesCompanyIdExist(company_id: string): Promise<any> {
  const result = await db.doesCompanyIdExist(company_id);
  return result;
}

export async function rerunAssessmentFull(video_id: string): Promise<any> {
  const result = await db.rerunAssessmentFull(video_id);
  return result;
}

export async function getJobJointData(video_id: string): Promise<any> {
  const result = await db.getJobJointData(video_id);
  return result;
}

export async function getExternalJobJointData(shortKey: string): Promise<any> {
  const result = await db.getExternalJobJointData(shortKey);
  return result;
}
/*
 * updateRiskComponentsFirebaseHelpers
 * --------------------
 * A function meant to update risk assessment form data
 * that *is* gathered from video
 * -------
 * Params:
 *  - uid: The user who own's the video
 *  - videoId: The ID of the video to update
 *  - assessment_id: The ID of the Assessment (RULA/REBA/NIOSH) for the video
 *  - personId: The person within the video whose info needs to be updated
 *  - newInfo: A map that looks like this:
 *    newInfo["type"]: The type of the additional information
 *    newInfo["bodyGroup"]: The body part the information is about
 *    newInfo["newValue"]: The new value to set
 *
 */

export async function updateRiskComponentsFirebaseHelper(
  video_id: string,
  assessment_id: number,
  person_id: number,
  new_info: any,
): Promise<any> {
  await db.updateRiskComponents({
    video_id,
    assessment_id,
    person_id,
    new_info,
  });
}

/*
 * updateAdditionalInfoFirebaseHelper
 * --------------------
 * A function meant to update risk assessment form data
 * that can't be gathered from video on Firebase
 * -------
 * Params:
 *  - uid: The user who own's the video
 *  - videoId: The ID of the video to update
 *  - assessment_id: The ID of the Assessment (RULA/REBA/NIOSH) for the video
 *  - personId: The person within the video whose info needs to be updated
 *  - newInfo: A map that looks like this:
 *    newInfo["type"]: The type of the additional information
 *    newInfo["bodyGroup"]: The body part the information is about
 *    newInfo["newValue"]: The new value to set
 *
 */
export async function updateAdditionalInfoFirebaseHelper(
  video_id: string,
  assessment_id: number,
  posture_id: number,
  new_info: any,
  new_metadata: any,
  request_id?: string,
  aes_keys?: any,
): Promise<any> {
  await db.updateAdditionalVideoInfo({
    video_id,
    assessment_id,
    posture_id,
    new_info,
    new_metadata,
    request_id,
    aes_keys,
  });
}

// Create a new group within a company (not a virgil group, a firebase group)
// Accepts the list of UIDs of the people in the group & the new name of this group
export async function createNewGroupWithUsers(
  users: any,
  groupName: string,
  accessGroups?: TAccessGroup[],
): Promise<any> {
  // const currentUserObj = await getCurrentUser();
  // const companyId = currentUserObj.company_id;
  const group = await db.createGroup(
    encodeURIComponent(groupName),
    accessGroups,
  );

  const promises = [];
  for (let i = 0; i < users.length; i += 1) {
    promises.push(
      db.addUserToGroup({ user_id: users[i].id, group_id: group.id }),
    );
  }
  await Promise.all(promises);

  const newGroup = { userData: users, groupName, key: group.id };
  return newGroup;
}

export async function updateGroup(
  groupId: number,
  groupName: string,
  accessGroups?: TAccessGroup[],
): Promise<void> {
  await db.updateGroup(groupId, groupName, accessGroups);
}

export async function deleteGroup(
  groupId: number,
  moveUsersToGroupId?: number,
): Promise<void> {
  await db.deleteGroup(groupId, moveUsersToGroupId);
}

export async function deleteTempPassword(user_id: number): Promise<any> {
  await db.deleteTempPassword(user_id);
}

export async function createUser(
  userFormObject: any,
  virgilId: string,
  companyId?: number,
  isAdmin: boolean = false,
): Promise<any> {
  // Creates a new user doc and sets the user's role. If you're creating the org,
  // you're an "admin", otherwise you're "requesting" access to the company.
  // The admin must grant access.updateAdditionalInfoFirebaseHelper
  let user = null;
  try {
    const { name, email, password, isSendEmail, emailTemplate } =
      userFormObject;
    user = await db.createUser({
      name,
      email,
      password,
      virgil_id: virgilId,
      isSendEmail,
      emailTemplate,
      companyId,
      isAdmin,
    });
    return user;
  } catch (err) {
    console.log(err);
  }
  return null;
}

export async function addFeedback(
  id: string,
  rating: any,
  feedbackText: string,
): Promise<any> {
  const result = await db.addFeedback(
    id,
    `"${rating}" start rating: ${feedbackText}`,
    "video feedback",
  );
  return result;
}

// When a user requests to join a company there is a document
// written to the admin's 'requesting' collection. Once they accept
// the request this document can be deleted and the user's role
// can be updated
export async function acceptUserIntoCompanyFirebaseHelper(
  id: number,
  sendEmail: boolean = false,
): Promise<any> {
  await db.acceptUserCompanyJoin(id, sendEmail);
}

export async function denyUserRequest(id: number): Promise<void> {
  await db.denyUserCompanyJoin(id);
}

export async function getRequestingUsers(): Promise<any> {
  const result = (await db.getRequestingUsers()).users;
  return result;
}

export async function getCompanysGroups(): Promise<any> {
  const result = await db.getCompanysGroups();
  return result;
}

export async function addUserToGroup(
  user_id: number,
  group_id: number,
): Promise<any> {
  const result = await db.addUserToGroup({ user_id, group_id });
  return result;
}

export async function removeUserFromGroup(
  user_id: number,
  group_id: number,
): Promise<any> {
  await db.removeUserFromGroup({ user_id, group_id });
}

export async function getCompaniesGroups(): Promise<any> {
  const groupIds = (await db.getGroupsByCompany()).groups;
  const promises = groupIds.map((groupId: string) =>
    db.getGroup(groupId).then((group: any) => ({
      userData: group.users,
      groupName: group.name,
      key: group.id,
    })),
  );
  const userGroupObjs = await Promise.all(promises);
  return userGroupObjs;
}

// call server to grab chunks if not
// TODO not implemented yet
export async function getVideoJsonsLegacy(
  uid: string,
  videoId: string,
  personId: number,
  jointMetadata: any,
  chunk: number,
): Promise<any> {
  const jsons: any = {};
  let { url } = await db.getVideoJointData(videoId, chunk);
  if (videoId === "example" && window && window.location) {
    url = `${window.location.origin}/assets/example/video.json`;
  }

  const res = await fetch(url);
  const resJson = await res.json();
  if (!resJson || !resJson[personId] || !resJson[personId].Joint_Angles_3D) {
    return jsons;
  }
  Object.keys(resJson[personId].Joint_Angles_3D).forEach((key: string) => {
    let individualJointMetadata = null;
    if (jointMetadata) {
      individualJointMetadata = jointMetadata[String(personId)];
    }
    jsons[key] = formatData(
      resJson[personId].Joint_Angles_3D[key],
      chunk,
      individualJointMetadata,
      key,
    );
  });
  return jsons;
}

export async function getDecodedPointsFromUrl(
  url: string,
  numPeople: number,
  numAngles: number,
  rangeFrom: number,
  rangeTo: number,
  headerLength: number,
): Promise<[number, number[]]> {
  const range: [number, number] = [
    rangeFrom + headerLength,
    rangeTo + headerLength,
  ];
  const jointsData = await readFileAsFloat(url, 0, range);
  if (!jointsData[0]) {
    return [0, []];
  }
  const framesLength = jointsData[0] / (numPeople * numAngles * 4);
  const decodedPoints: number[] = jointsData[1] as number[];

  return [framesLength, decodedPoints];
}

export async function getVideoJsons(
  url: string,
  numPeople: number,
  personId: number,
  jointMetadata: any,
  chunk: number,
): Promise<any> {
  const headerLength = 20;
  const header = await readHeaderAsInt(url, headerLength);
  // New 3d joint
  let numAngles: number = POINTS3D_IN_SINGLE_PERSON;
  if (header.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    numAngles = header[1];
  }

  const frames: [number, number] = [chunk * 600, (chunk + 1) * 600 - 1];
  const rangeFrom = frames[0] * numAngles * 4;
  const rangeTo = frames[1] * numAngles * 4;

  const [framesLength, decodedPoints3d] = await getDecodedPointsFromUrl(
    url,
    numPeople,
    numAngles,
    rangeFrom,
    rangeTo,
    header.length,
  );
  const jsons: any = {};
  if (!framesLength) {
    return jsons;
  }
  let pointsPos = 0;
  const points3dInFrame = numAngles * numPeople;
  const angles: { [key: string]: number[] } = {};
  const RealJoints3D = numAngles === 10 ? Joints3DNew : Joints3D;
  RealJoints3D.forEach((joint) => {
    angles[joint] = [];
  });
  for (let i = 0; i < framesLength; i += 1) {
    const peoplePoints3d = decodedPoints3d.slice(
      pointsPos * points3dInFrame,
      pointsPos * points3dInFrame + points3dInFrame,
    );
    const personPoints3d = peoplePoints3d.slice(
      personId * numAngles,
      personId * numAngles + numAngles,
    );
    RealJoints3D.forEach((joint, index) => {
      angles[joint].push(personPoints3d[index]);
    });
    pointsPos += 1;
  }
  Object.keys(angles).forEach((key: string) => {
    let individualJointMetadata = null;
    if (jointMetadata) {
      individualJointMetadata = jointMetadata[String(personId)];
    }
    jsons[key] = formatData(angles[key], chunk, individualJointMetadata, key);
  });
  return jsons;
}

export async function deleteVideosFirebaseHelper(
  userIds: any[],
  videoIds: string[],
): Promise<any> {
  await db.deleteVideos(videoIds);
}

export async function deletePosture(postureId: string): Promise<any> {
  await db.deletePosture(postureId);
}

export async function deleteAssessment(assessmentId: number): Promise<any> {
  await db.deleteAssessment(assessmentId);
}

export async function deleteClip(
  videoId: string,
  clipId: number,
): Promise<any> {
  await db.deleteClip(videoId, clipId);
}

export async function setPrimaryAssessment(assessmentId: number): Promise<any> {
  await db.setPrimaryAssessment(assessmentId);
}

export async function getVideoManifest(video_id: string): Promise<any> {
  let result: any;
  if (video_id.startsWith("view_")) {
    result = await db.externalGetVideoManifest(video_id);
  } else {
    result = await db.getVideoManifest(video_id);
  }
  return result;
}

export async function getThumbnail(video_id: string): Promise<any> {
  if (video_id.startsWith("view_")) {
    return null;
  }
  const result = await db.getThumbnail(video_id);
  return result;
}

export async function getPostureThumbnail(
  video_id: string,
  frame_num: number,
): Promise<any> {
  const result = await db.getPostureThumbnail(video_id, frame_num);
  return result;
}

export async function getVideoSlice(
  video_id: string,
  res: number | string,
  slice: number | string,
): Promise<any> {
  let result: any;
  if (video_id.startsWith("view_")) {
    result = await db.externalGetVideoSlice(video_id, res, slice);
  } else {
    result = await db.getVideoSlice(video_id, res, slice);
  }
  return result;
}

export async function getStatVideoCount(): Promise<null> {
  return null;
}

export async function externalGetVideoDoc(shortKey: string): Promise<any> {
  const result = await db.externalGetVideoDoc(shortKey);
  return result;
}

export async function auth(): Promise<any> {
  const storageAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  const result = await storageAdapter.getItem("userObj");
  return result;
}

export async function getDashboardData(
  filter_object: any,
  assessments_by_field_param: any,
  dateGranularityParam: any,
  clientTimezone: any,
  aggType: any,
): Promise<any> {
  console.log("filter_object--------->", filter_object);
  console.log(
    "assessments_by_field_param--------->",
    assessments_by_field_param,
  );
  console.log("dateGranularityParam----------->", dateGranularityParam);
  console.log("clientTimezone----------->", clientTimezone);
  const result = await db.getDashboardData(
    filter_object,
    assessments_by_field_param,
    dateGranularityParam,
    clientTimezone,
    aggType || "count_assessment",
  );
  return result;
}

export async function getDashboardDataExternal(shortKey: string): Promise<any> {
  const result = await db.getDashboardDataExternal(shortKey);
  return result;
}

export async function getDashboardFilters(): Promise<any> {
  const result = await db.getDashboardFilters();
  return result;
}

export async function getUserVirgilId(email: string): Promise<any> {
  const result = await db.getUserVirgilId(encodeURIComponent(email));
  return result;
}

export async function checkAccountStatus(email: string): Promise<any> {
  const result = await db.checkAccountStatus(email);
  return result;
}

export async function getVideoComparisonData(
  video_list: string[],
): Promise<any> {
  const result = await db.getVideoComparisonData(video_list);
  return result;
}

export async function getClientDeptCardsData(
  company_id: string,
  seeOnlyYourMetadata: boolean,
): Promise<any> {
  if (seeOnlyYourMetadata) {
    const result = await db.getClientDepartmentCards(company_id, 2);
    return result;
  }
  const result = await db.getClientDepartmentCards(company_id, 1);
  return result;
}

export async function getClientDeptObjData(
  client_dept_option_id: number,
): Promise<any> {
  const result = await db.getClientDeptObject(client_dept_option_id);
  return result;
}

export async function getUserData(
  userId: number,
  withEmails?: boolean,
  withCompany?: boolean,
): Promise<any> {
  const result = await db.getUserData(userId, withEmails, withCompany);
  return result;
}

export async function getCognitoUserById(id: number) {
  const user = await db.getCognitoUserById(id);

  return user;
}

export async function updateCognitoUserAttributes(id: number, attrs: any) {
  const user = await db.updateCognitoUserAttributes(id, attrs);

  return user;
}

export async function saveUserData(
  userId: number,
  data: any,
  isAdmin: boolean,
): Promise<any> {
  const result = await db.saveUserData(userId, data, isAdmin);
  return result;
}

export async function setUserRole(userId: number, role: string): Promise<any> {
  const result = (await db.setUserRole(userId, role)).groups;
  return result;
}

export async function deleteUsers(
  userIds: number[],
  isFull: boolean = false,
): Promise<any> {
  const result = await db.deleteUsers(userIds, isFull);
  return result;
}

export async function getUsersData(filter_object: any): Promise<any> {
  console.log("filter_object--------->", filter_object);
  const result = await db.getUsersData(filter_object);
  return result;
}

export async function getCompaniesData(filter_object: any): Promise<any> {
  console.log("filter_object--------->", filter_object);
  const result = await db.getCompaniesData(filter_object);
  return result;
}

export async function updateCompanyPolicies(
  companyId: number,
  policiesData: any,
): Promise<any> {
  const result = await db.updateCompanyPolicies(companyId, policiesData);
  return result;
}

export async function deleteCompany(companyId: number): Promise<any> {
  const result = await db.deleteCompany(companyId);
  return result;
}

export async function getTempPasswordsData(filter_object: any): Promise<any> {
  console.log("filter_object--------->", filter_object);
  const result = await db.getTempPasswordsData(filter_object);
  return result;
}

export async function getLinksData(): Promise<any> {
  const result = await db.getLinksData();
  return result;
}

export async function updateLinkHelper(
  linkId: number,
  values: any,
): Promise<any> {
  const result = await db.updateLink(linkId, values);
  return result;
}

export async function deleteLinkHelper(linkId: number): Promise<any> {
  const result = await db.deleteLink(linkId);
  return result;
}

export async function sendLinkHelper(
  linkId: number,
  value: string,
  type: string,
): Promise<any> {
  const result = await db.sendLink(linkId, value, type);
  return result;
}

export async function computeEarthMover(
  summaryTable1: any,
  summaryTable2: any,
): Promise<any> {
  const result = await db.computeEarthMover(summaryTable1, summaryTable2);
  return result;
}

export async function updateAssessmentROI(
  assessmentId: number,
  results: any,
): Promise<any> {
  const result = await db.updateAssessmentROI(assessmentId, results);
  return result;
}

export async function updateCompanySettingsHelper(
  companyId: number,
  key: string,
  value: string,
): Promise<any> {
  const result = await db.updateCompanySettings(companyId, key, value);
  return result;
}

export async function saveBaselineTask(
  name: string,
  cutoffs: any,
  videoVIds: string[],
  id?: number,
): Promise<any> {
  const result = await db.saveBaselineTask(name, cutoffs, videoVIds, id);
  return result;
}

export async function deleteBaselineTask(id: number): Promise<any> {
  const result = await db.deleteBaselineTask(id);
  return result;
}

export async function getConsentUrl(
  companyId: number,
): Promise<{ url: string }> {
  const result = await db.getConsentUrl(companyId);
  return result;
}

export async function getImprovements(
  filter: any,
  pageSize: number,
  pageOffset: number,
): Promise<any> {
  let newPageSize = pageSize;
  if (pageSize === undefined) {
    newPageSize = 8;
  }
  if (pageOffset === undefined) {
    newPageSize = 0;
  }

  const result = await db.getImprovements(filter, newPageSize, pageOffset);
  return result;
}

export async function getImprovement(improvementId: number): Promise<any> {
  const result = await db.getImprovement(improvementId);
  return result;
}

export async function saveImprovement(
  improvementData: IImprovementData,
  improvementId?: number,
): Promise<any> {
  const result = db.saveImprovement(improvementData, improvementId);
  return result;
}

export async function deleteImprovement(improvementId?: number): Promise<any> {
  const result = db.deleteImprovement(improvementId);
  return result;
}

export async function sendCompanyNotification(
  companyId: number,
  message: string,
): Promise<any> {
  const result = await db.sendCompanyNotification(companyId, message);
  return result;
}

export async function checkUserMfa(email: string): Promise<any> {
  const result = await db.checkUserMfa(email);
  return result;
}
