import { IConfigAdapter, TConfigFormat } from "@kernel-adapters/config";

/* Deployment configuration */

// The forward slash has to be at the end of the url
// TODO: Find JS equivalent of Python os.path.join
export type TConfig = {
  FIREBASE_CONFIG: any | null;
  RISK_SUITE_BUCKET: string | null;
  TUMEKE_API: string | null;
  TUMEKE_WEBSOCKET_API: string | null;
  TUMEKE_ADMIN_PANEL_API: string | null;
  TUMEKE_SERVER_API: string | null;
  TUMEKE_SERVER_WEBSOCKET_API: string | null;
  MAX_DEMO_VIDEOS: number | null;
  VIDEO_DELETE_TIME: number | null;
  DEMO_VIDEO_UIDS: string | null;
  DEMO_EMAIL: string | null;
  DEMO_PASSWORD: string | null;
  DEMO_UID: string | null;
  DEMO_CLEARKEY: any | null;
  AWS_REGION: string | null;
  COGNITO_EXPIRE_TIME_MINS: number | null;
  SENTRY_ENV: string | null;
  DEPLOY_URL: string | null;
  US_URL: string | null;
  EU_URL: string | null;
  COGNITO_POOL: string | null;
  COGNITO_CLIENT_ID: string | null;
  SUPPORT_EMAIL: string | null;
  MAX_VIDEO_DURATION: number | null;
  init: (configAdapter: IConfigAdapter) => void;
};

export const Config: TConfig = {
  FIREBASE_CONFIG: null,
  RISK_SUITE_BUCKET: null,
  TUMEKE_API: null,
  TUMEKE_ADMIN_PANEL_API: null,
  TUMEKE_SERVER_API: null,
  TUMEKE_SERVER_WEBSOCKET_API: null,
  TUMEKE_WEBSOCKET_API: null,
  MAX_DEMO_VIDEOS: null,
  VIDEO_DELETE_TIME: null,
  DEMO_VIDEO_UIDS: null,
  DEMO_EMAIL: null,
  DEMO_PASSWORD: null,
  DEMO_UID: null,
  DEMO_CLEARKEY: null,
  AWS_REGION: null,
  COGNITO_EXPIRE_TIME_MINS: null,
  SENTRY_ENV: null,
  DEPLOY_URL: null,
  US_URL: null,
  EU_URL: null,
  COGNITO_POOL: null,
  COGNITO_CLIENT_ID: null,
  MAX_VIDEO_DURATION: null,
  SUPPORT_EMAIL: null,
  init: (configAdapter: IConfigAdapter) => {
    Config.FIREBASE_CONFIG = JSON.parse(
      configAdapter.env(
        "REACT_APP_FIREBASE_CONFIG",
        TConfigFormat.Base64,
        true,
      ) as string,
    );
    Config.RISK_SUITE_BUCKET = configAdapter.env(
      "REACT_APP_RISK_SUITE_BUCKET",
      TConfigFormat.String,
      true,
    ) as string;
    Config.MAX_DEMO_VIDEOS = configAdapter.env(
      "REACT_APP_MAX_DEMO_VIDEOS",
      TConfigFormat.IntPositive,
      true,
    ) as number;
    Config.VIDEO_DELETE_TIME = configAdapter.env(
      "REACT_APP_VIDEO_DELETE_TIME",
      TConfigFormat.IntPositive,
      true,
    ) as number;
    Config.DEMO_VIDEO_UIDS = configAdapter.env(
      "REACT_APP_DEMO_VIDEO_UIDS",
      TConfigFormat.String,
      true,
    ) as string;
    Config.DEMO_EMAIL = configAdapter.env(
      "REACT_APP_DEMO_EMAIL",
      TConfigFormat.String,
      true,
    ) as string;
    Config.DEMO_PASSWORD = configAdapter.env(
      "REACT_APP_DEMO_PASSWORD",
      TConfigFormat.String,
      true,
    ) as string;
    Config.DEMO_UID = configAdapter.env(
      "REACT_APP_DEMO_UID",
      TConfigFormat.String,
      true,
    ) as string;
    Config.DEMO_CLEARKEY = JSON.parse(
      configAdapter.env(
        "REACT_APP_DEMO_CLEARKEY",
        TConfigFormat.Base64,
        true,
      ) as string,
    );
    Config.AWS_REGION = configAdapter.env(
      "REACT_APP_AWS_REGION",
      TConfigFormat.String,
      true,
    ) as string;
    Config.COGNITO_EXPIRE_TIME_MINS = configAdapter.env(
      "REACT_APP_COGNITO_EXPIRE_TIME_MINS",
      TConfigFormat.String,
      true,
    ) as number;
    Config.SENTRY_ENV = configAdapter.env(
      "REACT_APP_SENTRY_ENV",
      TConfigFormat.String,
      true,
    ) as string;
    Config.DEPLOY_URL = configAdapter.env(
      "REACT_APP_DEPLOY_URL",
      TConfigFormat.String,
      true,
    ) as string;
    Config.US_URL = configAdapter.env(
      "REACT_APP_US_URL",
      TConfigFormat.String,
      true,
    ) as string;
    Config.EU_URL = configAdapter.env(
      "REACT_APP_EU_URL",
      TConfigFormat.String,
      true,
    ) as string;
    Config.COGNITO_POOL = configAdapter.env(
      "REACT_APP_COGNITO_POOL",
      TConfigFormat.String,
      true,
    ) as string;
    Config.COGNITO_CLIENT_ID = configAdapter.env(
      "REACT_APP_COGNITO_CLIENT_ID",
      TConfigFormat.String,
      true,
    ) as string;
    Config.TUMEKE_API = configAdapter.env(
      "REACT_APP_TUMEKE_API",
      TConfigFormat.String,
      true,
    ) as string;
    Config.TUMEKE_ADMIN_PANEL_API = configAdapter.env(
      "REACT_APP_TUMEKE_ADMIN_PANEL_API",
      TConfigFormat.String,
      true,
    ) as string;
    Config.TUMEKE_SERVER_API = configAdapter.env(
      "REACT_APP_TUMEKE_SERVER_API",
      TConfigFormat.String,
      true,
    ) as string;
    Config.TUMEKE_SERVER_WEBSOCKET_API = configAdapter.env(
      "REACT_APP_TUMEKE_SERVER_WEBSOCKET_API",
      TConfigFormat.String,
      true,
    ) as string;
    Config.TUMEKE_WEBSOCKET_API = configAdapter.env(
      "REACT_APP_TUMEKE_WEBSOCKET_API",
      TConfigFormat.String,
      true,
    ) as string;
    Config.MAX_VIDEO_DURATION = configAdapter.env(
      "REACT_APP_MAX_VIDEO_DURATION",
      TConfigFormat.Float,
      true,
    ) as number;
    Config.SUPPORT_EMAIL =
      (configAdapter.env(
        "REACT_APP_SUPPORT_EMAIL",
        TConfigFormat.String,
        true,
      ) as string) || "support@tumeke.io";
  },
};

/* PROD config */
// export const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyD7yjrlmJyph3KQElKs84gcux9oziOJzbw",
//     authDomain: "tumekeapi.firebaseapp.com",
//     databaseURL: "https://tumekeapi.firebaseio.com",
//     projectId: "tumekeapi",
//     storageBucket: "tumekeapi.appspot.com",
//     messagingSenderId: "167469950474",
//     appId: "1:167469950474:web:026444e2dc1519c7b161f3"
// };

// export const TUMEKE_API = "https://user.tumeke.io/";
// export const MAX_DEMO_VIDEOS = 2;
// export const VIDEO_DELETE_TIME = 10;
// export const DEMO_VIDEO_UIDS = "b2384fb2-08b2-49f7-9baf-421b7c56b6f2,b8b88120-ab5e-4df3-bd76-afe2bde91ddf";
// export const DEMO_EMAIL = "demo_account@tumeke.io";
// export const DEMO_PASSWORD = "asdfasdf";
// export const DEMO_UID = "6Kns3J1GhJN48ZQgnsBnBuGvcW23";
// export const DEMO_CLEARKEY = {
// 	"552e3788efed7a175a031285ee146b99":"913cb361af8eb1656404b31ad5dd5770",
// 	"65cddc9c49d4f5edfc1221ee390171b0":"58a0d895a7334919efc78af67c1b9f56"
// }
