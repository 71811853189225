import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EMPTY_FILTER, TDashboardFilter } from "@kernel-store/dashboard/slice";
import {
  savePolicesRequest,
  getCompaniesDataRequest,
} from "@kernel-store/companies/thunk";

export type CompaniesReduxState = {
  companiesData: any;
  companiesCount: number;
  companiesDataLoading: boolean;
  companiesFilters: any;
  companiesDataDirty: boolean;
  filterObject: TDashboardFilter;
  refresh: boolean;
};

export const companiesInitialState: CompaniesReduxState = {
  companiesData: [],
  companiesCount: 0,
  companiesDataLoading: false,
  companiesFilters: {},
  companiesDataDirty: false,
  filterObject: EMPTY_FILTER,
  refresh: false,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState: companiesInitialState,
  reducers: {
    deleteCompany: (state, action: PayloadAction<{ companyId: number }>) => {
      const companyIndex = state.companiesData.findIndex(
        (company: any) => company.id === action.payload.companyId,
      );
      if (companyIndex > -1) {
        state.companiesData.splice(companyIndex, 1);
        state.companiesCount -= 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(savePolicesRequest.pending, (state) => {
      state.companiesDataLoading = true;
    });
    builder.addCase(savePolicesRequest.fulfilled, (state, action) => {
      const companyIndex = state.companiesData.findIndex(
        (company: any) => company.id === action.payload.companyId,
      );
      if (companyIndex > -1) {
        const companyKeys = Object.keys(action.payload.companyData) as string[];
        companyKeys.forEach((companyKey) => {
          state.companiesData[companyIndex][companyKey] =
            action.payload.companyData[companyKey];
        });
      }
      state.companiesDataLoading = false;
    });
    builder.addCase(savePolicesRequest.rejected, (state) => {
      state.companiesDataLoading = false;
    });
    builder.addCase(getCompaniesDataRequest.pending, (state) => {
      state.companiesDataLoading = true;
    });
    builder.addCase(getCompaniesDataRequest.fulfilled, (state, action) => {
      state.companiesData = action.payload.companiesData.items;
      state.companiesCount = action.payload.companiesData.count;
      state.companiesDataLoading = false;
    });
    builder.addCase(getCompaniesDataRequest.rejected, (state) => {
      state.companiesDataLoading = false;
    });
  },
});

export const { deleteCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
