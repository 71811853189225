import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import classnames from "classnames";
import Notification, { TNotification } from "./Notification";

type Props = {
  notifications?: TNotification[];
  onRequestHide?: (notification: TNotification) => void;
  enterTimeout?: number;
  leaveTimeout?: number;
};

class Notifications extends React.Component<Props> {
  static defaultProps = {
    notifications: [],
    onRequestHide: () => null,
    enterTimeout: 400,
    leaveTimeout: 400,
  };

  handleRequestHide = (notification: TNotification) => () => {
    const { onRequestHide } = this.props;
    if (onRequestHide) {
      onRequestHide(notification);
    }
  };

  render() {
    const { notifications, enterTimeout, leaveTimeout } = this.props;
    const className = classnames("notification-container", {
      "notification-container-empty": (notifications?.length || 0) === 0,
    });
    return (
      <div className={className}>
        <TransitionGroup>
          {notifications?.map((notification) => {
            const key = notification.id || new Date().getTime();
            return (
              <CSSTransition
                classNames="notification"
                key={key}
                timeout={{ exit: leaveTimeout, enter: enterTimeout }}
              >
                <Notification
                  key={key}
                  type={notification.type}
                  title={notification.title}
                  message={notification.message}
                  timeOut={notification.timeOut}
                  onClick={notification.onClick}
                  onRequestHide={this.handleRequestHide(notification)}
                  customClassName={notification.customClassName}
                  id="globalNotification"
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    );
  }
}

export default Notifications;
