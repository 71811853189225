import JointDefinitions from "./joint_definitions.json";
import RawJointColors from "./joint_colors.json";
import RawDeviationColors from "./deviation_colors.json";

export { JointDefinitions, RawJointColors, RawDeviationColors };

export const Joints3D = [
  "Hip",
  "Left shoulder",
  "Right shoulder",
  "Left elbow",
  "Right elbow",
  "Neck",
  "Left wrist",
  "Right wrist",
];
export const Joints3DNew = [
  "Hip",
  "Left shoulder",
  "Right shoulder",
  "Left elbow",
  "Right elbow",
  "Neck",
  "Left wrist",
  "Right wrist",
  "Left leg",
  "Right leg",
];
export const JOINT_DEF_POS: number = 0;
export const HAND_DEF_POS: number = 3;
export const POINTS_IN_SINGLE_PERSON: number =
  JointDefinitions[JOINT_DEF_POS].joints.length * 2;
export const POINTS3D_IN_SINGLE_PERSON: number = Joints3D.length;
export const SCORES_IN_SINGLE_PERSON: number =
  JointDefinitions[JOINT_DEF_POS].joints.length;
export const HANDS_IN_SINGLE_PERSON: number =
  JointDefinitions[HAND_DEF_POS].joints.length * 2;
export const WRNCH_CONFIDENCE_CUTOFF: number = 0.35;
export const LOWER_ARM_CUTOFF: number =
  RawJointColors.constants.lower_arm_cutoff;
export const DEFAULT_JOINT_COLOR: string = "rgb(0,255,0)";
