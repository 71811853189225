import mixpanel from "mixpanel-browser";
import { Config } from "@kernel-config";

mixpanel.init("42992e7d018f423529c62f0e5322238b");

const isProduction = process.env.NODE_ENV === "production";
// let isProduction = true;

const actions = {
  identify: (id: any): void => {
    if (Config.AWS_REGION === "eu-central-1") return;
    if (isProduction) mixpanel.identify(id);
  },
  alias: (id: any): void => {
    if (Config.AWS_REGION === "eu-central-1") return;
    if (isProduction) mixpanel.alias(id);
  },
  track: (name: any, props: any): void => {
    if (Config.AWS_REGION === "eu-central-1") return;
    if (isProduction) mixpanel.track(name, props);
  },
  people: {
    set: (props: any): void => {
      if (Config.AWS_REGION === "eu-central-1") return;
      if (isProduction) mixpanel.people.set(props);
    },
  },
  reset: (): void => {
    if (Config.AWS_REGION === "eu-central-1") return;
    if (isProduction) mixpanel.reset();
  },
};

export const Mixpanel = actions;
