import {
  AnyAction,
  combineReducers,
  ReducersMapObject,
} from "@reduxjs/toolkit";
import adminProfileReducer, {
  AdminProfileReduxState,
} from "./adminProfile/slice";
import authReducer, { AuthReduxState } from "./auth/slice";
import dashboardReducer, { DashboardReduxState } from "./dashboard/slice";
import usersReducer, { UsersReduxState } from "./users/slice";
import companiesReducer, { CompaniesReduxState } from "./companies/slice";
import tempPasswordsReducer, {
  TempPasswordsReduxState,
} from "./temp_passwords/slice";
import menuReducer, { MenuReduxState } from "./menu/slice";
import notificationsReducer, {
  NotificationsReduxState,
} from "./notifications/slice";
import roiReducer, { RoiReduxState } from "./roi/slice";
import settingsReducer, { SettingsReduxState } from "./settings/slice";
import treeReducer, { TreeReduxState } from "./tree/slice";
import videosReducer, { VideosReduxState } from "./videos/slice";
import improvementsReducer, {
  ImprovementsReduxState,
} from "./improvements/slice";
import { ExtendedState } from "../types";

type EmptyReduxState = {
  loading: boolean;
};

export type EmptyState = {
  empty: EmptyReduxState;
};

export type ReduxState = {
  menu: MenuReduxState;
  settings: SettingsReduxState;
  authUser: AuthReduxState;
  adminProfile: AdminProfileReduxState;
  notifications: NotificationsReduxState;
  videos: VideosReduxState;
  dashboard: DashboardReduxState;
  users: UsersReduxState;
  companies: CompaniesReduxState;
  tempPasswords: TempPasswordsReduxState;
  roi: RoiReduxState;
  tree: TreeReduxState;
  improvements: ImprovementsReduxState;
};

export const emptyReducer = combineReducers<EmptyState>({
  empty: (state: EmptyReduxState = { loading: true }) => ({ ...state }),
});

const mergeReducers = <S extends ReducersMapObject>(extendedStore: S) => {
  const reduxState: ReducersMapObject<ExtendedState<S>> = {
    menu: menuReducer,
    settings: settingsReducer,
    authUser: authReducer,
    adminProfile: adminProfileReducer,
    notifications: notificationsReducer,
    videos: videosReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    companies: companiesReducer,
    tempPasswords: tempPasswordsReducer,
    roi: roiReducer,
    tree: treeReducer,
    improvements: improvementsReducer,
    ...extendedStore,
  };
  const reducers = combineReducers<ExtendedState<S>>(reduxState);

  const rootReducer = (state: any, action: AnyAction): ExtendedState<S> => {
    // Clear all data in redux store to initial.
    if (action.type === "auth/logoutUser") {
      return reducers(undefined, action);
    }

    return reducers(state, action);
  };

  return rootReducer;
};

export default mergeReducers;
