// TODO: Prob a better place to put this
export interface IInitialCompanyMetaData {
  name: string;
  type: string;
  options: string[];
}

const initialCompanyMetaData: IInitialCompanyMetaData[] = [
  // {
  //   "name": "Job Type",
  //   "type": "select",
  //   "options": [
  //     "Lifting box",
  //     "Welding"
  //   ]
  // },
  {
    name: "Worker Type",
    type: "select",
    options: ["General", "Welder"],
  },
  {
    name: "Location",
    type: "select",
    options: ["Assembly line", "Loading bay"],
  },
];

// const non_consultancy_field = {
//   "name": "Department",
//   "type": "select",
//   "options": [
//     "Example Department 1",
//     "Example Department 2"
//   ]
// }
//
// const consultancy_field =  {
//   "name": "Client",
//   "type": "select",
//   "options": [
//     "Example Client 1",
//     "Example Client 2"
//   ]
// }

export { initialCompanyMetaData };
