import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getClientDeptCardsRequest,
  getClientDeptObjRequest,
  setMetadataViewPreference,
} from "@kernel-store/tree/thunk";

export type TreeReduxState = {
  clientDeptCardsData: any[];
  clientDeptCardsDataLoading: boolean;
  clientDeptObjData: any[];
  clientDeptObjJobs: any[];
  clientDeptObjDataLoading: boolean;
  filters: any;
  seeOnlyYourMetadata: boolean;
  refresh: boolean;
};

export const treeInitialState: TreeReduxState = {
  clientDeptCardsData: [],
  clientDeptCardsDataLoading: false,
  clientDeptObjData: [],
  clientDeptObjJobs: [],
  clientDeptObjDataLoading: false,
  filters: {},
  seeOnlyYourMetadata: false,
  refresh: false,
};

const treeSlice = createSlice({
  name: "tree",
  initialState: treeInitialState,
  reducers: {
    setClientCardVisible: (
      state,
      action: PayloadAction<{ clientDeptCardId: number; visible: boolean }>,
    ) => {
      const cardIndex = state.clientDeptCardsData.findIndex(
        (card) => card.id === action.payload.clientDeptCardId,
      );
      if (cardIndex > -1) {
        state.clientDeptCardsData[cardIndex] = {
          ...state.clientDeptCardsData[cardIndex],
          visible: action.payload.visible,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientDeptCardsRequest.pending, (state) => {
      state.clientDeptCardsDataLoading = true;
    });
    builder.addCase(getClientDeptCardsRequest.fulfilled, (state, action) => {
      state.clientDeptCardsData = action.payload.clientDeptCardsData.map(
        (a: any) => ({
          ...a,
          visible: true,
        }),
      );
      state.clientDeptCardsDataLoading = false;
    });
    builder.addCase(getClientDeptCardsRequest.rejected, (state) => {
      state.clientDeptCardsDataLoading = false;
    });
    builder.addCase(getClientDeptObjRequest.pending, (state) => {
      state.clientDeptObjDataLoading = true;
    });
    builder.addCase(getClientDeptObjRequest.fulfilled, (state, action) => {
      state.clientDeptObjData =
        action.payload.clientDeptObjData.ClientDeptObject;
      state.clientDeptObjJobs = action.payload.clientDeptObjJobs;
      state.clientDeptObjDataLoading = false;
    });
    builder.addCase(getClientDeptObjRequest.rejected, (state) => {
      state.clientDeptObjDataLoading = false;
    });
    builder.addCase(setMetadataViewPreference.pending, (state, action) => {
      state.seeOnlyYourMetadata = action.meta.arg.value;
    });
  },
});

export const { setClientCardVisible } = treeSlice.actions;

export default treeSlice.reducer;
