import { Colors, JointTypeEnum } from "./constants";

const commonConfigs: {
  pointBorderWidth: number;
  pointHoverRadius: number;
  pointHoverBorderWidth: number;
  pointRadius: number;
  fill: boolean;
  borderWidth: number;
} = {
  pointBorderWidth: 5,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 1,
  pointRadius: 0,
  fill: false,
  borderWidth: 2,
};

function isDashedHere(
  jointMetadata: { angleTypeUsed: any },
  chunk: number,
  index: number,
  jointType: string | number,
): boolean {
  if (!jointMetadata) return false;
  if (!jointMetadata.angleTypeUsed) return false;
  const ranges = jointMetadata.angleTypeUsed;
  const trueIndex = chunk * 600 + index;

  return Object.values(ranges[jointType]).some((range: any) => {
    if (trueIndex >= range[0] && trueIndex <= range[1]) {
      return true;
    }
    return false;
  });
}

function getColor(value: number, jointType: JointTypeEnum): number {
  switch (jointType as JointTypeEnum) {
    case JointTypeEnum.LeftElbow:
      if (value > 60 && value < 100) {
        return Colors.Green;
      }
      break;
    case JointTypeEnum.RightElbow:
      if (value > 60 && value < 100) {
        return Colors.Green;
      }
      break;
    case JointTypeEnum.Hip:
      if (value < 20) {
        return Colors.Green;
      }
      if (value < 60) {
        return Colors.Yellow;
      }
      break;
    case JointTypeEnum.LeftShoulder:
      if (value < 45) {
        return Colors.Green;
      }
      if (value < 90) {
        return Colors.Yellow;
      }
      break;
    case JointTypeEnum.RightShoulder:
      if (value < 45) {
        return Colors.Green;
      }
      if (value < 90) {
        return Colors.Yellow;
      }
      break;
    case JointTypeEnum.Neck:
      return Colors.Black;
    case JointTypeEnum.LeftWrist:
      return Colors.Black;
    case JointTypeEnum.RightWrist:
      return Colors.Black;
    case JointTypeEnum.LeftLeg:
    case JointTypeEnum.RightLeg:
      if (value < 57.5) {
        return Colors.Green;
      }
      if (value < 62.5) {
        return Colors.Yellow;
      }
      break;
    default:
      break;
  }
  return Colors.Red;
}

export const ChartConfig = {
  RedSolid: {
    label: "RedSolid",
    borderColor: "red",
    pointBorderColor: "red",
    pointBackgroundColor: "red",
    pointHoverBackgroundColor: "red",
    pointHoverBorderColor: "red",
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Red &&
      !isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  RedDashed: {
    label: "RedDashed",
    borderColor: "red",
    pointBorderColor: "red",
    pointBackgroundColor: "red",
    pointHoverBackgroundColor: "red",
    pointHoverBorderColor: "red",
    borderDash: [15, 5],
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Red &&
      isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  YellowSolid: {
    label: "YellowSolid",
    borderColor: "#e6e340",
    pointBorderColor: "#e6e340",
    pointBackgroundColor: "#e6e340",
    pointHoverBackgroundColor: "#e6e340",
    pointHoverBorderColor: "#e6e340",
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Yellow &&
      !isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  YellowDashed: {
    label: "YellowDashed",
    borderColor: "#e6e340",
    pointBorderColor: "#e6e340",
    pointBackgroundColor: "#e6e340",
    pointHoverBackgroundColor: "#e6e340",
    pointHoverBorderColor: "#e6e340",
    borderDash: [15, 5],
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Yellow &&
      isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  GreenSolid: {
    label: "GreenSolid",
    borderColor: "rgb(109, 208, 163)",
    pointBorderColor: "rgb(109, 208, 163)",
    pointBackgroundColor: "rgb(109, 208, 163)",
    pointHoverBackgroundColor: "rgb(109, 208, 163)",
    pointHoverBorderColor: "rgb(109, 208, 163)",
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Green &&
      !isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  GreenDashed: {
    label: "GreenDashed",
    borderColor: "rgb(109, 208, 163)",
    pointBorderColor: "rgb(109, 208, 163)",
    pointBackgroundColor: "rgb(109, 208, 163)",
    pointHoverBackgroundColor: "rgb(109, 208, 163)",
    pointHoverBorderColor: "rgb(109, 208, 163)",
    borderDash: [15, 5],
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Green &&
      isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  BlackSolid: {
    label: "BlackSolid",
    borderColor: "#645aff",
    pointBorderColor: "#645aff",
    pointBackgroundColor: "#645aff",
    pointHoverBackgroundColor: "#645aff",
    pointHoverBorderColor: "#645aff",
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Black &&
      !isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
  BlackDashed: {
    label: "BlackDashed",
    borderColor: "#645aff",
    pointBorderColor: "#645aff",
    pointBackgroundColor: "#645aff",
    pointHoverBackgroundColor: "#645aff",
    pointHoverBorderColor: "#645aff",
    borderDash: [15, 5],
    belongs: (
      value: any,
      jointMetadata: any,
      chunk: any,
      index: any,
      jointType: any,
    ) =>
      getColor(value, jointType) === Colors.Black &&
      isDashedHere(jointMetadata, chunk, index, jointType),
    ...commonConfigs,
  },
};
