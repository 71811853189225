import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

import {
  Rula,
  Reba,
  Niosh,
  HandStrain,
  LibertyMutual,
  Training,
} from "@tumeke/tumekejs";
import { Assessments, ProcessingStatus } from "@kernel-constants/maps";
import { getAssessmentIndexFromVideo } from "@kernel-helpers/filters/VideoFilters";
import {
  getVideoListRequest,
  getThumbnailRequest,
  getThumbnailsRequest,
  getJointDataLegacy,
  getJointData,
  getVideoJointUrls,
  getSingleVideoRequest,
  updateVideoName,
  updateAssessmentName,
  deleteVideosRequest,
  deleteVideoAssessment,
  deleteVideoClip,
  setPrimaryVideoAssessment,
  setVideoMetadata,
  getAllMetadataFields,
  generateReportRequest,
  generateCSVRequest,
  downloadVideoRequest,
  downloadRiskJointsCsvRequest,
  compareVideosRequest,
  saveVideoNotesRequest,
  getLinks,
  updateLink,
  deleteLink,
} from "@kernel-store/videos/thunk";

import exampleVideo from "./example.json";

export type TPageMetadata = {
  currentPage: number;
  search: string;
  pageSize: number;
};

export type VideosReduxState = {
  videoList: any;
  videosLoaded: boolean;
  videosLoading: boolean;
  refresh: boolean;
  optionsMetadataArray: any[];
  selectedMetadataObj: any;
  reportLoading: boolean;
  worksheetLoading: boolean;
  csvLoading: boolean;
  selectedPosture: number;
  selectedRepInPosture: number;
  personId: number;
  compareLoading: boolean;
  compareVideoIds: any[];
  assessmentIds: any[];
  compareVideoData: any;
  videoDownloading: boolean;
  riskJointsCsvDownloading: boolean;
  websocketFailed: boolean;
  videoCount: number;
  totalVideoDurationMins: number;
  videoLimitCount: number;
  recommendationLoading: boolean;
  pageMetadata: TPageMetadata;
  linksList: any[];
  linksLoading: boolean;
  assessmentsLimitModal: boolean;
  deleteAssessmentLoading: boolean;
  deleteVideoClipLoading: boolean;
  setPrimaryAssessmentLoading: boolean;
};

export const videosInitialState: VideosReduxState = {
  videoList: {
    example: exampleVideo,
  },
  videosLoaded: false,
  videosLoading: false,
  refresh: false,
  optionsMetadataArray: [],
  selectedMetadataObj: {},
  reportLoading: false,
  worksheetLoading: false,
  csvLoading: false,
  selectedPosture: 0,
  selectedRepInPosture: 0,
  personId: 0,
  compareLoading: false,
  compareVideoIds: [],
  assessmentIds: [],
  compareVideoData: {},
  videoDownloading: false,
  riskJointsCsvDownloading: false,
  websocketFailed: false,
  videoCount: 0,
  totalVideoDurationMins: 0,
  videoLimitCount: 0,
  pageMetadata: {
    currentPage: 1,
    search: "",
    pageSize: 8,
  },
  recommendationLoading: false,
  linksList: [],
  linksLoading: false,
  assessmentsLimitModal: false,
  deleteAssessmentLoading: false,
  deleteVideoClipLoading: false,
  setPrimaryAssessmentLoading: false,
};

const DOSAGE_STATS_TEMPLATE = {
  frequency: 0.0,
  duration: 0.0,
  lowContrib: 1.0,
  mediumContrib: 1.0,
  highContrib: 1.0,
  vhighContrib: 1.0,
};

const getAssessmentFromSingleVideo = (
  videoObj: any,
  assessmentId: string,
): any | undefined => {
  if (!("tasks" in videoObj)) return undefined;

  const assessmentObjList: any[] = [];
  videoObj.tasks.forEach((task: any) => {
    task.assessments.forEach((assessment: any) => {
      assessmentObjList.push(assessment);
    });
  });

  return assessmentObjList.filter(
    (x) => x.id === parseInt(assessmentId, 10),
  )[0];
};

const notesHaveChangedHelper = (oldVideo: any, newVideo: any): boolean => {
  if (
    !oldVideo ||
    !newVideo ||
    !Object.prototype.hasOwnProperty.call(oldVideo, "tasks") ||
    !Object.prototype.hasOwnProperty.call(newVideo, "tasks")
  ) {
    return false;
  }
  if (oldVideo.tasks.length === 0 || newVideo.tasks.length === 0) {
    return false;
  }
  for (let i = 0; i < oldVideo.tasks.length; i++) {
    for (let j = 0; j < oldVideo.tasks[i].assessments.length; j++) {
      const oldAssessmentObj = oldVideo.tasks[i].assessments[j];
      const newAssessmentObj = getAssessmentFromSingleVideo(
        newVideo,
        oldAssessmentObj.id,
      );
      if (oldAssessmentObj?.data?.notes !== newAssessmentObj?.data?.notes) {
        return true;
      }
    }
  }
  return false;
};

const videosSlice = createSlice({
  name: "videos",
  initialState: videosInitialState,
  reducers: {
    addNewSkinnyVideo: (state, action: PayloadAction<{ videoObj: any }>) => {
      const videoId = action.payload.videoObj.key;
      const videoObj = { ...action.payload.videoObj };

      /** *
       *  Initialize
       *
       *  - Generate locally stored versions of the assessment objects
       */
      // TODO(znoland): old copy commented out below this if statement. remove when done
      const { tasks } = videoObj;
      // This means there's already been a full vid doc pulled for this item
      // Don't then pull skinny doc after
      if (
        state.videoList[videoId] &&
        state.videoList[videoId].tasks &&
        state.videoList[videoId].tasks[0] &&
        state.videoList[videoId].tasks[0].assessments &&
        Object.prototype.hasOwnProperty.call(
          state.videoList[videoId].tasks[0].assessments[0],
          "posture_assessments",
        )
      ) {
        return;
      }
      // loop through tasks
      for (let iIask = 0; iIask < tasks.length; iIask += 1) {
        // loop through assessments
        for (
          let iAssessment = 0;
          iAssessment < tasks[iIask].assessments.length;
          iAssessment += 1
        ) {
          // const assessment = tasks[iIask].assessments[iAssessment];
          // Set video thumbnail
        }
      }
      if (videoId in state.videoList) {
        if (
          state.videoList[videoId].thumbnailLoc &&
          state.videoList[videoId].thumbnailLoc.startsWith("https://")
        ) {
          videoObj.thumbnailLoc = state.videoList[videoId].thumbnailLoc;
        }
      }

      state.videoList[videoId] = videoObj;
    },
    setVideosVisibility: (
      state,
      action: PayloadAction<{
        videoIds: string[];
        visible: boolean;
      }>,
    ) => {
      (action.payload.videoIds || []).forEach((key) => {
        if (state.videoList[key]) {
          state.videoList[key].visible = action.payload.visible;
        }
      });
    },
    setAllInvisible: (state) => {
      Object.keys(state.videoList).forEach((key) => {
        state.videoList[key].visible = false;
      });
    },
    setWebsocketFailed: (
      state,
      action: PayloadAction<{ websocketFailed: boolean }>,
    ) => {
      state.websocketFailed = action.payload.websocketFailed;
    },
    setPageMetadata: (state, action: PayloadAction<Partial<TPageMetadata>>) => {
      const newPayload = { ...action.payload };
      const pageMetadataKeys = Object.keys(
        newPayload,
      ) as (keyof TPageMetadata)[];
      pageMetadataKeys.forEach((key) => {
        if (newPayload[key] === undefined) {
          delete newPayload[key];
        }
      });

      state.pageMetadata = { ...state.pageMetadata, ...newPayload };
    },
    addNewVideo: (
      state,
      action: PayloadAction<{ videoObj: any; initializeAfter: boolean }>,
    ) => {
      const videoId = action.payload.videoObj.key;
      let { recommendationLoading } = state;
      let newVideoObj = cloneDeep(action.payload.videoObj);
      /** *
       *  Update only - if already initialized
       *
       *  - This means listener has already been initialized, and the user is interacting
       *    with the video in the GUI. In this case don't accept videos from snapshot listener
       */
      if (
        state.videoList[videoId] &&
        state.videoList[videoId].tasks?.length > 0 &&
        state.videoList[videoId].tasks[0].assessments?.length > 0 &&
        state.videoList[videoId].tasks[0].assessments[0].posture_assessments
          ?.length > 0 &&
        state.videoList[videoId].tasks[0].assessments[0].posture_assessments[0]
          ?.riskAssessment?.initialized
      ) {
        const additionsToObj: any = {};
        if (newVideoObj?.videoLoc) {
          additionsToObj.videoLoc = newVideoObj.videoLoc;
        }

        // TODO(znoland): update this!
        if (typeof newVideoObj?.processingStatus !== "undefined") {
          additionsToObj.processingStatus = newVideoObj.processingStatus;
        }
        if (typeof newVideoObj?.bucketName !== "undefined") {
          additionsToObj.bucketName = newVideoObj.bucketName;
        }
        if (typeof newVideoObj?.videoMetadata !== "undefined") {
          additionsToObj.videoMetadata = newVideoObj.videoMetadata;
        }

        if (typeof newVideoObj?.tasks !== "undefined") {
          additionsToObj.tasks = newVideoObj.tasks;
        }
        if (typeof newVideoObj?.metadata !== "undefined") {
          additionsToObj.metadata = newVideoObj.metadata;
        }
        additionsToObj.videoName = newVideoObj.videoName;
        additionsToObj.thumbnailLoc = newVideoObj.thumbnailLoc;

        const oldVideoObj = state.videoList[videoId];

        newVideoObj = { ...oldVideoObj, ...additionsToObj };
      }

      if (
        notesHaveChangedHelper(state.videoList[videoId], newVideoObj) &&
        state.recommendationLoading
      ) {
        recommendationLoading = false;
      }

      // TODO(znoland): complete updates to GET_VIDEO_SUCCESS

      // Loop through all tasks + all assessments + all postures

      //

      /** *
       *  Initialize
       *
       *  - Generate locally stored versions of the assessment objects
       */
      // TODO(znoland): old copy commented out below this if statement. remove when done
      const { tasks } = newVideoObj;
      if ((action.payload as any).initializeAfter && tasks.length > 0) {
        // loop through tasks
        for (let iTask = 0; iTask < tasks.length; iTask += 1) {
          // loop through assessments
          for (
            let iAssessment = 0;
            iAssessment < tasks[iTask].assessments.length;
            iAssessment += 1
          ) {
            const assessment = tasks[iTask].assessments[iAssessment];

            // Set video thumbnail
            // if (videoObj.thumbnailLoc == '/assets/img/placeholder.jpg' || !videoObj.thumbnailLoc) {
            //   videoObj.thumbnailLoc = assessment['data'].thumbnailLoc
            // }

            // Create the Assessment Level insights (+ Posture Contribution to risk score)
            const postures = assessment.posture_assessments;
            if (assessment.posture_assessments.length > 0) {
              const type = assessment.data.assessmentType;
              const numPostures = postures.length;
              assessment.data.otherDosageStatBucket = {};
              for (
                let i = 0;
                i < assessment.data.assessmentMetadata.numPeople;
                i += 1
              ) {
                assessment.data.otherDosageStatBucket[i] = cloneDeep(
                  DOSAGE_STATS_TEMPLATE,
                );
                if (!("vhighContrib" in postures[0].dosageStatistics)) {
                  delete assessment.data.otherDosageStatBucket[i].vhighContrib;
                }
              }
              let maxScore = -Number.MAX_VALUE;

              // Loop through postures
              for (let i = 0; i < numPostures; i += 1) {
                const posture = postures[i];
                if (!posture.riskAssessment?.initialized) {
                  const { assessmentType } = assessment.data;
                  let personInspectionData = posture.riskAssessment;
                  let score = 0;
                  if (assessmentType === Assessments.RULA) {
                    posture.riskAssessment = new Rula(personInspectionData);
                    score = posture.riskAssessment.assessmentResult.Score;
                  } else if (assessmentType === Assessments.REBA) {
                    posture.riskAssessment = new Reba(personInspectionData);
                    score = posture.riskAssessment.assessmentResult.Score;
                  } else if (assessmentType === Assessments.NIOSH) {
                    personInspectionData = {
                      ...personInspectionData,
                      ...assessment.data.assessmentMetadata,
                    };
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.li * 100,
                      ) / 100.0;
                    posture.riskAssessment = new Niosh(personInspectionData);
                  } else if (assessmentType === Assessments.HAND_STRAIN) {
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.OverallScore *
                          100,
                      ) / 100.0;
                    posture.riskAssessment = new HandStrain(
                      personInspectionData,
                    );
                  } else if (assessmentType === Assessments.LIBERTY_MUTUAL) {
                    personInspectionData = {
                      ...personInspectionData,
                      ...assessment.data.assessmentMetadata,
                    };
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.mal * 100,
                      ) / 100.0;
                    posture.riskAssessment = new LibertyMutual(
                      personInspectionData,
                    );
                  } else if (assessmentType === Assessments.TRAINING) {
                    score =
                      Math.round(
                        posture.riskAssessment.assessmentResult.OverallScore *
                          100,
                      ) / 100.0;
                    posture.riskAssessment = new Training(personInspectionData);
                  }
                  if (score > maxScore) {
                    maxScore = score;
                  }

                  let repArr = [];
                  repArr = posture.repetitions.map((rep: any, idx: number) => ({
                    candidate: idx === 0,
                    frame: rep,
                  }));
                  repArr.sort((a: any, b: any) => a.frame - b.frame);
                  const { person_id: personId } = posture;
                  if ("lowContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].lowContrib -= posture.dosageStatistics.lowContrib;
                  }
                  if ("mediumContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].mediumContrib -= posture.dosageStatistics.mediumContrib;
                  }
                  if ("highContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].highContrib -= posture.dosageStatistics.highContrib;
                  }
                  if ("vhighContrib" in postures[i].dosageStatistics) {
                    assessment.data.otherDosageStatBucket[
                      personId
                    ].vhighContrib -= posture.dosageStatistics.vhighContrib;
                  }

                  posture.repetitions = repArr;
                }
              }
              assessment.data.score = maxScore;
              assessment.data.numPeople =
                assessment.data.assessmentMetadata.numPeople;
              for (
                let i = 0;
                i < assessment.data.assessmentMetadata.numPeople;
                i += 1
              ) {
                const dosageStatistics =
                  assessment.data.otherDosageStatBucket[i];
                if (
                  "summaryStats" in assessment.data &&
                  assessment.data.summaryStats
                ) {
                  console.log(
                    "assessment['data'].summaryStats",
                    assessment.data.summaryStats,
                  );
                  const summaryStats = assessment.data.summaryStats[i];
                  if (summaryStats && "riskFrequency" in summaryStats) {
                    const { riskFrequency } = summaryStats;
                    let levels: string[] = [];
                    let contrib: string[] = [];

                    if (type === 1) {
                      levels = ["low", "medium", "high"];
                      contrib = ["lowContrib", "mediumContrib", "highContrib"];
                    }
                    if (type === 2) {
                      levels = ["low", "medium", "high", "very high"];
                      contrib = [
                        "lowContrib",
                        "mediumContrib",
                        "highContrib",
                        "vhighContrib",
                      ];
                    }
                    if (type === 3) {
                      levels = [];
                      contrib = [];
                    }
                    let c = 0;
                    let nums: number[] = [];
                    levels.forEach((l) => {
                      nums =
                        type === 1
                          ? Rula.getRiskScoresFromLevel(l)
                          : Reba.getRiskScoresFromLevel(l);
                      const flag = Object.entries(riskFrequency).find(
                        (pair: any[]) =>
                          nums.includes(parseInt(pair[0], 10)) && pair[1] > 0,
                      );
                      const entries: any = Object.entries(riskFrequency);
                      for (let p = 0; p < entries.length; p += 1) {
                        // const contained = nums.includes(entries[p][0]);
                      }

                      if (!flag) {
                        dosageStatistics[contrib[c]] = -1;
                      }
                      c += 1;
                    });
                  }
                }
              }
            }
          }
        }
      }
      state.videoList[videoId] = {
        ...newVideoObj,
        tasks,
      };
      state.recommendationLoading = recommendationLoading;
    },
    setPersonId: (state, action: PayloadAction<{ personId: number }>) => {
      state.personId = action.payload.personId;
    },
    setSelectedPosture: (
      state,
      action: PayloadAction<{ postureNum: number }>,
    ) => {
      state.selectedPosture = action.payload.postureNum;
    },
    /* Deprecated */
    setSelectedRep: (
      state,
      action: PayloadAction<{ videoId: string; repNum: number }>,
    ) => {
      const { videoId, repNum } = action.payload;
      const len =
        state.videoList[videoId].postures[state.selectedPosture].repetitions
          .length;

      state.selectedRepInPosture = Math.max(Math.min(repNum, len - 1), 0);
    },
    /*
     * updateRiskComponents
     * ------------------------
     * Dispatch meant to modify the individual components of a risk assessment.
     * ------------------------
     * Parameters:
     * 	- videoId: The ID of the video you're updating info for
     * 	- assessmentId: The ID of the Assessment (RULA/REBA/NIOSH) for the video
     *	- personId: The person whose info you're modifying
     *	- newInfo: A dictionary meant to codify the type of info your updating.
     *  	- newInfo["bodyGroup"]: The body part whose risk your modifying.
     *		- newInfo["type"]: The type of info your changing for that body part
     *		- newInfo["newValue"]: The new (numerical) value of that risk component.
     */
    updateRiskComponents: (
      state,
      action: PayloadAction<{
        videoId: string;
        assessmentId: number;
        personId: number;
        newInfo: any;
      }>,
    ) => {
      const { videoId, assessmentId, newInfo } = action.payload;
      const newInfoObj = cloneDeep(newInfo);
      const video = state.videoList[action.payload.videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        assessmentId,
      );
      if (
        taskIndex !== null &&
        assessmentIndex !== null &&
        video.tasks[taskIndex].assessments[assessmentIndex].posture_assessments
          ?.length
      ) {
        state.videoList[videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].posture_assessments[
          state.selectedPosture
        ]?.riskAssessment?.updateRiskComponents(
          newInfoObj.bodyGroup,
          newInfoObj.type,
          newInfoObj.newValue,
          newInfoObj.units,
        );
        state.refresh = !state.refresh;
      }
    },
    updateAdditionalInfo: (
      state,
      action: PayloadAction<{
        videoId: string;
        assessmentId: number;
        personId: number;
        newInfo: any;
      }>,
    ) => {
      const { videoId, assessmentId, newInfo } = action.payload;
      const newInfoObj = cloneDeep(newInfo);
      const video = state.videoList[videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        assessmentId,
      );

      if (
        taskIndex !== null &&
        assessmentIndex !== null &&
        video.tasks[taskIndex] &&
        video.tasks[taskIndex]?.assessments[assessmentIndex] &&
        video.tasks[taskIndex].assessments[assessmentIndex]?.posture_assessments
          ?.length
      ) {
        const assessment = video.tasks[taskIndex].assessments[assessmentIndex];
        const riskAssessment =
          assessment.posture_assessments[state.selectedPosture]?.riskAssessment;
        if (riskAssessment) {
          riskAssessment.updateAdditionalInfo(
            newInfoObj.type,
            newInfoObj.bodyGroup,
            newInfoObj.newValue,
            newInfoObj.units,
          );
          if (newInfoObj.type === "startEnd") {
            const newMetadata = cloneDeep(assessment.data.assessmentMetadata);
            if (assessment.data.assessmentType === Assessments.NIOSH) {
              newMetadata.nioshMetadata = {
                ...newMetadata.nioshMetadata,
                startEnd: newInfoObj.newValue,
              };
            } else if (
              assessment.data.assessmentType === Assessments.LIBERTY_MUTUAL
            ) {
              newMetadata.libertyMutualMetadata = {
                ...newMetadata.libertyMutualMetadata,
                startEnd: newInfoObj.newValue,
              };
            }
            state.videoList[videoId].tasks[taskIndex].assessments[
              assessmentIndex
            ].data.assessmentMetadata = newMetadata;
          }
          state.refresh = !state.refresh;
        }
      }
    },
    updateAssessmentMetadata: (
      state,
      action: PayloadAction<{
        videoId: string;
        assessmentId: number;
        personId: number;
        newInfo: any;
      }>,
    ) => {
      const { videoId, assessmentId, newInfo } = action.payload;
      const video = state.videoList[videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        assessmentId,
      );
      if (
        taskIndex !== null &&
        assessmentIndex !== null &&
        video.tasks[taskIndex].assessments[assessmentIndex].data
      ) {
        state.videoList[videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].data.assessmentMetadata = newInfo;
      }
    },
    updateAssessmentROIResults: (
      state,
      action: PayloadAction<{
        videoId: string;
        assessmentId: number;
        results: any;
      }>,
    ) => {
      const { videoId, assessmentId, results } = action.payload;
      const video = state.videoList[videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        assessmentId,
      );
      if (taskIndex !== null && assessmentIndex !== null) {
        state.videoList[videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].roi_results = results;
      }
    },
    setRecLoading: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.recommendationLoading = action.payload.loading;
    },
    toggleAssessmentsLimitModal: (state) => {
      state.assessmentsLimitModal = !state.assessmentsLimitModal;
    },
    generateReportSuccess: (state) => {
      state.reportLoading = false;
      state.worksheetLoading = false;
    },
    /* Deprecated */
    modifyVideoDoc: (state, action: PayloadAction<{ newData: any }>) => {
      // This means the object has already been initialized by INITIALIZE_INSPECTION_DATA
      // So it no longer needs to be updated locally
      const { key } = action.payload.newData;
      if (
        state.videoList[key]?.riskAssessment &&
        state.videoList[key].riskAssessment["0"]?.initialized
      ) {
        return;
      }

      const newObj = {
        ...action.payload.newData,
        thumbnailLoc: state.videoList[key].thumbnailLoc,
        visible: state.videoList[key].visible,
      };
      state.videoList[key] = newObj;
    },
    /* Deprecated */
    clearVideoList: (state) => {
      state.videoList = {};
    },
    /* deprecated */
    filterVideos: (state, action: PayloadAction<{ videoIds: string[] }>) => {
      const { videoIds } = action.payload;

      Object.keys(state.videoList).forEach((key) => {
        state.videoList[key].visible =
          videoIds.includes(key) ||
          (state.videoList[key].processingStatus !==
            ProcessingStatus.COMPLETED &&
            state.videoList[key].processingStatus !== ProcessingStatus.ERROR);
      });
      state.refresh = !state.refresh;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVideoListRequest.pending, (state) => {
      state.videosLoading = true;
      state.videosLoaded = false;
    });
    builder.addCase(getVideoListRequest.fulfilled, (state, action) => {
      state.videosLoading = false;
      state.videosLoaded = true;
      state.videoCount = action.payload.videoCount;
      state.videoLimitCount = action.payload.videoLimitCount;
      state.totalVideoDurationMins = action.payload.totalVideoDurationMins;
    });
    builder.addCase(getVideoListRequest.rejected, (state, action) => {
      if (action.error.message === "Loading error") {
        state.videosLoading = false;
        state.videosLoaded = true;
      }
    });
    builder.addCase(getThumbnailRequest.fulfilled, (state, action) => {
      if (!(action.payload.videoId in state.videoList)) {
        return;
      }
      if (
        action.payload.taskId === undefined ||
        action.payload.assessmentId === undefined ||
        action.payload.postureId === undefined
      ) {
        state.videoList[action.payload.videoId].thumbnailLoc =
          action.payload.realThumbnailLoc;
        return;
      }
      // const task = state.videoList[action.payload.videoId].tasks.filter(x => x.id == action.payload.taskId);
      // const assessment = task.assessments.filter(x => x.id == action.payload.assessmentId);
      const tasks = state.videoList[action.payload.videoId].tasks.map(
        (task: any) => {
          if (task.id !== action.payload.taskId) {
            return task;
          }
          // Assessment level
          return {
            ...task,
            assessments: task.assessments.map((assessment: any) => {
              if (assessment.id !== action.payload.assessmentId) {
                return assessment;
              }
              // Posture level
              return {
                ...assessment,
                posture_assessments: assessment.posture_assessments.map(
                  (posture: any) => {
                    if (posture.id !== action.payload.postureId) {
                      return posture;
                    }
                    return {
                      ...posture,
                      thumbnailLoc: action.payload.realThumbnailLoc,
                    };
                  },
                ),
              };
            }),
          };
        },
      );
      state.videoList[action.payload.videoId].tasks = tasks;
    });
    builder.addCase(getThumbnailsRequest.fulfilled, (state, action) => {
      action.payload.forEach((videoThumbnail) => {
        if (state.videoList[videoThumbnail.videoId]) {
          state.videoList[videoThumbnail.videoId].thumbnailLoc =
            videoThumbnail.url;
        }
      });
    });
    builder.addCase(getJointDataLegacy.fulfilled, (state, action) => {
      const { videoId, data, chunk } = action.payload;
      state.videoList[videoId].videoJsons = data;
      state.videoList[videoId].chunk = chunk;
      state.refresh = !state.refresh;
    });
    builder.addCase(getJointData.fulfilled, (state, action) => {
      const { videoId, data, chunk } = action.payload;
      state.videoList[videoId].videoJsons = data;
      state.videoList[videoId].chunk = chunk;
      state.refresh = !state.refresh;
    });
    builder.addCase(getVideoJointUrls.fulfilled, (state, action) => {
      const { videoId, jointUrls } = action.payload;
      state.videoList[videoId].jointUrls = jointUrls;
    });
    builder.addCase(getSingleVideoRequest.rejected, (state, action) => {
      const { videoId } = action.meta.arg;
      state.videoList[videoId].errorMsg = action.error.message;
    });
    builder.addCase(updateVideoName.fulfilled, (state, action) => {
      state.videoList[action.payload.videoId].videoName = action.payload.name;
    });
    builder.addCase(updateAssessmentName.fulfilled, (state, action) => {
      const video = state.videoList[action.payload.videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        action.payload.assessmentId,
      );
      if (taskIndex !== null && assessmentIndex !== null) {
        state.videoList[action.payload.videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].data.assessmentName = action.payload.name;
      }
    });
    builder.addCase(deleteVideosRequest.fulfilled, (state, action) => {
      action.payload.videoIds.forEach((videoId) => {
        delete state.videoList[videoId];
      });
    });
    /* Deprecated */
    builder.addCase(setVideoMetadata.fulfilled, (state, action) => {
      const { videoId, metadataObj } = action.payload;
      if (!state.videoList[videoId]) {
        return;
      }
      metadataObj.sort(
        (a: { option_id: number }, b: { option_id: number }) =>
          a.option_id - b.option_id,
      );
      state.videoList[videoId].metadata = metadataObj;
    });
    builder.addCase(getAllMetadataFields.fulfilled, (state, action) => {
      state.optionsMetadataArray = action.payload.data.options;
      state.selectedMetadataObj = action.payload.data.selected;
    });
    builder.addCase(generateReportRequest.pending, (state, action) => {
      const { reportType } = action.meta.arg;
      if (!reportType) {
        return;
      }
      if (reportType === "worksheet") {
        state.worksheetLoading = true;
        return;
      }
      state.reportLoading = true;
    });
    builder.addCase(generateReportRequest.rejected, (state, action) => {
      const { reportType } = action.meta.arg;
      if (!reportType) {
        return;
      }
      if (reportType === "worksheet") {
        state.worksheetLoading = false;
        return;
      }
      state.reportLoading = false;
    });
    builder.addCase(generateCSVRequest.pending, (state) => {
      state.csvLoading = true;
    });
    builder.addCase(generateCSVRequest.fulfilled, (state) => {
      state.csvLoading = false;
    });
    builder.addCase(generateCSVRequest.rejected, (state) => {
      state.csvLoading = false;
    });
    builder.addCase(downloadVideoRequest.pending, (state) => {
      state.videoDownloading = true;
    });
    builder.addCase(downloadVideoRequest.fulfilled, (state) => {
      state.videoDownloading = false;
    });
    builder.addCase(downloadVideoRequest.rejected, (state) => {
      state.videoDownloading = false;
    });
    builder.addCase(downloadRiskJointsCsvRequest.pending, (state) => {
      state.riskJointsCsvDownloading = true;
    });
    builder.addCase(downloadRiskJointsCsvRequest.fulfilled, (state) => {
      state.riskJointsCsvDownloading = false;
    });
    builder.addCase(downloadRiskJointsCsvRequest.rejected, (state) => {
      state.riskJointsCsvDownloading = false;
    });
    builder.addCase(compareVideosRequest.pending, (state) => {
      state.compareLoading = true;
    });
    builder.addCase(compareVideosRequest.fulfilled, (state, action) => {
      state.compareLoading = false;
      state.compareVideoIds = action.payload.videoIds;
      state.assessmentIds = action.payload.assessmentIds;
      state.compareVideoData = action.payload.orderedData;
    });
    builder.addCase(compareVideosRequest.rejected, (state) => {
      state.compareLoading = false;
    });
    builder.addCase(saveVideoNotesRequest.fulfilled, (state, action) => {
      const video = state.videoList[action.payload.videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        action.payload.assessmentId,
      );
      if (
        taskIndex !== null &&
        assessmentIndex !== null &&
        state.videoList[action.payload.videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].data
      ) {
        state.videoList[action.payload.videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].data[action.payload.notesKey] = action.payload.notes;
      }
      state.videoList[action.payload.videoId][action.payload.notesKey] =
        action.payload.notes;
    });
    builder.addCase(getLinks.pending, (state) => {
      state.linksLoading = true;
    });
    builder.addCase(getLinks.fulfilled, (state, action) => {
      state.linksLoading = false;
      state.linksList = action.payload.linksList;
    });
    builder.addCase(getLinks.rejected, (state) => {
      state.linksLoading = false;
    });
    builder.addCase(updateLink.pending, (state) => {
      state.linksLoading = true;
    });
    builder.addCase(updateLink.fulfilled, (state, action) => {
      state.linksLoading = false;
      state.linksList = state.linksList.map((link) => {
        if (link.id === action.payload.linkId) {
          return action.payload.link;
        }
        return link;
      });
    });
    builder.addCase(updateLink.rejected, (state) => {
      state.linksLoading = false;
    });
    builder.addCase(deleteLink.pending, (state) => {
      state.linksLoading = true;
    });
    builder.addCase(deleteLink.fulfilled, (state, action) => {
      const { linkId } = action.payload;
      state.linksLoading = false;
      const linkIndex = state.linksList.findIndex((link) => link.id === linkId);
      if (linkIndex > -1) {
        state.linksList.splice(linkIndex, 1);
      }
    });
    builder.addCase(deleteLink.rejected, (state) => {
      state.linksLoading = false;
    });
    builder.addCase(deleteVideoAssessment.pending, (state) => {
      state.deleteAssessmentLoading = true;
    });
    builder.addCase(deleteVideoAssessment.fulfilled, (state, action) => {
      const { videoId, assessmentId } = action.payload;
      const video = state.videoList[videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        assessmentId,
      );
      if (taskIndex !== null && assessmentIndex !== null) {
        state.videoList[videoId].tasks[taskIndex].assessments.splice(
          assessmentIndex,
          1,
        );
      }
      state.deleteAssessmentLoading = false;
    });
    builder.addCase(deleteVideoAssessment.rejected, (state) => {
      state.deleteAssessmentLoading = false;
    });
    builder.addCase(deleteVideoClip.pending, (state) => {
      state.deleteVideoClipLoading = true;
    });
    builder.addCase(deleteVideoClip.fulfilled, (state, action) => {
      const { videoId, clipId } = action.payload;
      const taskIndex = state.videoList[videoId].tasks.findIndex(
        (task: any) => task.clip.id !== clipId,
      );
      if (taskIndex > -1) {
        state.videoList[videoId].tasks[taskIndex].assessments.splice(
          taskIndex,
          1,
        );
      }
      state.deleteVideoClipLoading = false;
    });
    builder.addCase(deleteVideoClip.rejected, (state) => {
      state.deleteVideoClipLoading = false;
    });
    builder.addCase(setPrimaryVideoAssessment.pending, (state) => {
      state.setPrimaryAssessmentLoading = true;
    });
    builder.addCase(setPrimaryVideoAssessment.fulfilled, (state, action) => {
      const { videoId, assessmentId } = action.payload;
      const video = state.videoList[videoId];
      const { taskIndex, assessmentIndex } = getAssessmentIndexFromVideo(
        video,
        assessmentId,
      );
      if (taskIndex !== null && assessmentIndex !== null) {
        state.videoList[videoId].tasks.forEach((task: any, index1: number) => {
          task.assessments.forEach((_: any, index2: number) => {
            state.videoList[videoId].tasks[index1].assessments[
              index2
            ].is_primary = false;
          });
        });
        state.videoList[videoId].tasks[taskIndex].assessments[
          assessmentIndex
        ].is_primary = true;
      }
      state.setPrimaryAssessmentLoading = false;
    });
    builder.addCase(setPrimaryVideoAssessment.rejected, (state) => {
      state.setPrimaryAssessmentLoading = false;
    });
  },
});

export const {
  addNewSkinnyVideo,
  addNewVideo,
  setVideosVisibility,
  setAllInvisible,
  setWebsocketFailed,
  setPageMetadata,
  setPersonId,
  setSelectedPosture,
  setSelectedRep,
  updateRiskComponents,
  updateAdditionalInfo,
  updateAssessmentMetadata,
  updateAssessmentROIResults,
  setRecLoading,
  toggleAssessmentsLimitModal,
  generateReportSuccess,
  modifyVideoDoc,
  clearVideoList,
  filterVideos,
} = videosSlice.actions;

export default videosSlice.reducer;
