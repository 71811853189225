import {
  EThree as EThreeBase,
  Data,
  NodeBuffer,
} from "@virgilsecurity/e3kit-browser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as db from "../utils/Database";
import { cognitoAuthUser, asyncStoreIdToken } from "./Cognito";

let EThree: typeof EThreeBase | null = null;
let PrivateKeyAlreadyExistsError: any | null = null;
let eThree: EThreeBase | null = null;
let groupChat: any | null = null;
let source: string = "web";

export function InitializeVirgilLibrary(
  EThree_remote: typeof EThreeBase,
): void {
  EThree = EThree_remote;
}

export function InitializeMobileRequirements(
  PrivateKeyAlreadyExistsError_remote: any,
): void {
  PrivateKeyAlreadyExistsError = PrivateKeyAlreadyExistsError_remote;
  source = "mobile";
}

// Assumes that device already authenticated w cognito
export async function initializeVirgil(
  virgilId: string,
  initializeFunction: undefined | (() => Promise<string>) = undefined,
): Promise<void> {
  const getToken = async (): Promise<any> => db.getVirgilJwt(virgilId);

  const initFunction =
    initializeFunction === undefined
      ? () => getToken().then((result) => result.virgil_token)
      : initializeFunction;

  try {
    if (source === "mobile") {
      // @ts-ignore
      eThree = await EThree!.initialize(initFunction, { AsyncStorage });
    } else {
      eThree = await EThree!.initialize(initFunction);
    }
    console.log("Successfully initialized virgil");
  } catch (err) {
    console.log(`Virgil error: ${err}`);
  }
}

export function isVirgilInitialized(): boolean {
  return eThree !== null && groupChat !== null;
}

export async function logoutVirgil(): Promise<void> {
  if (!eThree) return;
  try {
    // TODO Comment this out
    // await eThree.cleanup();
  } catch (err) {
    console.log(`Error cleaning up ${err}`);
  }
  eThree = null;
}

export async function createNewUserVirgil(password: string): Promise<void> {
  if (await eThree!.hasLocalPrivateKey()) {
    await eThree!.cleanup();
  }
  try {
    await eThree!.register();
    await eThree!.backupPrivateKey(password);
    console.log("Done");
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}

export async function createVirgilGroupWithSelf(
  uid: string,
  groupId: Data,
): Promise<void> {
  console.log(`Creating group with self: ${uid}`);
  const participants = await eThree!.findUsers([uid]);
  console.log("Got user card");
  groupChat = await eThree!.createGroup(groupId, participants);
  console.log("Group done");
}

export async function encryptMessage(
  message: Data,
): Promise<NodeBuffer | string> {
  // eslint-disable-next-line @typescript-eslint/return-await
  return await groupChat.encrypt(message);
}

export async function decryptMessage(
  message: Data,
  messageCreatorUID: string,
): Promise<NodeBuffer | string> {
  const messageSender = await eThree!.findUsers(messageCreatorUID);
  // eslint-disable-next-line @typescript-eslint/return-await
  return await groupChat.decrypt(message, messageSender);
}

async function addUserToGroup(uid: string): Promise<void> {
  console.log(`finding users: ${uid}`);
  const newParticipant = await eThree!.findUsers(uid);
  console.log(`adding to groupchat: ${JSON.stringify(newParticipant)}`);
  try {
    await groupChat.add(newParticipant);
  } catch (e) {
    console.log(e);
    // this is the error for when user is already in group.
    // if they are not, then throw error
    if (!String(e).startsWith("KeyknoxClientError")) {
      throw new Error("Add user to group error");
    }
  }
}

export async function acceptUserIntoCompanyVirgilHelper(
  uid: string,
): Promise<void> {
  await addUserToGroup(uid);
}

export async function joinGroup(userObj: any, companyObj: any): Promise<void> {
  if (userObj.role === "requesting") return;
  const { company_admin_virgil_id: companyAdminVirgilId } = companyObj;
  const card = await eThree!.findUsers(companyAdminVirgilId);
  groupChat = await eThree!.loadGroup(companyObj.virgil_id, card);
}

export async function decryptAESKeys(
  userObj: any,
  companyObj: any,
): Promise<any> {
  const { company_admin_virgil_id: companyAdminVirgilId, aes_key: aesKey } =
    companyObj;
  const decryptedObj: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(aesKey)) {
    // eslint-disable-next-line no-await-in-loop
    decryptedObj[key] = await decryptMessage(aesKey[key], companyAdminVirgilId);
  }
  return decryptedObj;
}

export async function changePassword(
  oldPassword: string,
  newPassword: string,
): Promise<void> {
  await eThree!.changePassword(oldPassword, newPassword);
}

// TODO: eThree!.keyEntryStorage.storage doesn't exist
// eThree!.keyLoader is private
async function hasLocalPrivateKeyWrapper(): Promise<boolean> {
  try {
    return await eThree!.hasLocalPrivateKey();
  } catch {
    await (eThree!.keyEntryStorage as any).storage.clear();
    (eThree! as any).keyLoader.cachedPrivateKey = null;
    return await eThree!.hasLocalPrivateKey();
  }
}

// TODO: eThree!.keyEntryStorage.storage doesn't exist
// eThree!.keyLoader is private
export async function restorePrivateKey(password: string): Promise<void> {
  // inside try catch for Virgil bug in iOS
  const hasLocalKey = await hasLocalPrivateKeyWrapper();
  if (hasLocalKey) return;
  try {
    await eThree!.restorePrivateKey(password);
  } catch (e) {
    if (PrivateKeyAlreadyExistsError === null) {
      throw e;
    }
    if (e instanceof PrivateKeyAlreadyExistsError) {
      await eThree!.cleanup();
    } else {
      await (eThree!.keyEntryStorage as any).storage.clear();
      (eThree! as any).keyLoader.cachedPrivateKey = null;
    }
    await eThree!.restorePrivateKey(password);
  }
}

export async function cleanupVirgil(): Promise<void> {
  if (eThree) {
    await eThree!.cleanup();
  }
}

export async function canResetPassword(virgilId: string): Promise<boolean> {
  try {
    await initializeVirgil(virgilId);
    console.log("Successfully initialized virgil");
  } catch (err) {
    console.log(`Virgil error: ${err}`);
    return false;
  }
  try {
    await restorePrivateKey("");
  } catch (e) {
    console.log(`Virgil error: ${e}`);
    return false;
  }
  return true;
}

export async function resetPassword(newPassword: string): Promise<void> {
  // Expects `canResetFunction` to already have been called
  // Just checking here to make sure.
  if (!eThree) {
    // TODO: missed parameters for canResetPassword
    // const ret = await canResetPassword();
    // if (!ret) {
    throw new Error("Cannot reset password");
    // }
  }

  await eThree!.resetPrivateKeyBackup();
  await eThree!.backupPrivateKey(newPassword);
}

export async function initiateResetPasswordFlow(
  email: string,
): Promise<string> {
  try {
    const encodedEmail = encodeURIComponent(email);
    const userObj = await db.getUserVirgilId(encodedEmail);
    const { virgilId } = userObj;

    const ableToReset = await canResetPassword(virgilId);
    if (!ableToReset) {
      const ret = await db.resetPasswordHelper(encodedEmail);
      if (ret.code === "VIRGIL_OFF") {
        await db.initiateResetUserPassword(encodedEmail);
      }
      return ret.code;
    }
    await db.initiateResetUserPassword(encodedEmail);
  } catch (err: any) {
    console.log("initiateResetPasswordFlow:", err?.message);
    throw err;
  }
  return "INITIATED_RESET";
}

export async function initiateResetPasswordCognito(
  email: string,
): Promise<void> {
  const encodedEmail = encodeURIComponent(email);
  await db.initiateResetUserPassword(encodedEmail);
}

const cognitologinWithEmailPasswordAsync = async (
  email: string,
  password: string,
) =>
  cognitoAuthUser(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

export async function confirmPasswordReset(
  email: string,
  confirmCode: string,
  newPassword: string,
  resetAccount: boolean = false,
): Promise<void> {
  const encodedEmail = encodeURIComponent(email);
  const ret = await db.confirmResetUserPassword(
    encodedEmail,
    confirmCode,
    newPassword,
    resetAccount,
  );
  if (resetAccount) {
    // Set state to requesting and reset virgil account
    // Somehow need to log back into cognito before all that
    // can be done however
    const cognitoUser = await cognitologinWithEmailPasswordAsync(
      email,
      newPassword,
    );
    const idToken = cognitoUser.getIdToken().getJwtToken();
    await asyncStoreIdToken(idToken);
    await initializeVirgil(ret.virgilId);
    await createNewUserVirgil(newPassword);
  } else if (!ret?.virgilOff) {
    // If not resetting account then change password on Virgil
    await resetPassword(newPassword);
  }
}

export const authConfig = { eThree };
