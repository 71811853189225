import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

import {
  addEmptySolution,
  deleteSolution,
  addEmptyInjury,
  deleteInjury,
  saveIntro,
  saveInjury,
  saveSolution,
} from "@kernel-store/roi/thunk";

import { INJURY_TYPES, INJURY_COSTS } from "./constants";
import { getRandomString } from "../../helpers/generators";

export type TRoiSolution = {
  name: string;
  equipmentCost: number | null;
  recurringCost: number | null;
  engineeringCost: number | null;
  trainingCost: number | null;
  effectiveness: string | null;
  productivity: string | null;
  id: string | number | null;
  totalCost: number;
};

export const EMPTY_SOLUTION: TRoiSolution = {
  name: "",
  equipmentCost: null,
  recurringCost: null,
  engineeringCost: null,
  trainingCost: null,
  effectiveness: null,
  productivity: null,
  id: null,
  totalCost: 0,
};

export type TRoiInjury = {
  type: number | null;
  numOccurrences: number | null;
  directCost: string | null;
  indirectCost: string | null;
  id: string | number | null;
  totalCost: number | null;
};

export const EMPTY_INJURY: TRoiInjury = {
  type: null,
  numOccurrences: null,
  directCost: null,
  indirectCost: null,
  id: 0,
  totalCost: null,
};

export type TRoiResults = {
  reductionInjuryDirectCost: number;
  reductionInjuryIndirectCost: number;
  reductionInjuryPercent: number;
  reductionInjuryCost: number;
  increaseProductivityPercent: number;
  increaseProductivityCost: number;
  annualSavings: number;
  savingsTimeRange: number;
  lifetimeSavings: number;
  lifetimeROI: number;
  annualROI: number;
  injuryTotalCost: number;
  additionalSalesNeeded: number;
  id: string | number | null;
};

export const EMPTY_RESULTS: TRoiResults = {
  reductionInjuryDirectCost: 0.0,
  reductionInjuryIndirectCost: 0.0,
  reductionInjuryPercent: 0.0,
  reductionInjuryCost: 0.0,
  increaseProductivityPercent: 0.0,
  increaseProductivityCost: 0.0,
  annualSavings: 0.0,
  savingsTimeRange: 3,
  lifetimeSavings: 0.0,
  lifetimeROI: 0.0,
  annualROI: 0.0,
  injuryTotalCost: 0.0,
  additionalSalesNeeded: 0.0,
  id: null,
};

export type TRoiIntro = {
  jobName: string;
  selfInsured: boolean;
  numPeople: number | null;
  hourlyWage: string | null;
  profitMargin: number;
};

export const EMPTY_INTRO: TRoiIntro = {
  jobName: "",
  selfInsured: false,
  numPeople: null,
  hourlyWage: null,
  profitMargin: 3,
};

export type RoiReduxState = {
  solutions: TRoiSolution[];
  injuries: TRoiInjury[];
  results: TRoiResults[];
  intro: TRoiIntro;
  isValid: boolean;
};

export const EMPTY_STATE: RoiReduxState = {
  solutions: [cloneDeep(EMPTY_SOLUTION)],
  injuries: [cloneDeep(EMPTY_INJURY)],
  results: [cloneDeep(EMPTY_RESULTS)],
  intro: EMPTY_INTRO,
  isValid: true,
};

export const roiInitialState: RoiReduxState = EMPTY_STATE;

// Checks if form inputs are invalid
// This logic is repeated in the views
// themselves to display specific warnings
// to each input
function isInvalid(state: RoiReduxState): boolean {
  return (
    (state.intro.numPeople !== null &&
      (state.intro.numPeople <= 0 ||
        !Number.isInteger(Number(state.intro.numPeople)))) ||
    (state.intro.hourlyWage !== null && Number(state.intro.hourlyWage) <= 0)
  );
}

function getReductionInjuryPercentHelper(effectiveness: string | null): number {
  switch (effectiveness) {
    case "0":
      return 0.0;
    case "1":
      return 0.1;
    case "2":
      return 0.15;
    case "3":
      return 0.4;
    case "4":
      return 0.7;
    default:
      return 0.0;
  }
}

function getIncreaseProductivityPercent(productivity: string | null): number {
  switch (productivity) {
    case "0":
      return 0;
    case "1":
      return 0.025;
    case "2":
      return 0.05;
    case "3":
      return 0.15;
    default:
      return 0.0;
  }
}

/*
function round(num: number, digits: number = 2): number {
  return Math.floor(num * 10**digits) / 10**digits;
}
*/

function currency(val: string | number | null) {
  if (val === null) {
    return 0;
  }
  return Number(String(val).replace(/[$,]+/g, ""));
}

function computeROIResults(
  intro: TRoiIntro,
  injuries: TRoiInjury[],
  solution: TRoiSolution,
): TRoiResults {
  const results = cloneDeep(EMPTY_RESULTS);
  results.id = getRandomString(10);
  results.reductionInjuryPercent = getReductionInjuryPercentHelper(
    solution.effectiveness,
  );
  results.increaseProductivityPercent = getIncreaseProductivityPercent(
    solution.productivity,
  );
  results.injuryTotalCost = 0;
  injuries.forEach((injury) => {
    results.injuryTotalCost +=
      Number(injury.numOccurrences) *
      (currency(injury.indirectCost) + currency(injury.directCost));
  });
  results.additionalSalesNeeded =
    results.injuryTotalCost / (intro.profitMargin / 100.0);

  results.reductionInjuryDirectCost = 0;
  results.reductionInjuryIndirectCost = 0;
  injuries.forEach((injury) => {
    results.reductionInjuryDirectCost +=
      results.reductionInjuryPercent *
      Number(injury.numOccurrences) *
      currency(injury.directCost);

    results.reductionInjuryIndirectCost =
      results.reductionInjuryPercent *
      Number(injury.numOccurrences) *
      currency(injury.indirectCost);
  });
  console.log("[ROI] indirect cost", results.reductionInjuryIndirectCost);

  results.reductionInjuryCost =
    results.reductionInjuryDirectCost + results.reductionInjuryIndirectCost;

  results.increaseProductivityCost =
    results.increaseProductivityPercent *
    Number(intro.numPeople) *
    currency(intro.hourlyWage) *
    2087;

  results.annualSavings =
    results.reductionInjuryCost + results.increaseProductivityCost;

  let lifetimeSavings = 0;
  for (let i = 0; i < results.savingsTimeRange; i += 1) {
    lifetimeSavings += results.annualSavings / (1 + 0.055) ** (i + 1);
  }
  // eslint-disable-next-line no-param-reassign
  solution.totalCost =
    currency(solution.equipmentCost) +
    currency(solution.recurringCost) * results.savingsTimeRange +
    currency(solution.engineeringCost) +
    currency(solution.trainingCost);

  results.lifetimeSavings = lifetimeSavings;
  results.lifetimeROI = lifetimeSavings / solution.totalCost;
  results.annualROI = results.lifetimeROI / results.savingsTimeRange;
  console.log("results", results);
  return results;
}

const getIndirectCostHelper = (directCostString: string | number): string => {
  const directCost = Number(directCostString);
  if (directCost < 3000) {
    return String(directCost * 4.5);
  }
  if (directCost < 5000) {
    return String(directCost * 1.6);
  }
  if (directCost < 10000) {
    return String(directCost * 1.2);
  }
  return String(directCost * 1.1);
};

const roiSlice = createSlice({
  name: "roi",
  initialState: roiInitialState,
  reducers: {
    computeROI: (state) => {
      state.results = state.solutions.map((sol) =>
        computeROIResults(state.intro, state.injuries, sol),
      );
    },
    clearCalculator: (state) => {
      const emptyStateKeys = Object.keys(
        EMPTY_STATE,
      ) as (keyof RoiReduxState)[];
      emptyStateKeys.forEach((key) => {
        Object.assign(state, { [key]: EMPTY_STATE[key] });
      });
    },
    restoreROIResults: (
      state,
      action: PayloadAction<{
        solutions: TRoiSolution[];
        injuries: TRoiInjury[];
        results: TRoiResults[];
        intro: TRoiIntro;
      }>,
    ) => {
      state.solutions = action.payload.solutions;
      state.injuries = action.payload.injuries;
      state.results = action.payload.results;
      state.intro = action.payload.intro;
      state.isValid = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addEmptySolution.pending, (state) => {
      state.solutions.push({
        ...cloneDeep(EMPTY_SOLUTION),
        id: getRandomString(10),
      });
    });
    builder.addCase(deleteSolution.pending, (state, action) => {
      const solutionIndex = state.solutions.findIndex(
        (solution) => solution.id === action.meta.arg.id,
      );
      if (solutionIndex > -1) {
        state.solutions.splice(solutionIndex, 1);
      }
    });
    builder.addCase(addEmptyInjury.pending, (state) => {
      state.injuries.push({
        ...cloneDeep(EMPTY_INJURY),
        id: getRandomString(10),
      });
    });
    builder.addCase(deleteInjury.pending, (state, action) => {
      const injuryIndex = state.injuries.findIndex(
        (injury) => injury.id === action.meta.arg.id,
      );
      if (injuryIndex > -1) {
        state.injuries.splice(injuryIndex, 1);
      }
    });
    builder.addCase(saveIntro.pending, (state, action) => {
      const { key, value } = action.meta.arg;
      Object.assign(state.intro, { [key]: value });
      state.isValid = !isInvalid(state);
    });
    builder.addCase(saveInjury.pending, (state, action) => {
      const { id, key, value } = action.meta.arg;
      const injuryIndex = state.injuries.findIndex(
        (injury) => injury.id === id,
      );
      if (injuryIndex > -1) {
        if (key === "type") {
          const f = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          });
          const name = INJURY_TYPES[value as number];
          const directCost = INJURY_COSTS[name];
          const indirectCost = getIndirectCostHelper(directCost);
          state.injuries[injuryIndex] = {
            ...state.injuries[injuryIndex],
            type: value as number,
            directCost: f.format(Number(directCost)).substring(1),
            indirectCost: f.format(Number(indirectCost)).substring(1),
          };
        } else {
          Object.assign(state.injuries[injuryIndex], { [key]: value });
        }
      }
    });
    builder.addCase(saveSolution.pending, (state, action) => {
      const { id, key, value } = action.meta.arg;
      const solutionIndex = state.solutions.findIndex(
        (solution) => solution.id === id,
      );
      if (solutionIndex > -1) {
        Object.assign(state.solutions[solutionIndex], { [key]: value });
      }
    });
  },
});

export const { computeROI, clearCalculator, restoreROIResults } =
  roiSlice.actions;

export default roiSlice.reducer;
