// Assumes arr looks like: [a, b, c, d]
export const strToArr = (str: string): number[] => {
  // eslint-disable-next-line no-param-reassign
  str = str
    .trim()
    .slice(1, str.length - 1)
    .trim(); // discard brackets
  const arr = str.split(", ");
  const ret = arr.map((item) => parseFloat(item));
  return ret;
};

export const interp = (
  val: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
): number => {
  /*
      Assumes val is in range [x1, x2]. x1 maps to y1 and
      x2 maps to y2. Computes what val would map to if
      we linearly interpolated between y1 and y2
  */
  if (val < x1 || val > x2) {
    throw new Error("val not in [x1, x2] range");
  }
  return ((val - x1) / (x2 - x1)) * (y2 - y1) + y1;
};

export const computeRangesHelper = (map: any, score: number): string | null => {
  let key: string | null = null;
  Object.keys(map).some((rangeKey: any) => {
    if (rangeKey === "Base" || rangeKey === "Multiplier") {
      return false;
    }
    const array = strToArr(rangeKey);

    if (Number.isNaN(array[0]) && score <= array[1]) {
      key = rangeKey;
      return true;
    }
    if (Number.isNaN(array[1]) && score >= array[0]) {
      key = rangeKey;
      return true;
    }

    if (score >= array[0] && score < array[1]) {
      key = rangeKey;
      return true;
    }
    return false;
  });
  return key;
};
