// This is meant to be just an enum-type, add any extra metadata to other map

export type TAssessments = {
  [key: number | string]: number | string;
};

export type TSolutionType = {
  id: string;
  name: string;
  icon: string;
};

export const Assessments: TAssessments = {
  1: "RULA",
  2: "REBA",
  3: "NIOSH",
  4: "HAND_STRAIN",
  5: "SNOOK",
  6: "TRAINING",
  RULA: 1,
  REBA: 2,
  NIOSH: 3,
  HAND_STRAIN: 4,
  LIBERTY_MUTUAL: 5,
  SNOOK: 5,
  TRAINING: 6,
};

export const AssessmentShortStrings: TAssessments = {
  1: "RULA",
  2: "REBA",
  3: "NIOSH",
  4: "HAND",
  5: "SNOOK",
  6: "TRAIN",
};

export const AssessmentStrings: TAssessments = {
  1: "RULA",
  2: "REBA",
  3: "NIOSH",
  4: "HANDSTRAIN",
  5: "SNOOK",
  6: "TRAIN",
};

export type TAssessmentsColor = {
  [key: number]: {
    name: string;
    color: string;
  };
};
export const AssessmentsColor: TAssessmentsColor = {
  1: {
    name: "RULA",
    color: "#34495e",
  },
  2: {
    name: "REBA",
    color: "#2980b9",
  },
  3: {
    name: "NIOSH",
    color: "#7f8c8d",
  },
  4: {
    name: "HAND_STRAIN",
    color: "#ace3de",
  },
  5: {
    name: "SNOOK",
    color: "#7f8c8d",
  },
  6: {
    name: "TRAINING",
    color: "#7f8c8d",
  },
};

export type TProcessingStatus = {
  [key: number | string]: number | string;
};
export const ProcessingStatus: TProcessingStatus = {
  1: "PROCESSING",
  5: "PROCESSING_RISK_CONTRIB",
  2: "MUXING",
  3: "COMPLETED",
  4: "ERROR",
  PROCESSING: 1,
  PROCESSING_RISK_CONTRIB: 5,
  MUXING: 2,
  COMPLETED: 3,
  ERROR: 4,
};

export type TPageErrors = {
  ROLE_ERROR: number;
  VIRGIL_ERROR: number;
};
export const PageErrors: TPageErrors = {
  ROLE_ERROR: 1,
  VIRGIL_ERROR: 2,
};

export type TVersions = {
  [key: number]: string;
};
export const Versions: TVersions = {
  1: "Initial Assessment",
  2: "Proposed Change",
  3: "Post Assessment",
};

export type TVersionColumns = {
  [key: number]: {
    name: string;
    icon: string;
  };
};
export const VersionColumns: TVersionColumns = {
  1: {
    name: "Initial Assessments",
    icon: "fas fa-clipboard-user",
  },
  2: {
    name: "Proposed Changes",
    icon: "fas fa-clipboard-list",
  },
  3: {
    name: "Post Assessments",
    icon: "fas fa-clipboard-check",
  },
};

export type TMaxAssessmentScores = {
  [key: number]: number;
};
export const MaxAssessmentScores: TMaxAssessmentScores = {
  1: 7,
  2: 15,
  3: 3,
};

export const AllowedVideoTypes: string[] = [
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/3gpp",
  "video/webm",
  "video/avi",
  "video/mov",
];

export const AllowedVideoExtensions: string[] = [
  "mp4",
  "avi",
  "mkv",
  "3gp",
  "wmv",
  "mov",
];

export const AllowedVideoTypesString = AllowedVideoTypes.reduce(
  (acc, val) => `${acc},${val}`,
);
