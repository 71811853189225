import * as env from "env-var";

export const ConfigAdapter = {
  env: (name, format, isRequired) => {
    let value = env.get(name);
    if (isRequired) {
      value = value.required();
    }
    if (format === 'Number') {
      value = value.asFloat();
    } else if (format === 'String') {
      value = value.asString();
    } else if (format === 'Float') {
      value = value.asFloat()
    } else if (format === 'Int') {
      value = value.asInt();
    } else if (format === 'IntPositive') {
      value = value.asIntPositive();
    } else if (format === 'Boolean') {
      value = value.asBool();
    } else if (format === 'Base64') {
      value = value.convertFromBase64().asString();
    }
    return value;
  }
};
  