import { NotificationManager } from "./react-notifications";

const getTagFromErrorCode = (code: number): string => {
  if (code >= 400 && code <= 499) {
    return "Warning";
  }
  return "Error";
};

export const handleVideoError = (e: any): void => {
  if (e.response) {
    const tag = getTagFromErrorCode(e.response.status);
    if (tag === "Error") {
      NotificationManager.error(
        tag,
        e.response.data.message,
        5000,
        null,
        null,
        "",
      );
      return;
    }
    NotificationManager.warning(
      tag,
      e.response.data.message,
      5000,
      null,
      null,
      "",
    );
    return;
  }
  NotificationManager.error(
    "Error",
    "Unknown server error. Please try again",
    5000,
    null,
    null,
    "",
  );
};
