import {
  Rula,
  Reba,
  Niosh,
  HandStrain,
  LibertyMutual,
  Training,
} from "@tumeke/tumekejs";
import { Assessments } from "@kernel-constants/maps";
import { IRiskScoreInfo } from "@tumeke/tumekejs/lib/assessments/interfaces";

export const getAssessmentScore = (
  assessmentType: number,
  score: number,
): IRiskScoreInfo | undefined => {
  let scoreInfo: IRiskScoreInfo | undefined;
  try {
    if (assessmentType === Assessments.RULA) {
      scoreInfo = Rula.getRiskScoreInfo("Overall", score);
    } else if (assessmentType === Assessments.REBA) {
      scoreInfo = Reba.getRiskScoreInfo("Overall", score);
    } else if (assessmentType === Assessments.NIOSH) {
      scoreInfo = Niosh.getRiskScoreInfo("li", score);
    } else if (assessmentType === Assessments.HAND_STRAIN) {
      scoreInfo = HandStrain.getRiskScoreInfo("OverallScore", score);
    } else if (assessmentType === Assessments.LIBERTY_MUTUAL) {
      scoreInfo = LibertyMutual.getRiskScoreInfo("mal", score);
    } else if (assessmentType === Assessments.TRAINING) {
      scoreInfo = Training.getRiskScoreInfo("Score", score);
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return scoreInfo;
};
