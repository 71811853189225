import RulaConfig from "../configs/rula.json";
import RebaConfig from "../configs/reba.json";

import { Rula } from "../assessments/Rula";
import { Reba } from "../assessments/Reba";

const getImprovementObj = (
  config: any,
  videoData: any,
  selectedPosture: string | number,
  bodyPartId: string,
  componentId: string | number,
): any | null | undefined => {
  const posture = videoData.posture_assessments[selectedPosture];
  const { riskAssessment } = posture;
  const oldAssessmentObj = riskAssessment;
  let newAssessmentObj: Rula | Reba | null = null;
  const improvementObj: any = {};

  // Assign min value for bodypart + type
  const minValue = config.ComponentValues[bodyPartId][componentId].Base;
  // const minValue = 1; // TODO(znoland): get min value for component
  const { assessmentType } = videoData.data;
  // Get new RULA or REBA object
  if (assessmentType === 1) {
    newAssessmentObj = new Rula(oldAssessmentObj);
  } else if (assessmentType === 2) {
    newAssessmentObj = new Reba(oldAssessmentObj);
  }
  newAssessmentObj!.updateRiskComponents(bodyPartId, componentId, minValue);

  /* Determine score card type:
   *   1. Overall risk
   *   2. General reduction (does not have a body part risk)
   *   3. Body part risk
   */
  // TODO(znoland): come up with other recommendations for warnings without a score impact

  const oldScore = oldAssessmentObj.assessmentResult.Score;
  const newScore = newAssessmentObj!.assessmentResult.Score;
  improvementObj["overall-risk-reduction"] = Math.abs(
    Math.round((newScore / oldScore - 1) * 100),
  );
  if (
    newAssessmentObj!.assessmentResult.Score <
    oldAssessmentObj.assessmentResult.Score
  ) {
    improvementObj.type = "overall";
    improvementObj.translateType = "breakdown.overall";
    improvementObj["old-risk-text"] =
      oldAssessmentObj.assessmentResult.ShortText;
    improvementObj["old-risk-translate-text"] =
      oldAssessmentObj.assessmentResult.TranslateText;
    improvementObj["old-risk-score"] = oldAssessmentObj.assessmentResult.Score;
    improvementObj["new-risk-text"] =
      newAssessmentObj!.assessmentResult.ShortText;
    improvementObj["new-risk-translate-text"] =
      newAssessmentObj!.assessmentResult.TranslateText;
    improvementObj["new-risk-score"] = newAssessmentObj!.assessmentResult.Score;
  } else if (!(bodyPartId in newAssessmentObj!.assessmentResult.Components)) {
    // return (<div>No component score</div>);
    return undefined;
  } else if (
    newAssessmentObj!.assessmentResult.Components[bodyPartId].Score <
    oldAssessmentObj.assessmentResult.Components[bodyPartId].Score
  ) {
    improvementObj.type = bodyPartId;
    improvementObj.translateType = `rulareba.${bodyPartId
      .toLowerCase()
      .split(" ")
      .join("")}.name`;
    improvementObj["old-risk-text"] =
      oldAssessmentObj.assessmentResult.Components[bodyPartId].ShortText;
    improvementObj["old-risk-translate-text"] =
      oldAssessmentObj.assessmentResult.Components[bodyPartId].TranslateText;
    improvementObj["old-risk-score"] =
      oldAssessmentObj.assessmentResult.Components[bodyPartId].Score;
    improvementObj["new-risk-text"] =
      newAssessmentObj!.assessmentResult.Components[bodyPartId].ShortText;
    improvementObj["new-risk-translate-text"] =
      newAssessmentObj!.assessmentResult.Components[bodyPartId].TranslateText;
    improvementObj["new-risk-score"] =
      newAssessmentObj!.assessmentResult.Components[bodyPartId].Score;
  } else if (
    newAssessmentObj!.assessmentResult.Components[bodyPartId].Score ===
    oldAssessmentObj.assessmentResult.Components[bodyPartId].Score
  ) {
    // return(<div>No change in body part score</div>)
    return undefined;
  }

  improvementObj["risk-reduction-perc"] = `${String(
    Math.abs(
      Math.round(
        (improvementObj["new-risk-score"] / improvementObj["old-risk-score"] -
          1) *
          100,
      ),
    ),
  )}%`;
  return improvementObj;
};

export interface IRiskComponent {
  componentText: string;
  componentWarning: string;
  componentTranslateText: string;
  componentTranslateWarning: string;
  componentImage: string;
  severityColor: string;
  label: string;
  translateLabel: string;
  bodyPartId: string;
  bodyPartTranslateId: string;
  id: string;
  improvementObj: any;
}

const getRiskComponent = (
  config: any,
  videoData: any,
  selectedPosture: string | number,
  bodyPartId: string,
  componentId: string,
  componentObj: any,
): any => {
  const { label } = componentObj;
  const translateLabel = componentObj.TranslateLabel;
  const posture = videoData.posture_assessments[selectedPosture];
  const { riskAssessment } = posture;
  const { assessmentType } = videoData.data;
  let assessmentObj: Rula | Reba | null;
  if (assessmentType === 1) {
    assessmentObj = new Rula(riskAssessment);
  } else if (assessmentType === 2) {
    assessmentObj = new Reba(riskAssessment);
  }

  const componentIndex = riskAssessment.riskComponents[bodyPartId][componentId];
  const bodyPartImage = config.ComponentValues[bodyPartId].Image;
  let severityColor = "#6c7b8a";
  if (componentIndex === -1 || componentIndex === undefined) {
    return {};
  }
  const componentText =
    config.ComponentValues[bodyPartId][componentId][String(componentIndex)]
      .Text;
  const componentTranslateText =
    config.ComponentValues[bodyPartId][componentId][String(componentIndex)]
      .TranslateText;
  const componentWarning =
    config.ComponentValues[bodyPartId][componentId][String(componentIndex)]
      .Warning;
  const componentTranslateWarning =
    config.ComponentValues[bodyPartId][componentId][String(componentIndex)]
      .TranslateWarning;

  if (
    componentWarning === "" ||
    !componentWarning ||
    componentWarning === null
  ) {
    return {};
  }

  const improvementObj = getImprovementObj(
    config,
    videoData,
    selectedPosture,
    bodyPartId,
    componentId,
  );
  if (!improvementObj) {
    return {};
  }
  const score = assessmentObj!.assessmentResult.Components[bodyPartId].Score;

  severityColor = config.BodyPartScores[bodyPartId][score].Color;
  return {
    componentText,
    componentWarning,
    componentTranslateText,
    componentTranslateWarning,
    componentImage: bodyPartImage,
    severityColor,
    label,
    translateLabel,
    bodyPartId,
    bodyPartTranslateId: `rulareba.${bodyPartId
      .toLowerCase()
      .split(" ")
      .join("")}.name`,
    id: `${bodyPartId}-${componentId}`,
    improvementObj,
  };
};

const getConfig = (videoData: any): any => {
  const config = videoData.data.assessmentType === 1 ? RulaConfig : RebaConfig;
  return config;
};

export const getRecommendations = (
  videoData: any,
  selectedPosture: any,
): any[] => {
  const arr = [];
  const config = getConfig(videoData);
  const bodyParts = Object.keys(config.ComponentValues);
  for (let i = 0; i < bodyParts.length; i++) {
    const bodyPartId = bodyParts[i];
    const bodyPartObj = config.ComponentValues[bodyPartId];
    const typesOfInfo = Object.keys(bodyPartObj);
    for (let j = 0; j < typesOfInfo.length; j++) {
      const componentId = typesOfInfo[j];
      const componentObj = bodyPartObj[componentId];
      const rec = getRiskComponent(
        config,
        videoData,
        selectedPosture,
        bodyPartId,
        componentId,
        componentObj,
      );
      if (Object.prototype.hasOwnProperty.call(rec, "componentText")) {
        arr.push(rec);
      }
    }
  }
  return arr.sort(
    (a, b) =>
      b.improvementObj["overall-risk-reduction"] -
      a.improvementObj["overall-risk-reduction"],
  );
};
