import { createAsyncThunk } from "@reduxjs/toolkit";

import { getUsersData as getUsersDataApi } from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";

export const getUsersDataRequest = createAsyncThunk<
  { usersData: any },
  {
    page: number;
    page_size: number;
    search: string;
    company_id?: number;
  },
  { state: ReduxState }
>(
  "users/getUsersDataRequest",
  async ({ page, page_size, search, company_id }) => {
    let usersData = null;
    try {
      usersData = await getUsersDataApi({
        page,
        page_size,
        search,
        company_id,
      });
    } catch (e) {
      console.log("Get users error", e);
      throw e;
    }

    return { usersData };
  },
);
