import { createSlice } from "@reduxjs/toolkit";

import { EMPTY_FILTER, TDashboardFilter } from "@kernel-store/dashboard/slice";
import { getTempPasswordsDataRequest } from "@kernel-store/temp_passwords/thunk";

export type TempPasswordsReduxState = {
  tempPasswordsData: any;
  tempPasswordsCount: number;
  tempPasswordsDataLoading: boolean;
  tempPasswordsFilters: any;
  tempPasswordsDataDirty: boolean;
  filterObject: TDashboardFilter;
  refresh: boolean;
};

export const tempPasswordsInitialState: TempPasswordsReduxState = {
  tempPasswordsData: [],
  tempPasswordsCount: 0,
  tempPasswordsDataLoading: false,
  tempPasswordsFilters: {},
  tempPasswordsDataDirty: false,
  filterObject: EMPTY_FILTER,
  refresh: false,
};

const tempPasswordsSlice = createSlice({
  name: "tempPasswords",
  initialState: tempPasswordsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTempPasswordsDataRequest.pending, (state) => {
      state.tempPasswordsDataLoading = true;
    });
    builder.addCase(getTempPasswordsDataRequest.fulfilled, (state, action) => {
      state.tempPasswordsData = action.payload.tempPasswordsData.items;
      state.tempPasswordsCount = action.payload.tempPasswordsData.count;
      state.tempPasswordsDataLoading = false;
    });
    builder.addCase(getTempPasswordsDataRequest.rejected, (state) => {
      state.tempPasswordsDataLoading = false;
    });
  },
});

export default tempPasswordsSlice.reducer;
