export const Colors: {
  1: string;
  2: string;
  3: string;
  4: string;
  Red: number;
  Yellow: number;
  Green: number;
  Black: number;
} = {
  1: "Red",
  2: "Yellow",
  3: "Green",
  4: "Black",
  Red: 1,
  Yellow: 2,
  Green: 3,
  Black: 4,
};

export enum JointTypeEnum {
  Neck = "Neck",
  LeftElbow = "Left elbow",
  RightElbow = "Right elbow",
  Hip = "Hip",
  RightShoulder = "Right shoulder",
  LeftShoulder = "Left shoulder",
  RightWrist = "Right wrist",
  LeftWrist = "Left wrist",
  RightLeg = "Right leg",
  LeftLeg = "Left leg",
  Default = "",
}

export enum DeviationTypeEnum {
  Trunk = "trunk",
  Arm = "arm",
  ForeArm = "arm",
  InterForeArm = "arm",
  Thigh = "leg",
  Leg = "leg",
  Default = "",
}
